import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  isValidPhoneNumber,
} from 'libphonenumber-js'
import { customArray as CountryList } from "country-codes-list";

@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss'],
  providers: [DialogService]
})
export class ContactDialogComponent implements OnInit {
  newContactForm: FormGroup;

  countryCodes = CountryList({ value: '{countryCode}', label: '{countryNameEn}', callCode: '+{countryCallingCode}' });
  selectedPhoneCountry: any;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

  ngOnInit(): void {
    if (!this.config.data) {
      this.ref.close();
    }
    this.newContactForm = this.config.data;
    this.selectedPhoneCountry = this.countryCodes.find((c: any) => c.value == 'DK');

    this.newContactForm.valueChanges.subscribe((value: any) => {
      if (value.phone) {
        if (isValidPhoneNumber(value.phone, this.selectedPhoneCountry.value)) {
          this.newContactForm.controls.phone.setErrors(null);
        } else {
          this.newContactForm.controls.phone.setErrors({ 'invalid': true });
        }
      }
    });
    this.newContactForm.controls.email.statusChanges.subscribe((value: any) => {
      if (value === 'INVALID') {
        this.newContactForm.controls.sendLabel.disable();
        this.newContactForm.controls.sendLabel.setValue(false);
      } else {
        if (this.newContactForm.controls.email.value) {
          this.newContactForm.controls.sendLabel.enable();
        }
        else {
          this.newContactForm.controls.sendLabel.disable();
          this.newContactForm.controls.sendLabel.setValue(false);
        }
      }
    });

    if (this.newContactForm.controls.email.value && this.newContactForm.controls.email.valid) {
      this.newContactForm.controls.sendLabel.enable();
    }
  }


  onPhoneCountryChange(event: any) {
    if (this.newContactForm.controls.phone.value) {
      const isValidPhone = isValidPhoneNumber(this.newContactForm.controls.phone.value, this.selectedPhoneCountry.value);
      if (!isValidPhone) {
        this.newContactForm.controls.phone.setErrors({ 'invalid': true });
      }
      else {
        this.newContactForm.controls.phone.setErrors(null);
      }

    }
  }

  addContactPerson() {
    this.ref.close(this.newContactForm);
  }


  closeDialog() {
    this.ref.close();
  }

}

<div [ngClass]="{'layout-inline-menu': true, 'layout-inline-menu-active':appMain.activeInlineProfile}"
    *ngIf="!appMain.isMobile() && (appMain.isStatic() || appMain.isSlim() || appMain.isSidebar())">
    <a class="layout-inline-menu-action" (click)="appMain.activeInlineProfile = !appMain.activeInlineProfile">
        <i class="pi pi-users" alt="avatar"></i>
        <span class="layout-inline-menu-text">{{ auth.userProfile.name }}</span>
        <i class="layout-inline-menu-icon pi pi-angle-down"></i>
    </a>

    <ul class="layout-inline-menu-action-panel"
        [@inline]="appMain.activeInlineProfile ? 'visibleAnimated' : 'hiddenAnimated'">
        <li class="layout-inline-menu-action-item">
            <a (click)="auth.logout()">
                <i class="pi pi-power-off pi-fw"></i>
                <span>Logout</span>
            </a>
        </li>
    </ul>
</div>
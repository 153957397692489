import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Shipment } from 'src/app/domain/Shipment';
import { ClientService } from 'src/app/services/client.service';
import { StepperService } from 'src/app/pages/Shared/stepper/stepper.service';
import { TenantService } from 'src/app/services/tenant.service';

@Component({
  selector: 'app-service-info',
  templateUrl: './service-info.component.html',
  styleUrls: ['./service-info.component.scss'],
})
export class ServiceInfoComponent implements OnInit {
  @Input() shipment = <Shipment>{};
  services: any;
  tenantServices: any[] = [];
  accounts: any;
  payments: any;

  serviceForm = new FormGroup({
    services: new FormControl<string[]>(this.shipment.services ?? []),
    serviceData: new FormArray([]),
  });

  constructor(public stepperService: StepperService, private clientService: ClientService, private tenant: TenantService) {
    this.shipment = this.stepperService.newShipment;
  }

  ngOnInit(): void {
    this.getServices();
    this.tenant.services$.subscribe((res) => {
      this.tenantServices = res.reduce((map, obj) => {
        map[obj.code] = obj;
        return map;
      }, {} as { [key: string]: any });
    });

    this.serviceForm.valueChanges.subscribe((value) => {
      this.shipment.services = value.services;
      this.shipment.serviceData = (this.serviceForm.get('serviceData') as FormArray).controls.reduce((map, control) => {
        map[control.get('service')?.value] = control.get('value')?.value;
        return map;
      }, {} as { [key: string]: string });
    });
  }

  getServices() {
    this.services = this.clientService.clientServices ?? [];
  }

  onServiceChange(event: any) {
    const newServices = event.checked ?? [];
    const serviceDataArray = this.serviceForm.get('serviceData') as FormArray;

    // Add new controls for services that require data
    newServices.forEach((service) => {
      if (this.tenantServices[service]?.requiresData) {
        const exists = serviceDataArray.controls.some((control) => control.get('service')?.value === service);
        if (!exists) {
          serviceDataArray.push(
            new FormGroup({
              service: new FormControl(service),
              value: new FormControl(null, [Validators.required]),
            })
          );
        }
      }
    });

    // Remove controls for services that are no longer selected
    for (let i = serviceDataArray.length - 1; i >= 0; i--) {
      const control = serviceDataArray.at(i);
      if (!newServices.includes(control.get('service')?.value)) {
        serviceDataArray.removeAt(i);
      }
    }
  }

  getServiceDataFormGroups(): FormGroup[] {
    return ((this.serviceForm.get('serviceData') as FormArray).controls as FormGroup[]) ?? [];
  }
}

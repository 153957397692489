import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';
import { TenantService } from 'src/app/services/tenant.service';

@Pipe({
  name: 'serviceText',
  standalone: true,
})
export class ServiceTextPipe implements PipeTransform {
  constructor(private readonly tenantService: TenantService, private translate: TranslateService) {}

  transform(serviceCode: string, ...args: unknown[]): Observable<string> {
    return this.tenantService.services$.pipe(
      map((services) => {
        if (serviceCode == 'T30') {
          return this.translate.instant(serviceCode);
        }

        const service = services.find((x) => x.code === serviceCode);
        if (service && service.nameDa) {
          return service.nameDa;
        }

        return serviceCode;
      })
    );
  }
}

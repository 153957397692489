<div class="col-12 md:col-12 grid" style="margin-top:2px;">
    <!-- Left side -->
    <div class="col-4 md:col-4" [formGroup]="sendMailForm">
        <div class="col-12 md:col-12">
            <span class="p-float-label">
                <input pInputText id="emailRecipient" #emailRecipient type="text" formControlName="recipient"
                    style="width:100%;" />
                <label for="emailRecipient">{{'RECEIVER' | translate}}</label>
            </span>
        </div>
        <div class="col-12 md:col-12" style="margin-top:6px;">
            <span class="p-float-label">
                <input pInputText id="subject" #subject type="text" formControlName="subject" style="width:100%;" />
                <label for="subject">{{'SUBJECT' | translate}}</label>
            </span>
        </div>

        <div class="col-12 md:col-12" style="margin-top:6px;">
            <span class="p-float-label">
                <textarea [rows]="6" id="text" #text pInputTextarea type="text" formControlName="text"
                    style="width:100%;" [autoResize]="true"></textarea>
                <label for="text">{{'TEXT' | translate}}</label>
            </span>
        </div>
    </div>

    <!-- Right side -->
    <div class="col-8 md:col-8">
        <h6 style="text-align: center;" *ngIf="pdfUrl">{{'ATTACHPDFTEXT' | translate}}</h6>
        <div *ngIf="pdfUrl">
            <object [data]="pdfUrl" type="application/pdf" style="width:100%; min-height:600px;">
            </object>
        </div>
    </div>
</div>

<!-- Buttons -->
<div class="col-12 md:col-12" style="text-align:end;">
    <span></span>
    <button style="margin-right:8px;" pButton pRipple label="{{ 'GENERIC.SEND' | translate }}" icon="pi pi-check"
        class="p-button-success" (click)="sendMail()" [disabled]="!sendMailForm.valid"></button>
    <button pButton pRipple label="{{ 'GENERIC.CLOSE' | translate }}" icon="pi pi-times" class="p-button-warning"
        (click)="close()"></button>
</div>
import { Injectable } from '@angular/core';
import { Agreement } from '../domain/Agreement';
import { Service } from '../domain/Service';
import { Account } from '../domain/Account';
import { Payer } from '../domain/Payer';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  clientTenant: any;
  clientTenantName: any;
  clientTenantLogo: any;
  clientPermissions: any[] = [];
  clientAgreements: Agreement[] = [];
  clientServices: Service[] = [];
  clientAccounts: Account[] = [];
  clientAddress?: any;
  clientPayers?: Payer[] = [];
  clientReadyDeadline: any;
  countries: [];

  defaultClientPhone?: string;
  defaultClientEmail?: string;
  defaultClientName?: string;
  defaultClientNumber?: string;
  defaultClientContactPerson?: string;
  clientName?: string;
  clientId?: string;

  constructor() {}

  hasClientPermission(permission: any) {
    return this.clientPermissions.includes(permission);
  }
}

<div class="exception-body notfound">
    <div class="exception-panel">
        <h1 style="color:#e31414">Siden blev</h1>
        <h3 style="color:black;">Ikke fundet</h3>
        <!-- <p>...</p> -->
        <button type="button" pButton label="Tilbage" style="background-color:#e31414;" [routerLink]="['/']"></button>
    </div>
    <div class="exception-footer">
        <img [src]="'assets/layout/images/OnlineBook/ob_logo_'+ (app.colorScheme === 'light' ? 'dark' : 'light') + '.svg'" style="width:300px; height:100px;" class="logo"/>
    </div>
</div>

<div class="col-12 md:col-12 grid" style="margin-top:2px; justify-content: center;">
    <div *ngIf="pdfUrl && !loading" style="width:100%;">
        <object [data]="pdfUrl" type="application/pdf" style="min-width:100%; min-height:600px;">
        </object>
    </div>
    <div *ngIf="loading">
        <p-progressSpinner styleClass="w-6rem h-6rem" strokeWidth="6" animationDuration="1s"></p-progressSpinner>
    </div>

    <!-- Buttons -->
    <div class="col-12 md:col-12" style="text-align:end;">
        <span></span>
        <button pButton pRipple label="{{ 'GENERIC.CLOSE' | translate }}" icon="pi pi-times" class="p-button-warning"
            (click)="close()"></button>
    </div>
<div class="col-12 md:col-12">
    <div class="p-fluid p-formgrid grid">
        <form [formGroup]="newAddressForm" class="col-12 md:col-12">
            <div class="field grid padTop">
                <div class="col-9 md:col-9">
                    <span class="p-float-label">
                        <p-dropdown [options]="countries" [(ngModel)]="selectedCountry" id="country"
                            formControlName="countryCode" optionValue="code"
                            placeholder="{{ 'CUSTOMER.FORM.SELECTCOUNTRY' | translate }} *">
                            <ng-template pTemplate="selectedItem">
                                <div class="country-item country-item-value" *ngIf="selectedCountry">
                                    <div><img src="assets/demo/flags/flag_placeholder.png"
                                            [class]="'flag flag-' + selectedCountry.toLowerCase()" />
                                        {{ 'COUNTRIES.'+selectedCountry | translate }}
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template let-country pTemplate="item">
                                <div class="country-item">
                                    <div class="country-item">
                                        <img src="assets/demo/flags/flag_placeholder.png"
                                            [class]="'flag flag-' + country.code.toLowerCase()" />
                                        {{ 'COUNTRIES.'+country.code | translate }}
                                    </div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                        <label for="country">{{ 'CUSTOMER.FORM.SELECTCOUNTRY' | translate }}</label>
                    </span>
                </div>
                <div class="col-3 md:col-3">
                    <p-message severity="error" *ngIf="newAddressForm.controls['countryCode'].errors?.required"
                        text="{{ 'CUSTOMER.FORM.REQUIREDFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                    <p-message severity="success" *ngIf="newAddressForm.controls['countryCode'].valid"
                        text="{{ 'CUSTOMER.FORM.OKFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                </div>
            </div>
            <div class="field grid">
                <div class="col-9 md:col-9">
                    <span class="p-float-label">
                        <p-autoComplete [suggestions]="foundAddresses" [minLength]="3" formControlName="street"
                            showEmptyMessage="true" emptyMessage="{{'NORESULTS' | translate}}" [delay]="1000"
                            name="test" id="addressStreet" #addressStreet autocomplete="anyrandomstring"
                            (completeMethod)="searchAddress($event)" showClear="true" (onClear)="onClearAddress()"
                            (onSelect)="setAddress($event)" [field]="formattedStreetName">

                            <ng-template let-address pTemplate="address">
                                <div class="address-item">
                                    <div>
                                        <img *ngIf="address.countryCode" src="assets/demo/flags/flag_placeholder.png"
                                            [class]="'flag flag-' + address.countryCode.toLowerCase()" />
                                        {{address.street}},
                                        <span class="grayedText"> {{address.zipCode}} {{address.city}}</span>
                                    </div>
                                </div>
                            </ng-template>
                        </p-autoComplete>
                        <label for="address">{{'ADDRESS' | translate}}</label>
                    </span>
                </div>
                <div class="col-3 md:col-3">
                    <p-message severity="error" *ngIf="newAddressForm.controls['street'].errors?.required"
                        text="{{ 'CUSTOMER.FORM.REQUIREDFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                    <p-message severity="success" *ngIf="newAddressForm.controls['street'].valid"
                        text="{{ 'CUSTOMER.FORM.OKFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                </div>
            </div>
            <div class="field grid">
                <div class="col-9 md:col-9">
                    <span class="p-float-label">
                        <input type="text" id="zipCode" pInputText formControlName="zipCode">
                        <label for="zipCode">{{ 'CUSTOMER.ADDRESS.ZIPCODE' | translate }}</label>
                    </span>
                </div>
                <div class="col-3 md:col-3">
                    <p-message severity="error" *ngIf="newAddressForm.controls['zipCode'].errors?.required"
                        text="{{ 'CUSTOMER.FORM.REQUIREDFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                    <p-message severity="success" *ngIf="newAddressForm.controls['zipCode'].valid"
                        text="{{ 'CUSTOMER.FORM.OKFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                </div>
            </div>
            <div class="field grid">
                <div class="col-9 md:col-9">
                    <span class="p-float-label">
                        <input type="text" id="city" pInputText formControlName="city">
                        <label for="city">{{ 'CUSTOMER.ADDRESS.CITY' | translate }}</label>
                    </span>
                </div>
                <div class="col-3 md:col-3">
                    <p-message severity="error" *ngIf="newAddressForm.controls['city'].errors?.required"
                        text="{{ 'CUSTOMER.FORM.REQUIREDFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                    <p-message severity="success" *ngIf="newAddressForm.controls['city'].valid"
                        text="{{ 'CUSTOMER.FORM.OKFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                </div>
            </div>
        </form>
    </div>

    <div class="col-12 md:col-12" style="text-align:end;">
        <span style="padding-right:4px;" *ngIf="newAddressForm.touched && !newAddressForm.valid">{{
            'CUSTOMER.FORM.INVALID' | translate
            }}</span>
        <button style="margin-right:8px;" pButton pRipple label="{{ 'GENERIC.SAVE' | translate }}" icon="pi pi-check"
            (click)="addAddress()" [disabled]="!newAddressForm.valid" class="p-button-success"></button>
        <button pButton pRipple label="{{ 'GENERIC.CANCEL' | translate }}" icon="pi pi-times" (click)="close()"
            class="p-button-warning"></button>
    </div>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { StepperService } from 'src/app/pages/Shared/stepper/stepper.service';

@Component({
  selector: 'app-exchange-pallets',
  templateUrl: './exchange-pallets.component.html',
  styleUrls: ['./exchange-pallets.component.scss']
})
export class ExchangePalletsComponent implements OnInit {

  @Input() shipment!: any;
  @Input() removeBtns: boolean = false;

  isDisabled = true;

  exchangePalletsFormGroup = new FormGroup({
    includeExchangePallets: new FormControl(false),
    exchangePallets: new FormGroup({
      fullExchangePallets: new FormControl({ value: null, disabled: this.isDisabled }),
      halfExchangePallets: new FormControl({ value: null, disabled: this.isDisabled }),
      quarterExchangePallets: new FormControl({ value: null, disabled: this.isDisabled }),
    })
  });

  constructor(public stepperService: StepperService) {
    this.shipment = this.stepperService.newShipment;
    this.exchangePalletsFormGroup.get('includeExchangePallets')?.valueChanges.subscribe((checked: boolean) => {
      if (checked) {
        this.shipment.exchangePallets = true;
        this.exchangePalletsFormGroup.get('exchangePallets').enable();
        this.exchangePalletsFormGroup.get('exchangePallets')?.get('fullExchangePallets')?.setValue(this.shipment.fullExchangePallets.quantity, { emitEvent: false });
        this.exchangePalletsFormGroup.get('exchangePallets')?.get('halfExchangePallets')?.setValue(this.shipment.halfExchangePallets.quantity, { emitEvent: false });
        this.exchangePalletsFormGroup.get('exchangePallets')?.get('quarterExchangePallets')?.setValue(this.shipment.quarterExchangePallets.quantity, { emitEvent: false });
      } else {
        this.shipment.exchangePallets = false;
        this.exchangePalletsFormGroup.get('exchangePallets').disable();
        this.shipment.fullExchangePallets.quantity = 0;
        this.shipment.halfExchangePallets.quantity = 0;
        this.shipment.quarterExchangePallets.quantity = 0;
        this.exchangePalletsFormGroup.get('exchangePallets')?.get('fullExchangePallets')?.setValue(null, { emitEvent: false });
        this.exchangePalletsFormGroup.get('exchangePallets')?.get('halfExchangePallets')?.setValue(null, { emitEvent: false });
        this.exchangePalletsFormGroup.get('exchangePallets')?.get('quarterExchangePallets')?.setValue(null, { emitEvent: false });
        this.exchangePalletsFormGroup.get('exchangePallets')?.get('fullExchangePallets')?.setErrors(null);
        this.exchangePalletsFormGroup.get('exchangePallets')?.get('halfExchangePallets')?.setErrors(null);
        this.exchangePalletsFormGroup.get('exchangePallets')?.get('quarterExchangePallets')?.setErrors(null);

      }
    });

    this.exchangePalletsFormGroup.get('exchangePallets')?.valueChanges.subscribe((value: any) => {
      var foundError = false;
      if (value.fullExchangePallets > 1000) {
        this.exchangePalletsFormGroup.get('exchangePallets')?.get('fullExchangePallets')?.setErrors({ max: true });
        foundError = true;
      }
      if (value.halfExchangePallets > 1000) {
        this.exchangePalletsFormGroup.get('exchangePallets')?.get('halfExchangePallets')?.setErrors({ max: true });
        foundError = true;
      }
      if (value.quarterExchangePallets > 1000) {
        this.exchangePalletsFormGroup.get('exchangePallets')?.get('quarterExchangePallets')?.setErrors({ max: true });
        foundError = true;
      }

      if (foundError) {
        return;
      }

      this.shipment.fullExchangePallets.quantity = value.fullExchangePallets != null ? value.fullExchangePallets : 0;
      this.shipment.halfExchangePallets.quantity = value.halfExchangePallets != null ? value.halfExchangePallets : 0;
      this.shipment.quarterExchangePallets.quantity = value.quarterExchangePallets != null ? value.quarterExchangePallets : 0;

    });

  }

  ngOnInit(): void {
    if (!this.shipment.fullExchangePallets) {
      this.shipment.fullExchangePallets = { quantity: 0 };
    }
    if (!this.shipment.halfExchangePallets) {
      this.shipment.halfExchangePallets = { quantity: 0 };
    }
    if (!this.shipment.quarterExchangePallets) {
      this.shipment.quarterExchangePallets = { quantity: 0 };
    }
    if (this.shipment.fullExchangePallets?.quantity || this.shipment.halfExchangePallets?.quantity || this.shipment.quarterExchangePallets?.quantity) {
      this.exchangePalletsFormGroup.get('includeExchangePallets')?.setValue(true, { emitEvent: false });
      this.exchangePalletsFormGroup.get('exchangePallets').enable({ emitEvent: false });
      this.exchangePalletsFormGroup.get('exchangePallets')?.get('fullExchangePallets')?.setValue(this.shipment.fullExchangePallets.quantity, { emitEvent: false });
      this.exchangePalletsFormGroup.get('exchangePallets')?.get('halfExchangePallets')?.setValue(this.shipment.halfExchangePallets.quantity, { emitEvent: false });
      this.exchangePalletsFormGroup.get('exchangePallets')?.get('quarterExchangePallets')?.setValue(this.shipment.quarterExchangePallets.quantity, { emitEvent: false });
    }

  }

}

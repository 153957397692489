import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StepperService } from '../stepper/stepper.service';
import { formatDate } from '@angular/common';
import { MessageService } from 'primeng/api';
import { ShipmentService } from 'src/app/services/shipment.service';
import { Router } from '@angular/router';
import { ServicesService } from 'src/app/services/services.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-postnord-booking',
  templateUrl: './postnord-booking.component.html',
  styleUrls: ['./postnord-booking.component.scss']
})
export class PostnordBookingComponent {
  shipment = <any>{
    senderAddress: {},
    recipientAddress: {},
    collis: [],
  };

  externalCarrierServices: any;
  loading: any;
  weightLimit: any;
  contactPersons: any;

  addressForm = new FormGroup({
    name: new FormControl(this.shipment.recipientAddress.name),
    street: new FormControl(this.shipment.recipientAddress.street, Validators.required),
    contactPerson: new FormControl(),
    contactPhone: new FormControl(),
    contactEmail: new FormControl(null, Validators.email),
    zipCode: new FormControl(this.shipment.recipientAddress.zipCode, Validators.required),
    city: new FormControl(this.shipment.recipientAddress.city, Validators.required),
    countryCode: new FormControl(this.shipment.recipientAddress.countryCode, Validators.required),
    note: new FormControl(this.shipment.note),
    parcelShopId: new FormControl(this.shipment.parcelShopId),
    externalCarrierServices: new FormControl()
  });

  packageInfoForm = new FormGroup({
    bookingType: new FormControl('', Validators.required),
    weight: new FormControl(this.shipment.weight, Validators.required),
    waybillNo: new FormControl(this.shipment.waybillNo),
  });

  constructor(private stepperService: StepperService, private messageService: MessageService, private shipmentService: ShipmentService, private router: Router, private servicesService: ServicesService, private translateService: TranslateService) {
    this.setPostnordValues();
    this.checkPackageInfoForm();
  }


  goBack() {
    this.stepperService.bookingChoice = null;
    this.stepperService.newShipment.collis = [];
    this.router.navigate(['/']);
  }

  setPostnordValues() {
    this.stepperService.newShipment.services = null;
    this.shipment = this.stepperService.newShipment;
    this.shipment.bookingType = null;
    this.shipment.carrier = 'Postnord';
    this.shipment.collis = [];
    this.shipment.collis.push({ type: 'PC', quantity: 1, description: 'Postnord' });
    let date = new Date();
    date.setDate(date.getDate() + 1);
    this.shipment.deliveryDate = formatDate(date, 'yyyy-MM-dd', 'en-US');
  }

  checkPackageInfoForm() {
    this.packageInfoForm.valueChanges.subscribe((value) => {
      if (value.weight) {
        this.shipment.collis[0].weight = value.weight;
      }
      this.shipment.reference1 = value.waybillNo;

      if (value.bookingType != this.shipment.bookingType) {
        this.contactPersons = null;
        this.addressForm.reset();
        this.addressForm.get('note').clearValidators();
        this.addressForm.get('note').updateValueAndValidity();
        this.shipment = this.stepperService.newShipment;
        this.shipment.recipientAddress = {};
        this.shipment.externalCarrierServices = null;

        if (value.bookingType == 'PARCELSHOP') {
          this.addressForm.controls.parcelShopId.addValidators(Validators.required);
          this.addressForm.controls.parcelShopId.updateValueAndValidity();
          this.addressForm.controls.city.disable({ emitEvent: false });
          this.addressForm.controls.zipCode.disable({ emitEvent: false });
          this.addressForm.controls.countryCode.disable({ emitEvent: false });
          this.addressForm.controls.contactPerson.addValidators(Validators.required);
          this.addressForm.controls.contactPhone.addValidators(Validators.required);
          this.addressForm.controls.contactPhone.setErrors({ required: true });
          this.addressForm.controls.contactEmail.addValidators(Validators.required);
          this.addressForm.controls.contactEmail.setErrors({ required: true });
        } else {
          this.addressForm.controls.parcelShopId.removeValidators(Validators.required);
          this.addressForm.controls.parcelShopId.updateValueAndValidity();
          this.addressForm.controls.city.enable({ emitEvent: false });
          this.addressForm.controls.zipCode.enable({ emitEvent: false });
          this.addressForm.controls.countryCode.enable({ emitEvent: false });
          this.addressForm.controls.contactPerson.removeValidators(Validators.required);
          this.addressForm.controls.contactPerson.updateValueAndValidity();
          this.addressForm.controls.contactPhone.removeValidators(Validators.required);
          this.addressForm.controls.contactPhone.updateValueAndValidity();
          this.addressForm.controls.contactEmail.removeValidators(Validators.required);
          this.addressForm.controls.contactEmail.updateValueAndValidity();
          this.shipment.parcelShop = null;
        }

        this.shipment.bookingType = value.bookingType;
        this.setServicesbyBookingType();
      }
    });
  }

  setServicesbyBookingType() {
    switch (this.shipment.bookingType) {
      case 'PARCELSHOP':
        this.packageInfoForm.controls.weight.clearValidators();
        this.weightLimit = 20;
        this.packageInfoForm.controls.weight.setValidators([Validators.required, Validators.max(this.weightLimit), Validators.min(1)]);
        this.externalCarrierServices = [];
        this.servicesService.getExternalServicesByCarrier(this.shipment.carrier, this.shipment.bookingType).subscribe((result: any) => {
          this.shipment.externalCarrierServices = result.services;
          if (!this.shipment.externalCarrierServices.find(x => x.code == 'A7')) {
            this.shipment.externalCarrierServices.push({ code: 'A7', name: '' });
          }
        });
        break;
      case 'BUSINESS':
        this.packageInfoForm.controls.weight.clearValidators();
        this.weightLimit = 30;
        this.packageInfoForm.controls.weight.setValidators([Validators.required, Validators.max(this.weightLimit), Validators.min(1)]);
        this.servicesService.getExternalServicesByCarrier(this.shipment.carrier, this.shipment.bookingType).subscribe((result: any) => {
          this.externalCarrierServices = result.services;
        });
        break;
    }
  }


  getFormErrors() {
    let invalidFields = [];
    if (this.packageInfoForm.invalid) {
      for (let key in this.packageInfoForm.controls) {
        if (this.packageInfoForm.controls[key].invalid) {
          switch (key) {
            case 'weight':
              invalidFields.push('Vægt');
              break;
          }
        }
      }
    }
    if (this.addressForm.invalid) {
      for (let key in this.addressForm.controls) {
        if (this.addressForm.controls[key].invalid) {
          switch (key) {
            case 'contactPerson':
              invalidFields.push('Modtager Navn');
              break;
            case 'contactPhone':
              invalidFields.push('Modtager Mobil');
              break;
            case 'contactEmail':
              invalidFields.push('Modtager Email');
              break;
            case 'street':
              if (this.shipment.bookingType != 'PARCELSHOP') {
                invalidFields.push('Adresse');
              } else {
                invalidFields.push('Pakkeshop Adresse');
              }
              break;
            case 'parcelShopId':
              invalidFields.push('Pakkeshop');
              break;
            case 'note':
              invalidFields.push('Bemærkning');
              break;
          }
        }
      }
    }

    return `${invalidFields.join(', ')}`;
  }

  checkFormValidity() {
    if (!this.packageInfoForm.valid || !this.addressForm.valid) {
      this.messageService.add({
        severity: 'warn',
        summary: this.translateService.instant('MESSAGES.MISSINGFIELDS'),
        detail: this.getFormErrors()
      });

      if (this.packageInfoForm.controls.bookingType.value == 'PARCELSHOP') {
        if (!this.addressForm.controls.contactPerson.valid) {
          this.addressForm.controls.contactPerson.markAsDirty();
        }
        if (!this.addressForm.controls.contactPhone.valid) {
          this.addressForm.controls.contactPhone.markAsDirty();
          this.addressForm.controls.contactPhone.setErrors({ required: true });
        }
        if (!this.addressForm.controls.contactEmail.valid) {
          this.addressForm.controls.contactEmail.markAsDirty();
          this.addressForm.controls.contactEmail.setErrors({ required: true });
        }
        if (!this.addressForm.controls.parcelShopId.valid) {
          this.addressForm.controls.parcelShopId.markAsDirty();
        }
        if (!this.addressForm.controls.street.valid) {
          this.addressForm.controls.street.markAsDirty();
        }
      } else {
        if (!this.addressForm.controls.street.valid) {
          this.addressForm.controls.street.markAsDirty();
        }
        if (!this.addressForm.controls.note.valid) {
          this.addressForm.controls.note.markAsDirty();
        }
      }
      if (!this.packageInfoForm.controls.weight.valid) {
        this.packageInfoForm.controls.weight.markAsDirty();
      }

      return false;
    }

    return true;
  }

  bookShipment() {
    if (!this.checkFormValidity()) {
      return;
    }

    this.loading = true;
    if (this.shipment.externalCarrierServices) {
      this.shipment.services = [];
      this.shipment.externalCarrierServices.forEach((service: any) => {
        this.shipment.services.push(service.code);
      });
      this.shipment.externalCarrierServices = null;
    }

    this.shipmentService.createShipment(this.shipment).subscribe({
      next: (result: any) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Godkendt',
          detail: this.translateService.instant('MESSAGES.BOOKINGCREATED'),
        });
        this.packageInfoForm.reset();
        this.setPostnordValues();
        this.loading = false;
        this.externalCarrierServices = [];
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Fejl', detail: this.translateService.instant('BOOKINGERROR') });
        this.loading = false;
      },
      complete: () => { },
    });

  }

}

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DashboardComponent } from "./dashboard.component";
import { AddressComponent } from "../Shared/stepper/address/address.component";
import { ColliComponent } from "../Shared/stepper/colli/colli.component";
import { ExchangePalletsComponent } from "../Shared/stepper/exchange-pallets/exchange-pallets.component";
import { AccountComponent } from "../Shared/stepper/account/account.component";
import { ServiceInfoComponent } from "../Shared/stepper/service-info/service-info.component";
import { BookingInfoComponent } from "../Shared/stepper/booking-info/booking-info.component";
import { ContextMenuModule } from "primeng/contextmenu";
import { PayerComponent } from "../Shared/stepper/payer/payer.component";
import { DangerousGoodsComponent } from "../Shared/stepper/dangerous-goods/dangerous-goods.component";
import { GLSBooking } from "../Shared/gls-booking/gls-booking.component";
import { PostnordBookingComponent } from "../Shared/postnord-booking/postnord-booking.component";

const routes: Routes = [
    {
        path: '', component: DashboardComponent,
        children: [
            { path: 'sender', component: AddressComponent },
            { path: 'recipient', component: AddressComponent },
            { path: 'dangerousGoods', component: DangerousGoodsComponent },
            { path: 'collis', component: ColliComponent },
            { path: 'exchangePallets', component: ExchangePalletsComponent },
            { path: 'account', component: AccountComponent },
            { path: 'payer', component: PayerComponent },
            { path: 'services', component: ServiceInfoComponent },
            { path: 'note', component: BookingInfoComponent },
            { path: 'glsBooking', component: GLSBooking },
            { path: 'postnordBooking', component: PostnordBookingComponent }
        ]
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        ContextMenuModule
    ],
    declarations: [],
    exports: [RouterModule],
})
export class DashboardModule { } 
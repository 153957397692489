import { Component, OnDestroy, HostListener, ViewChild, AfterViewInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AppBreadcrumbService } from '../../services/app.breadcrumb.service';
import { Subscription } from 'rxjs';
import { AppConfig } from 'src/app/domain/appconfig';
import { ConfigService } from 'src/app/services/app.config.service';
import { ShipmentService } from 'src/app/services/shipment.service';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe, formatDate } from '@angular/common';
import { LabelService } from 'src/app/services/label.service';
import { TranslateService } from '@ngx-translate/core';
import { ShipmentGraphService } from 'src/app/services/shipment-graph.service';
import { ClientService } from 'src/app/services/client.service';

@Component({
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss'],
})
export class ArchiveComponent implements OnDestroy, AfterViewInit {
  @ViewChild('waybillno') waybillNoField: any;
  @ViewChild('deliveryCalendar') deliveryCalendar: any;
  @ViewChild('bookingCalendar') bookingCalendar: any;

  config: AppConfig;
  subscription: Subscription;
  sortings = [{ name: 'Modtaget dato', code: 'receivedDate' }];
  dropDownValue = [{ name: 'Alle', code: 'All' }];
  shipment: any;
  shipments: any;
  chosenShipmentId: any;
  pdfUrl: any;
  searchValues: any;
  selectedShipments: any;
  searchIsFocused: boolean = false;
  searching: boolean = true;
  options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  tenantLogo: any;
  loading: boolean = false;

  searchForm = new FormGroup({
    packageNo: new FormControl(null),
    senderName: new FormControl(null),
    waybillNo: new FormControl(null),
    senderZipcode: new FormControl(null),
    TrackAndTraceNo: new FormControl(null),
    receiverName: new FormControl(null),
    receiverZipcode: new FormControl(null),
    ref1: new FormControl(null),
    ref2: new FormControl(null),
    ref3: new FormControl(null),
    note: new FormControl(null),
    service: new FormControl(null),
    bookingDate: new FormControl<Date[] | null>([]),
    deliveryDate: new FormControl<Date[] | null>([]),
  });

  @HostListener('document:keydown.escape', ['$event']) onKeydownEscapeHandler(event: KeyboardEvent) {
    if (!this.searching) {
      this.clearChoice();
    }
  }
  @HostListener('document:keydown.backspace', ['$event'])
  onKeydownBackHandler(event: KeyboardEvent) {
    if (!this.searchIsFocused && !this.searching) {
      this.clearChoice();
    }
  }

  constructor(
    private breadcrumbService: AppBreadcrumbService,
    public configService: ConfigService,
    private messageService: MessageService,
    private sanitizer: DomSanitizer,
    private datePipe: DatePipe,
    private labelService: LabelService,
    private translateService: TranslateService,
    private shipmentGraphService: ShipmentGraphService,
    private shipmentService: ShipmentService,
    private clientService: ClientService
  ) {
    this.breadcrumbService.setItems([{ label: 'Arkiv', routerLink: ['archive'] }]);

    this.config = this.configService.config;
    this.subscription = this.configService.configUpdate$.subscribe((config) => {
      this.config = config;
    });

    this.tenantLogo = this.clientService.clientTenantLogo;
  }

  resetForm() {
    this.searchForm.reset();
    this.waybillNoField.nativeElement.focus();
  }

  onDeliveryDateSelect(value: any) {
    if (this.searchForm.value.deliveryDate.filter((x) => x == null).length == 0) {
      this.deliveryCalendar.overlayVisible = false;
    }
  }

  onBookingDateSelect(value: any) {
    if (this.searchForm.value.bookingDate.filter((x) => x == null).length == 0) {
      this.bookingCalendar.overlayVisible = false;
    }
  }

  performGraphSearch() {
    this.clearChoice();
    this.searching = true;
    this.loading = false;
    const formData = this.searchForm.value;
    if (Object.values(formData).every((x) => x === null || x === '' || x.length === 0)) {
      this.messageService.add({
        severity: 'info',
        summary: this.translateService.instant('MESSAGES.PLEASEFILLATLEASTONEFIELD'),
      });
      return;
    }
    this.shipmentGraphService
      .graphSearch(
        formData.packageNo,
        formData.waybillNo,
        formData.TrackAndTraceNo,
        formData.ref1,
        formData.ref2,
        formData.ref3,
        formData.service,
        formData.senderName,
        formData.receiverName,
        formData.senderZipcode,
        formData.receiverZipcode,
        formData.note,
        formData.bookingDate ? formData.bookingDate[0] : null,
        formData.bookingDate ? formData.bookingDate[1] : null,
        formData.deliveryDate ? formData.deliveryDate[0] : null,
        formData.deliveryDate ? formData.deliveryDate[1] : null
      )
      .subscribe({
        next: (res) => {
          this.shipments = [...res];
        },
        error: (error) => {
          console.error('Error while searching', error);
        },
      })
      .add(() => {
        this.searching = false;
      });
  }

  performSearch() {
    this.clearChoice();
    this.searching = true;
    const formData = this.searchForm.value;
    if (Object.values(formData).every((x) => x === null || x === '' || x.length === 0)) {
      this.messageService.add({
        severity: 'info',
        summary: this.translateService.instant('MESSAGES.PLEASEFILLATLEASTONEFIELD'),
      });
      return;
    }
    if (formData.deliveryDate && formData.deliveryDate[0] != null && formData.deliveryDate[1] == null) {
      this.messageService.add({
        severity: 'info',
        summary: this.translateService.instant('MESSAGES.PLEASEFILLDELIVERYDATETO'),
      });
      return;
    }
    if (formData.bookingDate && formData.bookingDate[0] != null && formData.bookingDate[1] == null) {
      this.messageService.add({
        severity: 'info',
        summary: this.translateService.instant('MESSAGES.PLEASEFILLBOOKINGDATETO'),
      });
      return;
    }
    this.shipmentService
      .archiveSearch({
        barcode: formData.packageNo,
        waybillNo: formData.waybillNo,
        trackAndTrace: formData.TrackAndTraceNo,
        reference1: formData.ref1,
        reference2: formData.ref2,
        reference3: formData.ref3,
        service: formData.service,
        senderName: formData.senderName,
        senderZip: formData.senderZipcode,
        recipientName: formData.receiverName,
        recipientZip: formData.receiverZipcode,
        deliveryDate: formData.deliveryDate && formData.deliveryDate[0] ? formatDate(formData.deliveryDate[0], 'dd/MM/yyyy', 'da-DK') : null,
        fromDate: formData.bookingDate && formData.bookingDate[0] ? formatDate(formData.bookingDate[0], 'dd/MM/yyyy', 'da-DK') : null,
        toDate: formData.bookingDate && formData.bookingDate[1] ? formatDate(formData.bookingDate[1], 'dd/MM/yyyy', 'da-DK') : null,
      })
      .subscribe((res) => {
        this.shipments = res;
        this.searching = false;
      });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.waybillNoField && this.waybillNoField.nativeElement) {
        this.waybillNoField.nativeElement.focus();
      }
    }, 100);
  }

  onRowSelect(event: any) {
    if (event.data) {
      this.shipment = event.data;
      this.chosenShipmentId = event.data.id;
      this.pdfUrl = null;
      this.loading = false;
    }
  }

  tooltipText(simpleShipment: any) {
    if (simpleShipment) {
      return simpleShipment.eventDate != null ? this.datePipe.transform(simpleShipment.eventDate, 'dd/MM/yyyy HH:mm:ss') : '';
    }
    return '';
  }

  onFocus(event?: any) {
    if (event) {
      this.searchIsFocused = true;
    } else {
      this.searchIsFocused = false;
    }
  }

  async printShipment(type: any): Promise<void> {

    if (!this.selectedShipments) {
      this.messageService.add({ severity: 'info', summary: this.translateService.instant('MESSAGES.CHOOSESHIPMENTFORPRINT') });
      return;
    }

    this.loading = true;
    this.chosenShipmentId = null;
    this.shipment = null;

    var shipmentIds = this.selectedShipments.map((shipment) => shipment.id);

    if (shipmentIds) {
      let queryParams = new HttpParams().append('printType', type);
      for (let shipmentId of shipmentIds) {
        queryParams = queryParams.append('shipmentIds', shipmentId);
      }

      if (type == 'label') {
        this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(await this.labelService.getMultipleLabels(shipmentIds));
      } else {
        this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.apiUrl}/shipments/print?${queryParams.toString()}`);
      }
    }
    this.loading = false;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  notImplemented() {
    this.messageService.add({
      severity: 'info',
      summary: this.translateService.instant('MESSAGES.NOTIMPLEMENTED'),
    });
  }

  clearChoice() {
    if (this.shipment) {
      this.shipment = null;
    }
    if (this.selectedShipments) {
      this.selectedShipments = [];
    }
    if (this.chosenShipmentId) {
      this.chosenShipmentId = null;
    }
    if (this.pdfUrl) {
      this.pdfUrl = null;
    }
    this.loading = false;
  }
}

import { Component, OnInit } from '@angular/core';
import { AppBreadcrumbService } from 'src/app/services/app.breadcrumb.service';
import { EdiService } from 'src/app/services/edi.service';

@Component({
  selector: 'app-edi',
  templateUrl: './edi.component.html',
  styleUrls: ['./edi.component.scss'],
})
export class EdiComponent implements OnInit {
  uploadedFiles: any[] = [];
  edi: any[] = [];

  constructor(private breadcrumbService: AppBreadcrumbService, private ediService: EdiService) {
    this.breadcrumbService.setItems([{ label: 'Upload Edi' }]);
  }

  ngOnInit(): void {}

  uploadFiles(event: any, fileUpload) {
    if (this.edi && this.edi.length > 0) {
      this.ediService.uploadFiles({ files: this.edi }).subscribe((res: any[]) => {
        if (res) {
          fileUpload.clear();
          this.edi = [];
          res.forEach((file) => {
            this.uploadedFiles.push({
              name: file.filename,
              id: file.ediId,
              status: 'Pending',
              shipments: [],
            });
            this.getEdiStatus(file.ediId);
          });
        }
      });
    }
  }

  onEdiFileSelect(event: any) {
    for (let file of event.files) {
      if (!this.edi.find((x) => x.name == file.name && x.size == file.size)) {
        this.edi.push(file);
      } else {
        // Was added
      }
    }
  }

  fileRemoved(event: any) {
    this.edi.splice(this.edi.indexOf(event.file), 1);
  }

  clearFiles(event: any) {
    this.edi = [];
  }

  getEdiStatus(ediId: string) {
    var timeoutId: any = null;
    var counter = 0;

    const pollForStatus = () => {
      this.ediService.getStatus(ediId).subscribe((res: any) => {
        if (res.finalized) {
          clearTimeout(timeoutId);
        } else if (counter >= 60) {
          clearTimeout(timeoutId);
        } else {
          timeoutId = setTimeout(pollForStatus, 2000);
        }
        counter++;

        const uploadedFile = this.uploadedFiles.find((f) => f.id === ediId);
        if (uploadedFile) {
          uploadedFile.status = res.status;
        }
        if (uploadedFile && uploadedFile.shipments) {
          for (const [shipmentId, shipmentStatus] of Object.entries(res.shipments)) {
            const shipment = uploadedFile.shipments.find((s) => s.id === shipmentId);
            if (shipment) {
              shipment.status = shipmentStatus;
            } else {
              uploadedFile.shipments.push({ id: shipmentId, status: shipmentStatus });
            }
          }
        }
      });
    };
    timeoutId = setTimeout(pollForStatus, 500);
  }

  getSampleFile() {
    this.ediService.getSampleFile().subscribe((res) => {
      const link = document.createElement('a');
      link.href = res;
      link.download = 'sample.csv';
      link.click();
    });
  }
}

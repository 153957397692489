<!-- <div style="position:absolute; top:0px;left: 0px;">
    <div class="backBtn" (click)="goBack()" pTooltip="Tilbage" tooltipPosition="top">
    </div>
</div> -->
<div class="col-12 md:col-12 grid">
    <div class="col-3 md:col-3">
        <img alt="PostNord" src="assets/layout/images/PostNord_wordmark.svg" class="postnordLogo" />
    </div>
    <div class="col-6 md:col-6 col-offset-3" style="align-self:center;" *ngIf="shipment.bookingType == 'PARCELSHOP'">
        <span
            style="line-height: 25px; color:red; letter-spacing:0.1rem; font-weight:500; text-decoration: underline;">{{'POSTNORD.PARCELSHOHELPERTEXT'
            | translate: {weight: '20', length: '150', cbm: '0,11'} }}
        </span>
    </div>

    <div class="col-6 md:col-6 col-offset-3" style="align-self:center;" *ngIf="shipment.bookingType == 'BUSINESS'">
        <span
            style="line-height: 25px; color:red; letter-spacing:0.1rem; font-weight:500;text-decoration: underline;">{{'POSTNORD.PARCELSHOHELPERTEXT'
            | translate: {weight: '30', length: '150', cbm: '0,11'} }}
        </span>
    </div>
</div>

<form [formGroup]="packageInfoForm" class="p-fluid p-formgrid grid">


    <div class="col-12 md:col-12 grid" style="margin-top:12px;">
        <div class="col-6 md:col-6 field-checkbox">
            <p-radioButton value="PARCELSHOP" formControlName="bookingType" inputId="parcelshop"></p-radioButton>
            <label for="parcelshop">{{'PARCELSHOPNAME' | translate}} ( MyPack Collect)</label>
        </div>

        <div class="col-6 md:col-6 field-checkbox">
            <p-radioButton value="BUSINESS" formControlName="bookingType" inputId="business"></p-radioButton>
            <label for="business">Post erhverv ( Erhvervs Pakke)</label>
        </div>

    </div>

    <div class="col-12 md:col-12 grid" style="padding-left:0px; padding-right:0px;" *ngIf="shipment.bookingType">
        <div class="col-4 md:col-4">
            <span class="p-float-label">
                <p-inputNumber [useGrouping]="false" formControlName="weight" id="weight"></p-inputNumber>
                <label for="weight">{{'SHIPMENT.WEIGHT' | translate}}</label>
            </span>

            <span style="padding:0px; margin:0px; font-size:13px; color:red; position:fixed;"
                *ngIf="packageInfoForm.controls['weight'].errors?.min">{{'WEGIHTMUSTBEABOVEZERO' | translate}}</span>

            <span style="padding:0px; margin:0px; font-size:13px; color:red; position:fixed;"
                *ngIf="packageInfoForm.controls['weight'].errors?.max">{{'WEIGHTISBEYONDLIMIT' | translate}}
                ({{weightLimit}})</span>

            <span style="padding:0px; margin:0px; margin-left:2px; font-size:13px; color:red; position:fixed;"
                *ngIf="packageInfoForm.controls['weight'].touched && packageInfoForm.controls['weight'].errors?.required">{{'WEIGHTREQUIRED'
                | translate}}</span>
        </div>

        <div class="col-8 md:col-8">
            <span class="p-float-label">
                <input formControlName="waybillNo" id="orderNo" pInputText />
                <label for="orderNo">{{'ORDERNO' | translate}}.</label>
            </span>
        </div>
    </div>

    <app-external-carrier-address [shipment]="shipment" [addressForm]="addressForm" [multiSelectServices]="true"
        [contactPersons]="contactPersons" [services]="externalCarrierServices"></app-external-carrier-address>

</form>


<div class="bottomBtns alignRightBottom">
    <button *ngIf="shipment.bookingType" pButton pRipple label="{{'CREATE' | translate}}"
        (click)="bookShipment()"></button>
</div>

<div class="spinner-overlay" *ngIf="loading">
    <p-progressSpinner styleClass="w-8rem h-8rem" strokeWidth="6" animationDuration="1s"></p-progressSpinner>
    <br />
    <p>{{'CREATINGBOOKING' | translate}}</p>
</div>
<div style="position: relative; margin-top:14px;">
    <h4 class="header">{{header}}</h4>
    <form [formGroup]="addressForm">

        <!-- Auto Complete Customer -->
        <div class="p-fluid p-formgrid grid row-gap-2">
            <div class="col-12 md:col-12">
                <span class="p-float-label">
                    <p-autoComplete [suggestions]="foundCustomers" [minLength]="3" formControlName="name" #name
                        [delay]="500" inputId="name" name="nameField" autocomplete="anyrandomstring"
                        showEmptyMessage="true" emptyMessage="{{'NORESULTS' | translate}}"
                        (completeMethod)="searchCustomer($event)" showClear="true" [field]="formattedCustomerName"
                        (onClear)="onClearCustomer()" (onSelect)="setCustomer($event)">
                        <ng-template let-customer pTemplate="customer">
                            <div class="customer-item">
                                <div>
                                    {{customer.name}}
                                    <span *ngIf="customer.address" class="grayedText">
                                        {{customer.address.street}}, {{customer.address.zipCode}},
                                        {{customer.address.city}}</span>
                                    <span *ngIf="customer.contactPerson" style="font-size:12px; color:gray;"> |
                                        {{'CUSTOMER.FORM.CONTACTPERSON' | translate}} {{customer.contactPerson.name}}
                                        <span *ngIf="customer.contactPerson.email">,
                                            {{customer.contactPerson.email}}</span>
                                        <span *ngIf="customer.contactPerson.phone">,
                                            {{customer.contactPerson.phone}}</span></span>
                                </div>
                            </div>
                        </ng-template>
                    </p-autoComplete>
                    <label for="name">{{'NAME' | translate}} {{'SEARCHFIELD' | translate}}</label>

                </span>
            </div>
            <!-- END Auto Complete Customer -->

            <!-- Auto Complete Address -->
            <div class="col-12 md:col-4">
                <span class="p-float-label">
                    <p-autoComplete [suggestions]="foundAddresses" [minLength]="3" formControlName="street"
                        name="addressField" showEmptyMessage="true" emptyMessage="{{'NORESULTS' | translate}}"
                        [delay]="500" inputId="addressStreet" #addressStreet autocomplete="anyrandomstring"
                        (completeMethod)="searchAddress($event)" showClear="true" (onClear)="onClearAddress()"
                        (onSelect)="setAddress($event)" [field]="formattedStreetName">

                        <ng-template let-address pTemplate="address">
                            <div class="address-item">
                                <div>
                                    <img *ngIf="address.countryCode" src="assets/demo/flags/flag_placeholder.png"
                                        [class]="'flag flag-' + address.countryCode.toLowerCase()" />
                                    {{address.street}},
                                    <span class="grayedText"> {{address.zipCode}} {{address.city}}</span>
                                    <span *ngIf="isDev()">, {{'CUSTOMER.FORM.VALIDATEDBY' | translate}} :
                                        {{address.validatedBy}}</span>
                                </div>
                            </div>
                        </ng-template>
                    </p-autoComplete>
                    <label for="addressStreet">{{'ADDRESS' | translate}}</label>
                </span>
            </div>
            <!-- END Auto complete Address -->

            <div class="col-12 md:col-2">
                <span class="p-float-label">
                    <input formControlName="zipCode" pInputText id="zipCode" #zipCode name="test"
                        autocomplete="anyrandomstring" [tabindex]="validatedAddress == true ? '-1' : '0'" type="text" />
                    <label for="zipCode">{{'ZIPCODE' | translate}}</label>
                </span>
            </div>

            <div class="col-12 md:col-3">
                <span class="p-float-label">
                    <input formControlName="city" [tabindex]="validatedAddress == true ? '-1' : '0'" pInputText
                        name="test" autocomplete="anyrandomstring" id="city" #city type="text" />
                    <label for="city">{{'CITY' | translate}}</label>
                </span>
            </div>

            <div class="col-12 md:col-3">
                <span class="p-float-label">
                    <p-dropdown inputId="countryCode" [options]="countries" placeholder="{{'COUNTRY' | translate}}"
                        formControlName="countryCode" [tabindex]="validatedAddress == true ? '-1' : '0'"
                        optionValue="code" name="test" autocomplete="anyrandomstring" optionLabel="name"></p-dropdown>
                    <label for="countryCode">{{'COUNTRY' | translate}}</label>
                </span>
            </div>

            <!-- Maps Icon -->
            <!-- <div class="col-12 md:col-1" class="mapIcon">
                <img style="width:60px; height:27px;" (click)="useMap()"
                    [src]="'assets/layout/images/GoogleMapsPin.svg'" pTooltip="Placér markør på kort" />
            </div> -->

            <!-- Leaflet Map -->
            <!-- <div id="leafletMap" leaflet (leafletMapReady)="onMapReady($event)" *ngIf="setAddressMarker" class="map"
                style="width:100%; height:450px; border-radius: 10px !important;">
            </div> -->

            <div class="col-12 md:col-4">
                <span class="p-float-label">
                    <p-autoComplete [dropdown]="contactPersons != null" [suggestions]="filteredContactPersons"
                        (completeMethod)="filterContactPersons($event)" field="name" formControlName="contactPerson"
                        (onSelect)="onContactPersonSelect($event)"></p-autoComplete>
                    <label for="contactPerson">{{'CONTACTPERSON' | translate}}</label>
                </span>
            </div>

            <div class="col-12 md:col-4">
                <div class="p-inputgroup">
                    <p-dropdown inputId="phone" [options]="countryCodes" [(ngModel)]="selectedPhoneCountry"
                        (onChange)="onPhoneCountryChange($event)" [disabled]="formDisabled"
                        [style]="{'min-width':'100%', 'border-right': '1px solid white', 'border-radius': '1px'}"
                        optionLabel="label" [ngModelOptions]="{standalone: true}">
                        <!-- Chosen country -->
                        <ng-template pTemplate="selectedItem">
                            <div class="flex align-items-center gap-2" *ngIf="selectedPhoneCountry">
                                <img src="/assets/demo/flags/flag_placeholder.png"
                                    [class]="'flag flag-' + selectedPhoneCountry.value?.toLowerCase()"
                                    style="width: 18px" />
                                <div>{{ selectedPhoneCountry.value }} {{selectedPhoneCountry.callCode}}</div>
                            </div>
                        </ng-template>

                        <!-- Suggestions -->
                        <ng-template let-country pTemplate="item">
                            <div class="flex align-items-center gap-2">
                                <img src="/assets/demo/flags/flag_placeholder.png"
                                    [class]="'flag flag-' + country.value.toLowerCase()" style="width: 18px" />
                                <div>{{ country.label }} {{country.callCode}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                    <input style="min-width:200px;" formControlName="contactPhone" pInputText id="contactPhone"
                        #contactPhone type="text" placeholder="{{'CONTACTPHONE' | translate}}" />
                </div>
                <div *ngIf="addressForm.controls['contactPhone'].errors?.invalid" style="text-align: center;">
                    <small style="color:#FC6161" id="contactPhoneInvalid" class="p-invalid">
                        {{'INVALIDNUMBER' | translate}}</small>
                </div>
            </div>

            <div class="col-12 md:col-4">
                <span class="p-float-label">
                    <input formControlName="contactEmail" pInputText id="contactEmail" #contactEmail type="text" />
                    <label for="contactEmail">{{'EMAIL' | translate}}</label>
                </span>
                <div *ngIf="addressForm.controls['contactEmail'].errors?.email">
                    <small style="color:#FC6161" id="contactEmailInvalid"
                        class="p-invalid">{{'CUSTOMER.FORM.INVALIDEMAIL' |
                        translate}}</small>
                </div>
            </div>

            <div class="col-12 md:col-4" *ngIf="!removeBtns">
                <span class="p-float-label">
                    <p-inputMask inputId="deliveryBefore" formControlName="date" mask="99/99/9999"
                        slotChar="dd/mm/yyyy"></p-inputMask>
                    <label for="deliveryBefore">{{isSenderForm ? ("PICKUP" | translate) : ("DELIVERY" |
                        translate)}}
                        {{'GENERIC.THE' | translate}}</label>
                </span>
                <div *ngIf="addressForm.controls['date'].errors?.LessThanOrEqualToToday">
                    <small style="color:#FC6161" id="deliveryBeforeInvalid" class="p-invalid">
                        {{'ADDRESSFORM.ERRORS.DATEBEFORETODAY' | translate}}
                    </small>
                </div>
                <div *ngIf="addressForm.controls['date'].errors?.LessThanToday">
                    <small style="color:#FC6161" id="deliveryBeforeInvalid"
                        class="p-invalid">{{'ADDRESSFORM.ERRORS.DATEAFTER' | translate}}</small>
                </div>
                <div *ngIf="addressForm.controls['date'].errors?.LessThanOrEqualToGivenDate">
                    <small style="color:#FC6161" id="deliveryBeforeInvalid"
                        class="p-invalid">{{'ADDRESSFORM.ERRORS.DATESAMEORAFTER' | translate}}</small>
                </div>
                <div *ngIf="addressForm.controls['date'].errors?.LessThanGivenDate">
                    <small style="color:#FC6161" id="deliveryBeforeInvalid"
                        class="p-invalid">{{'ADDRESSFORM.ERRORS.DATEAFTERPICKUPDATE' | translate}}</small>
                </div>
                <div *ngIf="addressForm.controls['date'].errors?.NotWeekday">
                    <small style="color:#FC6161" id="deliveryBeforeInvalid"
                        class="p-invalid">{{'ADDRESSFORM.ERRORS.DATEWEEKDAY' | translate}}</small>
                </div>
                <div *ngIf="addressForm.controls['date'].errors?.Holiday">
                    <small style="color:#FC6161" id="deliveryBeforeInvalid"
                        class="p-invalid">{{'ADDRESSFORM.ERRORS.DATEHOLIDAY' | translate}}</small>
                </div>
                <div *ngIf="address.date == null && !addressForm.controls['date'].errors">
                    <small style="color:#FC6161" id="deliveryBeforeInvalid"
                        class="p-invalid">{{'ADDRESSFORM.ERRORS.REQUIRED' | translate}}</small>
                </div>
            </div>
            <div class="col-12" [ngClass]="{'md:col-2': isSenderForm, 'md:col-3': !isSenderForm}" *ngIf="!removeBtns">
                <span class="p-float-label">
                    <p-inputMask inputId="deliveryBefore" formControlName="timeStart" mask="99:99"
                        (onComplete)="onTimeStartComplete()" slotChar="hh:mm"></p-inputMask>
                    <label for="delBefore">{{'AFTERTIME' | translate}}</label>
                </span>
                <small style="color:#FC6161" *ngIf="addressForm.controls['timeStart'].errors?.invalid"
                    class="p-invalid">
                    {{'ADDRESSFORM.ERRORS.INVALIDTIME' | translate}}
                </small>
            </div>
            <div class="col-12 " [ngClass]="{'md:col-2': isSenderForm, 'md:col-3': !isSenderForm}" *ngIf="!removeBtns">
                <span class="p-float-label">
                    <p-inputMask inputId="deliveryAfter" formControlName="timeEnd" mask="99:99"
                        (onComplete)="onTimeEndComplete()" slotChar="hh:mm"></p-inputMask>
                    <label for="delAfter">{{'BEFORETIME' | translate}}</label>
                </span>
                <small style="color:#FC6161" *ngIf="addressForm.hasError('invalidTimeEnd')" id="deliveryEndInvalid"
                    class="p-invalid">{{'ADDRESSFORM.ERRORS.INVALIDTIMEEND' | translate}}<br /></small>
                <small style="color:#FC6161" *ngIf="addressForm.controls.timeEnd.hasError('invalid')" class="p-invalid">
                    {{'ADDRESSFORM.ERRORS.INVALIDTIME' | translate}}
                </small>
            </div>
            <div class="field-checkbox col-2 md:col-2 saveCheckbox" *ngIf="!removeBtns">
                <p-checkbox name="saveAddressField" inputId="saveAddress" [binary]="true" formControlName="saveAddress">
                </p-checkbox>
                <label for="saveAddress">{{'SAVEADDRESS' | translate}}</label>
            </div>

            <div class="field-checkbox col-2 md:col-2 saveCheckbox" *ngIf="!removeBtns && isSenderForm">
                <p-checkbox name="sendLabel" inputId="sendLabel" [binary]="true"
                    formControlName="sendLabel"></p-checkbox>
                <label for="labelToSender">{{'ADDRESSFORM.LABELTOSENDER' | translate}}</label>
            </div>
        </div>
    </form>

    <p-divider></p-divider>

    <app-form-error *ngIf="!removeBtns" [controls]="addressForm.controls"></app-form-error>

    <div class="bottomBtns" *ngIf="!removeBtns" [ngClass]="{'alignRightBottom': stepperService.activeIndex == 0}">
        <button *ngIf="!checkHidePrevBtn()" pButton pRipple label="{{'GENERIC.PREVIOUS' | translate}}"
            (click)="stepperService.stepPrev()"></button>
        <button pButton pRipple label="{{'GENERIC.NEXT' | translate}}" [disabled]="!addressForm.valid || !address.date"
            (click)="goToNextStep()" (focus)="goToNextStep()"></button>
    </div>


</div>
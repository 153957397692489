import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { APOLLO_NAMED_OPTIONS, ApolloModule, NamedOptions } from 'apollo-angular';
import { InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { HttpClientModule } from '@angular/common/http';
import { ShipmentGraphService } from './services/shipment-graph.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule, ApolloModule],
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory(httpLink: HttpLink): NamedOptions {
        return {
          shipmentService: {
            cache: new InMemoryCache(),
            link: httpLink.create({ uri: '/api/graphql/shipments' }),
          },
        };
      },
      deps: [HttpLink],
    },
    ShipmentGraphService,
  ],
  exports: [HttpClientModule, ApolloModule],
})
export class GraphqlModule { }

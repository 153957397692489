import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ScanService {

    constructor(private httpClient: HttpClient) {
    }


    getScansByTrackAndTrace(trackAndTrace: any) {
        let queryParams = new HttpParams();
        queryParams = queryParams.append('trackAndTrace', trackAndTrace);
        return this.httpClient
            .get(`${environment.apiUrl}/scans/getscansbytrackandtrace`, { params: queryParams });
    }


    getScansByShipmentId(shipmentId: any) {
        let queryParams = new HttpParams();
        queryParams = queryParams.append('shipmentId', shipmentId);
        return this.httpClient
            .get(`${environment.apiUrl}/scans/getscansbytrackandtrace`, { params: queryParams });
    }
}


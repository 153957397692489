import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ColliService {

    constructor(private httpClient: HttpClient) { }

    updateColli(colli: any) {
        let colliUpdateCommand = { colli: colli };
        return this.httpClient.post(`${environment.apiUrl}/collis/update`, colliUpdateCommand);
    }

    updateColliLine(colli: any) {
        return this.httpClient.post(`${environment.apiUrl}/collis/updateLine`, colli);
    }

    createColli(colli: any) {
        return this.httpClient.post(`${environment.apiUrl}/collis/create`, colli);
    }

    deleteColli(colli: any) {
        let deleteColliCommand = { id: colli.id };
        return this.httpClient.post(`${environment.apiUrl}/collis/delete`, deleteColliCommand);
    }

    deleteColliLine(colli: any) {
        let deleteColliLineCommand = { lineId: colli.lineId };
        return this.httpClient.post(`${environment.apiUrl}/collis/deleteLine`, deleteColliLineCommand);
    }

}


import { Component } from '@angular/core';
import { AppComponent } from './app.component';
import { ClientService } from './services/client.service';

@Component({
    selector: 'app-footer',
    template: `
        <div class="layout-footer" style="height:unset;">
            <div class="footer-logo-container">
            </div>
            <span class="copyright">
                  &#169; OnlineBook
                 <span *ngIf="today"> - {{today}}</span> 
            </span>
        </div>
    `
})
export class AppFooterComponent {
    today: any;
    constructor(public app: AppComponent, public clientService: ClientService) {
        this.today = new Date().getFullYear();
    }
}

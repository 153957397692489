import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  services$ = new BehaviorSubject<any[]>([]);

  constructor(private http: HttpClient) {
    this.getServices().subscribe((res) => {
      this.services$.next(res);
    });
  }

  getServices(): Observable<any> {
    return this.http.get('/api/services').pipe(
      tap((res: any) => {
        this.services$.next(res);
      })
    );
  }
}

<div class="col-12 md:col-12">
    <div class="p-fluid p-formgrid grid">
        <form [formGroup]="newContactForm" class="col-12 md:col-12">
            <div class="field grid padTop">
                <div class="col-9 md:col-9">
                    <span class="p-float-label">
                        <input type="text" id="name" pInputText formControlName="name">
                        <label for="name">{{ 'CUSTOMER.CONTACT.NAME' | translate }}</label>
                    </span>
                </div>
                <div class="col-3 md:col-3">
                    <p-message severity="error" *ngIf="newContactForm.controls['name'].errors?.required"
                        text="{{ 'CUSTOMER.FORM.REQUIREDFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                    <p-message severity="success" *ngIf="newContactForm.controls['name'].valid"
                        text="{{ 'CUSTOMER.FORM.OKFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                </div>
            </div>
            <div class="field grid">
                <div class="col-9 md:col-9">
                    <span class="p-float-label">
                        <input type="email" id="email" pInputText formControlName="email">
                        <label for="email">{{ 'CUSTOMER.CONTACT.EMAIL' | translate }}</label>
                    </span>
                </div>
                <div class="col-3 md:col-3">
                    <p-message severity="error"
                        *ngIf="newContactForm.controls['email'].errors?.required || newContactForm.errors?.atLeastOne"
                        text="{{ 'CUSTOMER.FORM.REQUIREDFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                    <p-message severity="error" *ngIf="newContactForm.controls['email'].errors?.email"
                        text="{{ 'CUSTOMER.FORM.INVALIDEMAIL' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                    <p-message severity="success"
                        *ngIf="!newContactForm.errors?.atLeastOne && newContactForm.controls['email'].valid"
                        text="{{ 'CUSTOMER.FORM.OKFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                </div>
            </div>
            <div class="field grid">
                <div class="col-9 md:col-9">
                    <div class="p-inputgroup">
                        <p-dropdown inputId="phone" [options]="countryCodes" [(ngModel)]="selectedPhoneCountry"
                            appendTo="body" (onChange)="onPhoneCountryChange($event)"
                            [style]="{'min-width':'100%', 'border-right': '1px solid white', 'border-radius': '1px'}"
                            optionLabel="label" [ngModelOptions]="{standalone: true}">
                            <!-- Chosen country -->
                            <ng-template pTemplate="selectedItem">
                                <div class="flex align-items-center gap-2" *ngIf="selectedPhoneCountry">
                                    <img src="/assets/demo/flags/flag_placeholder.png"
                                        [class]="'flag flag-' + selectedPhoneCountry.value?.toLowerCase()"
                                        style="width: 18px" />
                                    <div>{{ selectedPhoneCountry.value }} {{selectedPhoneCountry.callCode}}</div>
                                </div>
                            </ng-template>

                            <!-- Suggestions -->
                            <ng-template let-country pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                    <img src="/assets/demo/flags/flag_placeholder.png"
                                        [class]="'flag flag-' + country.value.toLowerCase()" style="width: 18px" />
                                    <div>{{ country.label }} {{country.callCode}}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                        <input style="min-width:520px;" formControlName="phone" pInputText id="phone" #phone type="text"
                            placeholder="{{'CUSTOMER.CONTACT.PHONE' | translate}}" />
                    </div>
                </div>
                <div class="col-3 md:col-3">
                    <p-message severity="error"
                        *ngIf="newContactForm.controls['phone'].errors?.required || newContactForm.errors?.atLeastOne"
                        text="{{ 'CUSTOMER.FORM.REQUIREDFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                    <p-message severity="error" *ngIf="newContactForm.controls['phone'].errors?.invalid"
                        text="Ugyldig nummer" class="scalein animation-duration-1000">
                    </p-message>
                    <p-message severity="success"
                        *ngIf="!newContactForm.errors?.atLeastOne && newContactForm.controls['phone'].valid"
                        text="{{ 'CUSTOMER.FORM.OKFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                </div>
            </div>

            <div class="field grid">
                <div class="col-8 md:col-8">
                    <p-checkbox inputId="sendLabel" formControlName="sendLabel" binary="true"
                        label="Send label"></p-checkbox>
                </div>
                <div class="col-4 md:col-4">
                    <p-message severity="info"
                        *ngIf="!newContactForm.controls['email'].valid || !newContactForm.controls['email'].value"
                        class="scalein animation-duration-1000" text="Udfyld Email for at sende label"></p-message>
                </div>
            </div>

        </form>
    </div>
    <div class="col-12 md:col-12" style="text-align: end;">
        <span style="padding-right:4px;" *ngIf="newContactForm.touched && !newContactForm.valid">{{
            'CUSTOMER.FORM.INVALID' | translate
            }}</span>
        <button style="margin-right:8px;" pButton pRipple label="{{ 'GENERIC.SAVE' | translate }}" icon="pi pi-check"
            (click)="addContactPerson()" [disabled]="!newContactForm.valid" class="p-button-success"></button>
        <button pButton pRipple label="{{ 'GENERIC.CANCEL' | translate }}" icon="pi pi-times" (click)="closeDialog()"
            class="p-button-warning"></button>
    </div>
</div>
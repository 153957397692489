import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Address } from 'src/app/domain/Address';
import { AddressService } from 'src/app/services/address.service';
import { CountryService } from 'src/app/services/country.service';

@Component({
  selector: 'app-address-dialog',
  templateUrl: './address-dialog.component.html',
  styleUrls: ['./address-dialog.component.scss'],
  providers: [DialogService],
})
export class AddressDialogComponent implements OnInit {
  newAddressForm: FormGroup;
  countries: any[];
  selectedCountry: any;
  foundAddresses: any;

  constructor(private countryService: CountryService, private addressService: AddressService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

  ngOnInit(): void {
    if (!this.config.data) {
      this.ref.close();
    }
    this.newAddressForm = this.config.data;
    this.fetchCountries();
    if (this.newAddressForm.value.countryCode) {
      this.selectedCountry = this.countries.find((country) => country.code === this.newAddressForm.value.countryCode).code;
    }
  }

  fetchCountries() {
    this.countries = this.countryService.getClientCountries();
  }

  onClearAddress() {
    this.newAddressForm.reset();
  }

  setAddress(event: any) {
    this.newAddressForm.patchValue({
      street: event.street,
      zipCode: event.zipCode,
      city: event.city,
      countryCode: event.countryCode,
      longitude: event.longitude,
      latitude: event.latitude,
    });
  }

  formattedStreetName(item: Address) {
    if (item && item.street) {
      return `${item.street}`;
    }
    if (item) {
      return `${item}`;
    }
    return '';
  }

  searchAddress(event: any) {
    this.addressService.autoCompleteAddress(event.query).subscribe((addressResult: any) => {
      if (addressResult && addressResult.items) {
        this.foundAddresses = addressResult.items;
      }
    });
  }

  addAddress() {
    this.ref.close(this.newAddressForm);
  }

  close() {
    this.ref.close();
  }
}

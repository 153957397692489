<div style="display:flex; justify-content: space-between;">
    <h4 class="primColor">{{'EXCHANGEPALLETS' | translate}}</h4>
</div>

<form [formGroup]="exchangePalletsFormGroup">
    <div class="p-fluid p-formgrid grid">
        <div class="col-3 md:col-3 field-checkbox">
            <p-checkbox name="includeExchangePallets" formControlName="includeExchangePallets"
                inputId="includeExchangePallets" [binary]="true"></p-checkbox>
            <label for="includeExchangePallets">{{'INCLUDEEXCHANGEPALLETS' | translate}}</label>
        </div>


        <div class="col-3 md:col-3" formGroupName="exchangePallets">
            <span class="p-float-label">
                <p-inputNumber formControlName="fullExchangePallets" type="number" inputId="fullPalletsQuantity"
                    [useGrouping]="false" [min]="0"></p-inputNumber>
                <label for="fullPalletsQuantity">{{'FULLPALLETLABEL' | translate}}</label>
            </span>
            <small style="color:#FC6161"
                *ngIf="exchangePalletsFormGroup.get('exchangePallets').get('fullExchangePallets').errors?.max">
                {{'ABOVELIMIT' | translate}}
            </small>
        </div>

        <div class="col-3 md:col-3" formGroupName="exchangePallets">
            <span class="p-float-label">
                <p-inputNumber formControlName="halfExchangePallets" type="number" inputId="halfPalletsQuantity"
                    [useGrouping]="false" [min]="0"></p-inputNumber>
                <label for="halfPalletsQuantity">{{'HALFPALLETLABEL' | translate}}</label>
            </span>
            <small style="color:#FC6161"
                *ngIf="exchangePalletsFormGroup.get('exchangePallets').get('halfExchangePallets').errors?.max">
                {{'ABOVELIMIT' | translate}}
            </small>
        </div>

        <div class="col-3 md:col-3" formGroupName="exchangePallets">
            <span class="p-float-label">
                <p-inputNumber formControlName="quarterExchangePallets" type="number" inputId="quarterPalletQuantity"
                    [useGrouping]="false" [min]="0"></p-inputNumber>
                <label for="quarterPalletQuantity">{{'QUARTERPALLETLABEL' | translate}}</label>
            </span>
            <small style="color:#FC6161"
                *ngIf="exchangePalletsFormGroup.get('exchangePallets').get('quarterExchangePallets').errors?.max">
                {{'ABOVELIMIT' | translate}}
            </small>
        </div>
    </div>
</form>

<div class="bottomBar" *ngIf="!removeBtns">
    <button pButton pRipple label="{{'GENERIC.PREVIOUS' | translate}}" *ngIf="stepperService.activeIndex > 0"
        (click)="stepperService.stepPrev()"></button>
    <button [disabled]="!this.exchangePalletsFormGroup.valid" pButton pRipple label="{{'GENERIC.NEXT' | translate}}"
        (focus)="stepperService.stepNext()" (click)="stepperService.stepNext()"></button>
</div>
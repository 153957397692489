<p-toast></p-toast>
<!-- Booking page -->
<div class="grid">
    <!-- Left side-->
    <div class="col-12 md:col-7">

        <div class="card" style="position: relative;">
            <div #stepper>
                <p-steps [model]="stepperService.steps" [readonly]="false"
                    [activeIndex]="stepperService.activeIndex"></p-steps>
            </div>
            <p-divider *ngIf="stepperService.steps.length > 0"></p-divider>

            <h4 *ngIf="!stepperService.bookingChoice" class="text-center">{{'BOOKINGHEADER' | translate}}</h4>

            <div *ngIf="!stepperService.bookingChoice" class="col-12 md:col-12 p-fluid p-formgrid grid buttonGrid">
                <p-button class="bookingChoiceBtn" (click)="setBookingChoice('book')"
                    pTooltip="{{'BOOKINGHEADER' | translate}}" tooltipPosition="top">
                    <div style="width:100%;">
                        <img alt="parcel" src="assets/layout/images/parcel.png" class="parcelLogo" />
                    </div>
                </p-button>
                <p-button class="bookingChoiceBtn" (click)="setBookingChoice('gls')" *ngIf="enableGLSBooking">
                    <img alt="GLS" src="assets/layout/images/GLS_logo.png" class="glsLogo" />
                </p-button>
                <!-- <p-button class="bookingChoiceBtn" (click)="setBookingChoice('postnord')" *ngIf="enablePostnordBooking">
          <img alt="PostNord" src="assets/layout/images/PostNord_wordmark.svg" class="postnordLogo" />
        </p-button> -->
            </div>

            <router-outlet *ngIf="stepperService.bookingChoice"></router-outlet>
        </div>

    </div>

    <!-- Right side-->
    <div class="col-12 md:col-4 col-offset-1">
        <app-booking-preview [shipment]="stepperService.newShipment" [senderCustomer]="stepperService.senderCustomer"
            [recipientCustomer]="stepperService.recipientCustomer"></app-booking-preview>
    </div>

</div>

<!-- Context Menu for Resetting booking -->
<p-contextMenu [target]="stepper" [model]="contextMenuItems"></p-contextMenu>
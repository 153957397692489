import { OBPermissions } from '@alicetms/auth.permissions';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Inject, Injectable, Output } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ClientService } from './client.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, @Inject(DOCUMENT) private document: Document, private clientService: ClientService, private router: Router) {}

  private authenticated = false;
  public logoutUrl: string = null;

  @Output() features = new EventEmitter<IBffUserInfoClaim[]>();
  @Output() userProfile: UserProfile;
  private permissions: IBffUserInfoClaim[];
  private settings: IBffUserInfoClaim[];

  login(returnUrl: string = '/') {
    this.document.location.href = `/bff/login?returnUrl=${encodeURI(returnUrl)}`;
  }

  logout() {
    this.document.location.href = this.logoutUrl;
  }

  async isAuthenticated(): Promise<boolean> {
    if (!this.authenticated) {
      await lastValueFrom(this.getUserInfo())
        .then((claims: IBffUserInfoClaim[] | any) => {
          this.authenticated = true;

          this.logoutUrl = this.getLogoutUrl(claims);

          const features = claims.filter((x) => x.type.toLowerCase().includes('ft:'));

          this.features.emit(features);

          this.userProfile = {
            email: this.getEmail(claims),
            name: this.getFullName(claims),
            pictureUrl: '/assets/layout/images/profile-image.png',
          };

          const profilePictureClaim = claims.find((claim: IBffUserInfoClaim) => claim.type === 'avatar');

          this.getClientClaims(claims);

          if (profilePictureClaim) {
            this.userProfile.pictureUrl = profilePictureClaim.value;
          }

          this.permissions = claims.filter((claim: IBffUserInfoClaim) => claim.type === 'permission');

          this.settings = claims.filter((claim: IBffUserInfoClaim) => claim.type === 'client:setting');
        })
        .catch((err) => {
          console.error('Failed to get user info', err);
          this.logoutUrl = this.getLogoutUrl(err.error);
          this.authenticated = false;
          if (err.status === 401) {
            this.login();
          }
          if (err.status === 403) {
            this.authenticated = true;
            this.router.navigate(['forbidden']);
          }
        });
    }

    return this.authenticated;
  }

  private getClientClaims(claims: any) {
    this.clientService.clientTenantName = this.filterClaims(claims, '__tenant__')[0];
    this.clientService.clientTenant = this.filterClaims(claims, 'tenant:name')[0];
    this.clientService.clientPermissions = this.filterClaims(claims, 'client:permission');
    this.clientService.clientAgreements = this.filterClaims(claims, 'client:agreement');
    this.clientService.clientServices = this.filterClaims(claims, 'client:service');
    this.clientService.clientAccounts = this.filterClaims(claims, 'client:account');
    this.clientService.clientPayers = this.filterClaims(claims, 'client:payer');
    this.clientService.defaultClientPhone = this.filterClaims(claims, 'client:phone')[0];
    this.clientService.defaultClientEmail = this.filterClaims(claims, 'client:email')[0];
    this.clientService.defaultClientName = this.filterClaims(claims, 'client:name')[0];
    this.clientService.defaultClientNumber = this.filterClaims(claims, 'client:number')[0];
    this.clientService.defaultClientContactPerson = this.filterClaims(claims, 'client:contactPerson')[0];
    this.clientService.clientAddress = this.filterClaims(claims, 'client:address')[0];
    this.clientService.clientName = this.filterClaims(claims, 'client:name')[0];
    this.clientService.countries = this.filterClaims(claims, 'client:countries')[0];
    this.clientService.clientId = this.filterClaims(claims, '__clientId__')[0];
    this.clientService.clientTenantLogo = this.filterClaims(claims, 'tenant:logoUri')[0];
    this.clientService.clientReadyDeadline = this.filterClaims(claims, 'client:readyDeadline')[0];
  }

  private filterClaims(claims: IBffUserInfoClaim[], type: string): any[] {
    return claims.filter((claim) => claim.type === type).map((claim) => claim.value);
  }

  private getLogoutUrl(claims: IBffUserInfoClaim[]): string {
    const logoutUrlClaim = claims.find((claim: IBffUserInfoClaim) => claim.type === 'bff:logout_url');
    if (logoutUrlClaim) {
      return logoutUrlClaim.value;
    }
    return null;
  }

  private getFullName(claims: IBffUserInfoClaim[]): string {
    const prefix = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims';
    let fullName = 'User';
    const givenName = claims.find((claim) => claim.type === `${prefix}/givenname`);
    const surName = claims.find((claim) => claim.type === `${prefix}/surname`);
    if (givenName) {
      fullName = givenName.value;
    }
    if (surName) {
      fullName = `${fullName} ${surName.value}`;
    }
    return fullName.trim();
  }

  private getEmail(claims: IBffUserInfoClaim[]): string {
    var claimEmail = claims.find((claim: IBffUserInfoClaim) => claim.type === 'email');

    if (claimEmail) {
      return claimEmail.value;
    }

    return null;
  }

  private getUserInfo() {
    return this.http.get('/bff/user', {
      headers: new HttpHeaders({
        'X-CSRF': '1',
      }),
    });
  }

  hasPermission(permission: OBPermissions): boolean {
    return this.permissions.some((claim: IBffUserInfoClaim) => claim.value == permission);
  }

  hasSetting(setting: string): boolean {
    return this.settings.some((claim: IBffUserInfoClaim) => claim.value == setting);
  }
}

export interface IBffUserInfoClaim {
  type: string;
  value: string;
}

export interface UserProfile {
  name: string;
  email: string;
  pictureUrl: string;
}

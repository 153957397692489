import { formatDate } from '@angular/common';
import { Component, OnInit, OnDestroy, Input, ViewChild, isDevMode, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { Address } from 'src/app/domain/Address';
import { AppConfig } from 'src/app/domain/appconfig';
import { AddressService } from 'src/app/services/address.service';
import { ConfigService } from 'src/app/services/app.config.service';
import { CountryService } from 'src/app/services/country.service';
import { CustomerService } from 'src/app/services/customer.service';
import { StepperService } from 'src/app/pages/Shared/stepper/stepper.service';
import { DateValidator } from '../../validators/date.validators';
import * as Leaflet from 'leaflet';
import { Router } from '@angular/router';
import { getLayers } from 'src/app/pages/dashboard/dashboard.component';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { customArray as CountryList } from 'country-codes-list';
import { OBPermissions } from '@alicetms/auth.permissions';
import { AuthService } from 'src/app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { DatesService } from 'src/app/services/dates.service';
import { ClientService } from 'src/app/services/client.service';
var Holidays = require('date-holidays');
@Component({
  templateUrl: './address.component.html',
  selector: 'app-address',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() header = 'Addresse';
  @Input() address = <Address>{};
  @Input() removeBtns: boolean = false;
  @ViewChild('name') name: any;
  @ViewChild('addressStreet') addressStreet: any;

  config: AppConfig;
  subscription: Subscription;

  holidays: any;

  foundAddresses: any;
  foundCustomers: any;
  selectedAddress: any;
  validatedAddress: boolean = false;
  countryCodes = CountryList({ value: '{countryCode}', label: '{countryNameEn}', callCode: '+{countryCallingCode}' });
  selectedPhoneCountry: any;
  formDisabled = false;
  setAddressMarker: any;
  addressIsValid = false;
  map: Leaflet.Map;
  options: Leaflet.MapOptions = {
    layers: getLayers(),
    zoom: 12,
    center: new Leaflet.LatLng(55.676098, 12.568337),
  };

  bookingType: any[] = [
    { label: 'Erhverv', value: 'Industry' },
    { label: 'Privat', value: 'Private' },
  ];
  colliTypes = [
    { name: 'Ldm', code: 'Ldm' },
    { name: 'Palle', code: 'Palle' },
    { name: 'Kasse', code: 'Kasse' },
  ];
  countries: any;
  contactPersons: any;

  filteredContactPersons: any;
  filteredDeliveryBefore: any;
  filteredDelivery: any;

  BookPickupsPermission = OBPermissions.BookPickups;
  BookWithPickupDatePermission = OBPermissions.BookWithPickupDate;

  isSenderForm: boolean = false;

  addressForm = new FormGroup(
    {
      name: new FormControl(this.address.name, Validators.required),
      street: new FormControl(this.address.street, Validators.required),
      date: new FormControl(null, [Validators.required, Validators.pattern('^\\d{2}/\\d{2}/\\d{4}$')]),
      contactPerson: new FormControl(),
      contactPhone: new FormControl(),
      contactEmail: new FormControl(null, [
        Validators.email,
        Validators.pattern(
          /^[A-Za-z0-9!#$%&amp;'*+\/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&amp;'*+\/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/
        ),
      ]),
      timeStart: new FormControl(),
      timeEnd: new FormControl(),
      saveAddress: new FormControl(),
      zipCode: new FormControl(this.address.zipCode, Validators.required),
      city: new FormControl(this.address.city, Validators.required),
      countryCode: new FormControl(this.address.countryCode, Validators.required),
      sendLabel: new FormControl({ value: this.address.sendLabel, disabled: true }),
    },
    { validators: DateValidator.TimeStartTimeEndValidator }
  );

  constructor(
    public configService: ConfigService,
    private addressService: AddressService,
    private messageService: MessageService,
    public stepperService: StepperService,
    private countryService: CountryService,
    private customerService: CustomerService,
    private router: Router,
    private authService: AuthService,
    private translateService: TranslateService,
    private dateService: DatesService,
    private clientService: ClientService
  ) {
    this.config = this.configService.config;
    this.subscription = this.configService.configUpdate$.subscribe((config) => {
      this.config = config;
    });
    this.bookingType = [
      { label: 'Erhverv', value: 'Industry' },
      { label: 'Privat', value: 'Private' },
    ];

    this.checkFormData();

    const route = this.router.url;
    if (route == '/booking/sender') {
      this.address = this.stepperService.newShipment.senderAddress;
      if (this.stepperService.senderContactPersons && this.address.name) {
        this.contactPersons = this.stepperService.senderContactPersons;
      }
      this.header = this.translateService.instant('SENDER');
      this.addressForm.get('contactEmail').statusChanges.subscribe((status) => {
        if (status == 'INVALID') {
          this.addressForm.get('sendLabel').disable();
          this.addressForm.get('sendLabel').setValue(false);
        } else {
          this.addressForm.get('sendLabel').enable();
          this.addressForm.controls.sendLabel.setValue(true);
        }
      });
      this.isSenderForm = true;
      if (!isDevMode()) {
        if (!this.authService.hasPermission(this.BookPickupsPermission)) {
          const controlsToDisable = ['name', 'street', 'zipCode', 'city', 'countryCode', 'contactPerson', 'contactPhone', 'contactEmail', 'timeStart', 'timeEnd', 'saveAddress'];

          controlsToDisable.forEach((control) => {
            this.addressForm.get(control)?.disable();
          });
          this.formDisabled = true;
        }
      }
    }
    if (route == '/booking/recipient') {
      this.address = this.stepperService.newShipment.recipientAddress;

      if (this.stepperService.recipientContactPersons && this.address.name) {
        this.contactPersons = this.stepperService.recipientContactPersons;
      }
      this.header = this.translateService.instant('RECEIVER');
    }

    this.selectedPhoneCountry = this.countryCodes.find((c: any) => c.value == 'DK');

    this.initializeHolidays('DK');
  }

  initializeHolidays(country: string) {
    this.holidays = new Holidays(country, null, null, { types: ['public', 'bank'] });
  }

  isHoliday(date: Date) {
    var holidays = this.holidays.isHoliday(date);
    if (!holidays) return holidays;

    if (holidays.filter((h) => h.type != 'observance').length > 0) return true;

    return false;
  }

  ngAfterViewInit(): void {
    this.name?.nativeElement?.focus();
  }

  useMap() {
    this.setAddressMarker = !this.setAddressMarker;
  }

  onPhoneCountryChange(event: any) {
    if (this.addressForm.controls.contactPhone.value) {
      const isValidPhone = isValidPhoneNumber(this.addressForm.controls.contactPhone.value, this.selectedPhoneCountry.value);
      if (!isValidPhone) {
        this.addressForm.controls.contactPhone.setErrors({ invalid: true });
      } else {
        this.addressForm.controls.contactPhone.setErrors(null);
      }
    }
  }

  onMapReady(map: Leaflet.Map) {
    this.map = map.setView([55.9396761, 9.5155848], 7);

    Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    }).addTo(this.map);

    this.map.on('click', (event: any) => {
      Leaflet.marker(event.latlng, {
        icon: Leaflet.icon({
          ...Leaflet.Icon.Default.prototype.options,
          iconUrl: '/assets/layout/images/marker-icon.png',
          shadowUrl: '/assets/layout/images/marker-shadow.png',
        }),
      }).addTo(this.map);
    });
  }

  filterContactPersons(event: any) {
    if (!this.contactPersons) {
      this.filteredContactPersons = [];
      return;
    }
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.contactPersons.length; i++) {
      let contactPerson = this.contactPersons[i];
      if (contactPerson.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(contactPerson);
      }
    }

    this.filteredContactPersons = filtered;
  }

  onContactPersonSelect(event: any) {
    this.addressForm.patchValue({
      contactPhone: event.phone,
      contactEmail: event.email,
      contactPerson: { name: event.name },
    });
    if (this.isSenderForm) {
      this.addressForm.patchValue({ sendLabel: event.sendLabel });
    }
    this.address.contactPerson = event.name;
  }

  hardcodedStenaCheckRemoveASAP() {
    if (this.clientService.clientTenantName == 'tjw' && this.clientService.defaultClientNumber == '43964246') {
      // Stena
      this.stepperService.newShipment.recipientAddress.name = 'LOOP/Electronic';
      this.stepperService.newShipment.recipientAddress.city = 'Roskilde';
      this.stepperService.newShipment.recipientAddress.zipCode = '4000';
      this.stepperService.newShipment.recipientAddress.countryCode = 'DK';
      this.stepperService.newShipment.recipientAddress.contactEmail = 'info@loop-eletronic.dk';
      this.stepperService.newShipment.recipientAddress.contactPhone = '40454133';
      this.stepperService.newShipment.recipientAddress.street = 'Navervej 19';
      this.stepperService.newShipment.recipientAddress.latitude = 55.63852;
      this.stepperService.newShipment.recipientAddress.longitude = 12.11967;
      this.addressForm.patchValue({
        name: this.stepperService.newShipment.recipientAddress.name,
        street: this.stepperService.newShipment.recipientAddress.street,
        zipCode: this.stepperService.newShipment.recipientAddress.zipCode,
        city: this.stepperService.newShipment.recipientAddress.city,
        countryCode: this.stepperService.newShipment.recipientAddress.countryCode,
        contactEmail: this.stepperService.newShipment.recipientAddress.contactEmail,
        contactPhone: this.stepperService.newShipment.recipientAddress.contactPhone,
      });
    }
  }

  ngOnInit() {
    this.fetchCountries();
    var today = new Date();

    this.addressForm.patchValue({ countryCode: 'DK' }, { emitEvent: false }); // Default country
    if (this.address && this.address.street) {
      // Address was populated earlier
      this.addressForm.patchValue(
        {
          name: this.address.name,
          street: this.address.street,
          date: this.address.date ? this.address.date.toString() : undefined,
          timeStart: this.address.timeStart,
          timeEnd: this.address.timeEnd,
          contactPhone: this.address.contactPhone,
          zipCode: this.address.zipCode,
          city: this.address.city,
          countryCode: this.address.countryCode,
          saveAddress: this.address.saveAddress,
          sendLabel: this.address.sendLabel,
        },
        { emitEvent: false }
      );
      if (this.address.contactEmail && this.address.contactEmail != '') {
        this.addressForm.patchValue({ contactEmail: this.address.contactEmail }, { emitEvent: false });
      }
      if (this.address.contactPerson) {
        this.addressForm.patchValue({ contactPerson: { name: this.address.contactPerson } });
      }

      if (this.addressForm.controls.contactEmail.valid && this.addressForm.controls.contactEmail.value && this.addressForm.controls.contactEmail.value != '') {
        this.addressForm.get('sendLabel').enable();
        this.addressForm.patchValue({ sendLabel: true }, { emitEvent: false });
        this.address.sendLabel = true;
      }

      if (!this.address.date && this.router.url == '/booking/sender') {
        var unFormattedDate = new Date(today.setDate(today.getDate()));
        if (this.stepperService.newShipment.senderAddress.date) {
          var senderDate = this.dateService.parseDateArrayToDate(this.stepperService.newShipment.senderAddress.date.toString().split('/'));
          unFormattedDate = new Date(senderDate.setDate(senderDate.getDate() + 1));
        }

        var formattedDate = formatDate(unFormattedDate, 'dd/MM/yyyy', 'da-DK');
        var isBusinessDay = this.dateService.isBusinessDayOrHoliday(this.dateService.parseDateArrayToDate(formattedDate.split('/')), this.address.countryCode);
        if (isBusinessDay) {
          this.addressForm.patchValue({ date: formattedDate });
          if (this.address) {
            this.address.date = formattedDate;
          }
        } else {
          var nextBusinessDay = new Date(unFormattedDate);
          while (this.dateService.isBusinessDayOrHoliday(nextBusinessDay, this.address.countryCode) == false) {
            nextBusinessDay.setDate(nextBusinessDay.getDate() + 1);
          }
          var formattedNextBusinessDay = formatDate(nextBusinessDay, 'dd/MM/yyyy', 'da-DK');
          this.addressForm.patchValue({ date: formattedNextBusinessDay });
          if (this.address) {
            this.address.date = formattedNextBusinessDay;
          }
        }
      }
      if (this.stepperService.resetBookingDate) {
        var unFormattedDate = new Date(today.setDate(today.getDate()));
        var formattedDate = formatDate(unFormattedDate, 'dd/MM/yyyy', 'da-DK');
        var isBusinessDay = this.dateService.isBusinessDayOrHoliday(this.dateService.parseDateArrayToDate(formattedDate.split('/')), this.address.countryCode);
        if (isBusinessDay) {
          this.addressForm.patchValue({ date: formattedDate });
          if (this.address) {
            this.address.date = formattedDate;
          }
        } else {
          var nextBusinessDay = new Date(unFormattedDate);
          while (this.dateService.isBusinessDayOrHoliday(nextBusinessDay, this.address.countryCode) == false) {
            nextBusinessDay.setDate(nextBusinessDay.getDate() + 1);
          }
          var formattedNextBusinessDay = formatDate(nextBusinessDay, 'dd/MM/yyyy', 'da-DK');
          this.addressForm.patchValue({ date: formattedNextBusinessDay });
          if (this.address) {
            this.address.date = formattedNextBusinessDay;
          }
        }
        this.stepperService.resetBookingDate = false;
      }
    } else {
      if (!this.address?.date) {
        const route = this.router.url;

        if (route == '/booking/sender') {
          var unFormattedDate = new Date(today.setDate(today.getDate()));

          var formattedDate = formatDate(unFormattedDate, 'dd/MM/yyyy', 'da-DK');
          var isBusinessDay = this.dateService.isBusinessDayOrHoliday(this.dateService.parseDateArrayToDate(formattedDate.split('/')), this.address.countryCode);
          if (isBusinessDay) {
            this.addressForm.patchValue({ date: formattedDate });
            if (this.address) {
              this.address.date = formattedDate;
            }
          } else {
            // Find next available weekday
            var nextBusinessDay = new Date(unFormattedDate);
            while (this.dateService.isBusinessDayOrHoliday(nextBusinessDay, this.address.countryCode) == false) {
              nextBusinessDay.setDate(nextBusinessDay.getDate() + 1);
            }
            var formattedNextBusinessDay = formatDate(nextBusinessDay, 'dd/MM/yyyy', 'da-DK');
            this.addressForm.patchValue({ date: formattedNextBusinessDay });
            if (this.address) {
              this.address.date = formattedNextBusinessDay;
            }
          }
        }

        if (route == '/booking/recipient') {
          if (this.stepperService.newShipment.senderAddress.date == null) {
            var tmpDate = new Date(today.setDate(today.getDate()));
            var formattedDate = formatDate(tmpDate, 'dd/MM/yyyy', 'da-DK');
            this.stepperService.newShipment.senderAddress.date = formattedDate;
          }
          var senderDate = this.dateService.parseDateArrayToDate(this.stepperService.newShipment.senderAddress.date.toString().split('/'));
          var unFormattedDate = new Date(senderDate.setDate(senderDate.getDate() + 1));

          var formattedDate = formatDate(unFormattedDate, 'dd/MM/yyyy', 'da-DK');
          var isBusinessDay = this.dateService.isBusinessDayOrHoliday(this.dateService.parseDateArrayToDate(formattedDate.split('/')), this.address.countryCode);
          if (isBusinessDay) {
            this.addressForm.patchValue({ date: formattedDate });
            if (this.address) {
              this.address.date = formattedDate;
            }
          } else {
            // Find next available weekday
            var nextBusinessDay = new Date(unFormattedDate);
            while (this.dateService.isBusinessDayOrHoliday(nextBusinessDay, this.address.countryCode) == false) {
              nextBusinessDay.setDate(nextBusinessDay.getDate() + 1);
            }
            var formattedNextBusinessDay = formatDate(nextBusinessDay, 'dd/MM/yyyy', 'da-DK');
            this.addressForm.patchValue({ date: formattedNextBusinessDay });
            if (this.address) {
              this.address.date = formattedNextBusinessDay;
            }
          }
          this.hardcodedStenaCheckRemoveASAP(); // REMOVE THIS WHEN POSSIBLE
        }
      } else {
        if (this.router.url == '/booking/sender') {
          this.addressForm.patchValue({ date: this.address.date });
        }
        if (this.router.url == '/booking/recipient') {
          var senderDate = this.dateService.parseDateArrayToDate(this.stepperService.newShipment.senderAddress.date.toString().split('/'));
          var recipientDate = this.dateService.parseDateArrayToDate(this.address.date.toString().split('/'));
          if (recipientDate > senderDate && this.dateService.isBusinessDayOrHoliday(recipientDate, this.address.countryCode)) {
            this.addressForm.patchValue({ date: this.address.date });
          } else {
            // Find next available weekday
            var nextBusinessDay = new Date(senderDate);
            nextBusinessDay.setDate(nextBusinessDay.getDate() + 1);
            while (this.dateService.isBusinessDayOrHoliday(nextBusinessDay, this.address.countryCode) == false) {
              nextBusinessDay.setDate(nextBusinessDay.getDate() + 1);
            }
            var formattedNextBusinessDay = formatDate(nextBusinessDay, 'dd/MM/yyyy', 'da-DK');
            this.addressForm.patchValue({ date: formattedNextBusinessDay });
          }
        }
      }
    }
    this.checkSettingsPermission();
  }

  checkSettingsPermission() {
    const route = this.router.url;
    var pickupDeliverySameDate = this.authService.hasSetting('book.pickupdeliverysameday');

    if (pickupDeliverySameDate) {
      // Validate that Min Date is today
      this.addressForm.controls.date.setValidators(DateValidator.GreaterThanOrEqualToday);
      if (route == '/booking/recipient') {
        this.addressForm.controls.date.setValidators(DateValidator.GreaterThanOrEqualGivenDate(this.stepperService.newShipment.senderAddress.date));
      }
    } else {
      switch (route) {
        case '/booking/sender':
          this.addressForm.controls.date.setValidators(DateValidator.GreaterThanOrEqualToday);
          break;
        case '/booking/recipient':
          this.addressForm.controls.date.setValidators(DateValidator.GreaterThanGivenDate(this.stepperService.newShipment.senderAddress.date));
          break;
      }
    }
  }

  goToNextStep() {
    if (this.addressForm.valid) {
      this.stepperService.stepNext();
    }
  }

  fetchCountries() {
    if (!this.countries) {
      this.countries = this.countryService.getClientCountries();
    }
  }

  onTimeStartComplete() {
    if (this.addressForm.controls.timeStart.value) {
      if (
        this.addressForm.controls.timeStart.value.length == 5 &&
        parseInt(this.addressForm.controls.timeStart.value.substring(0, 2)) <= 23 &&
        parseInt(this.addressForm.controls.timeStart.value.substring(3, 5)) <= 59
      ) {
        this.address.timeStart = this.addressForm.controls.timeStart.value;
      } else {
        this.address.timeStart = null;
        this.addressForm.controls.timeStart.setValue(this.addressForm.controls.timeStart.value);
        this.addressForm.controls.timeStart.setErrors({ invalid: true });
      }
    }
  }

  onTimeEndComplete() {
    if (this.addressForm.controls.timeEnd.value) {
      if (
        this.addressForm.controls.timeEnd.value.length == 5 &&
        parseInt(this.addressForm.controls.timeEnd.value.substring(0, 2)) <= 23 &&
        parseInt(this.addressForm.controls.timeEnd.value.substring(3, 5)) <= 59
      ) {
        this.address.timeEnd = this.addressForm.controls.timeEnd.value;
      } else {
        this.address.timeEnd = null;
        this.addressForm.controls.timeEnd.setValue(this.addressForm.controls.timeEnd.value);
        this.addressForm.controls.timeEnd.setErrors({ invalid: true });
      }
    }
  }

  checkFormData() {
    this.addressForm.valueChanges.subscribe((data) => {
      this.address.sendLabel = data.sendLabel;

      if (data.timeStart && data.timeStart == 'hh:mm') {
        this.address.timeStart = null;
      }
      if (data.timeEnd && data.timeEnd == 'hh:mm') {
        this.address.timeEnd = null;
      }
      if (data.street) {
        this.address.street = data.street;
      }
      if (this.addressForm.controls.name.valid) {
        this.address.name = data.name;
      }

      if (this.addressForm.controls.date.valid && this.addressForm.controls.date.value && this.addressForm.controls.date.value != 'dd/mm/yyyy') {
        var isBusinessDay = this.dateService.isBusinessDay(this.dateService.parseDateArrayToDate(data.date.split('/')));
        if (isBusinessDay) {
          if (this.address.countryCode) {
            this.initializeHolidays(this.address.countryCode);
          }

          var isHoliday = this.isHoliday(this.dateService.parseDateArrayToDate(data.date.split('/')));
          if (!isHoliday) {
            this.address.date = data.date;
          } else {
            this.addressForm.controls.date.setErrors({ Holiday: true });
          }
        } else {
          this.addressForm.controls.date.setErrors({ NotWeekday: true });
        }
      } else {
        this.address.date = null;
      }

      if (data.contactPhone) {
        if (this.selectedPhoneCountry) {
          const isValidPhone = isValidPhoneNumber(data.contactPhone, this.selectedPhoneCountry.value);
          if (isValidPhone) {
            this.addressForm.controls.contactPhone.setErrors(null);
            this.address.contactPhone = data.contactPhone;
          } else {
            this.addressForm.controls.contactPhone.setErrors({ invalid: true });
          }
        } else {
          this.addressForm.controls.contactPhone.setErrors({ isRequired: true });
        }
      }

      this.address.contactPhone = data.contactPhone;

      if (data.zipCode) {
        this.address.zipCode = data.zipCode;
      }
      if (data.city) {
        this.address.city = data.city;
      }
      if (data.countryCode) {
        this.address.countryCode = data.countryCode;
      }
      if (this.addressForm.controls.contactEmail.valid) {
        if (!this.addressForm.controls.contactEmail.value || this.addressForm.controls.contactEmail.value == '') {
          this.addressForm.controls.sendLabel.disable({ emitEvent: false });
          this.addressForm.controls.sendLabel.setValue(false, { emitEvent: false });
        }
        this.address.contactEmail = data.contactEmail;
      }
      if (this.addressForm.controls.contactPerson.valid) {
        if (data.contactPerson && data.contactPerson.hasOwnProperty('name')) {
          this.address.contactPerson = data.contactPerson.name;
        } else {
          this.address.contactPerson = data.contactPerson;
        }
      }
      this.address.saveAddress = data.saveAddress;
    });
  }

  onClearAddress() {
    this.validatedAddress = false;
    this.enableAddressFields();
    this.clearAddressForm(false);
    this.addressForm.markAsPristine();
    this.addressStreet?.inputEL?.nativeElement?.focus();
  }

  onClearCustomer() {
    this.clearAddressForm(true);
    this.enableAddressFields();
    this.addressForm.markAsPristine();
    this.contactPersons = null;
    this.filteredContactPersons = null;
    this.name?.inputEL?.nativeElement?.focus();
  }

  clearAddressForm(includeContact: boolean) {
    this.validatedAddress = false;
    this.addressForm.patchValue({ street: '', zipCode: '', city: '', countryCode: 'DK' });
    this.address.street = '';
    this.address.city = '';
    this.address.zipCode = '';
    this.address.countryCode = '';
    this.address.latitude = null;
    this.address.longitude = null;
    if (includeContact) {
      this.addressForm.patchValue({ name: '', contactPerson: null, contactEmail: null, contactPhone: null });
      this.address.name = '';
      this.address.contactPerson = null;
      this.address.contactPhone = null;
      this.address.contactEmail = null;
    }
  }

  enableAddressFields() {
    this.addressForm.get('zipCode').enable();
    this.addressForm.get('city').enable();
    this.addressForm.get('countryCode').enable();
  }

  disableAddressFields(disableCountryCode) {
    this.addressForm.get('zipCode').disable();
    this.addressForm.get('city').disable();
    if (disableCountryCode) {
      this.addressForm.get('countryCode').disable();
    }
  }

  onClearContactPerson(event: any) {
    this.addressForm.patchValue({ contactPerson: null, contactEmail: null, contactPhone: null, sendLabel: false });
    this.address.contactPerson = null;
    this.address.contactEmail = null;
    this.address.contactPhone = null;
    this.address.sendLabel = false;
  }

  setAddress(event: any) {
    this.validatedAddress = true;
    let disableCountryCode = true;
    if (!event.countryCode) {
      disableCountryCode = false;
      this.addressForm.controls.countryCode.setValidators(Validators.required);
      this.addressForm.controls.countryCode.markAsDirty();
      this.addressForm.controls.countryCode.updateValueAndValidity();
    }
    this.addressForm.patchValue({ street: event.street, zipCode: event.zipCode, city: event.city, countryCode: event.countryCode });
    this.disableAddressFields(disableCountryCode);
    this.address.street = event.street;
    this.address.city = event.city;
    this.address.zipCode = event.zipCode;
    this.address.countryCode = event.countryCode;
    this.address.latitude = event.latitude;
    this.address.longitude = event.longitude;
    this.address.validatedBy = event.validatedBy;
  }

  setCustomer(cust: any) {
    this.addressForm.patchValue({ name: cust.name });
    if (this.router.url == '/booking/sender') {
      this.stepperService.senderCustomer = cust;
    }
    if (this.router.url == '/booking/recipient') {
      this.stepperService.recipientCustomer = cust;
    }

    if (cust.contactPersons) {
      this.contactPersons = cust.contactPersons;

      if (this.router.url == '/booking/sender') {
        this.stepperService.senderContactPersons = this.contactPersons;
      }
      if (this.router.url == '/booking/recipient') {
        this.stepperService.recipientContactPersons = this.contactPersons;
      }
    }

    if (cust.contactPerson) {
      this.addressForm.patchValue({ contactPerson: { name: cust.contactPerson.name }, contactEmail: cust.contactPerson.email, contactPhone: cust.contactPerson.phone });
      if (this.isSenderForm) {
        this.addressForm.patchValue({ sendLabel: cust.contactPerson.sendLabel });
      }
      this.address.contactPerson = cust.contactPerson.name;
      this.address.contactPhone = cust.contactPerson.phone;
      this.address.contactEmail = cust.contactPerson.email;
      if (cust.contactPerson.sendLabel && this.isSenderForm) {
        this.address.sendLabel = cust.contactPerson.sendLabel;
      }
    }

    if (!cust.contactPerson) {
      this.addressForm.patchValue({ contactPerson: null, contactEmail: null, contactPhone: null, sendLabel: false });
      this.address.contactPerson = null;
      this.address.contactPhone = null;
      this.address.contactEmail = null;
      this.address.sendLabel = false;
    }

    if (cust.address) {
      this.validatedAddress = true;
      this.setAddress(cust.address);
    }
  }

  formattedStreetName(item: Address) {
    // If Address is chosen (Auto Complete)
    if (item && item.street) {
      return `${item.street}`;
    }
    // if Address is manually typed
    if (item) {
      return `${item}`;
    }
    return '';
  }

  formattedCustomerName(item: any) {
    if (item && item.name) {
      return `${item.name}`;
    }
    if (item) {
      return `${item}`;
    }
    return '';
  }

  searchAddress(event: any) {
    this.addressService.autoCompleteAddress(event.query).subscribe((addressResult: any) => {
      if (addressResult && addressResult.items) {
        this.foundAddresses = addressResult.items;
      }
    });
  }

  searchCustomer(event: any) {
    this.customerService.searchCustomerFormatted(event.query).subscribe(
      (result: any) => {
        if (result) {
          this.foundCustomers = result;
        }
      },
      (error: any) => {
        this.foundCustomers = [];
      }
    );
  }

  isDev() {
    if (isDevMode()) {
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  checkHidePrevBtn() {
    if (this.stepperService.activeIndex == 0) {
      return true;
    }
    return false;
  }
}

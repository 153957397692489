<!-- <div style="position:absolute; top:0px;left: 0px;">
  <div class="backBtn" (click)="goBack()" pTooltip="Tilbage" tooltipPosition="top">
  </div>
</div> -->
<img alt="GLS" src="assets/layout/images/GLS_logo.png" class="glsLogo" />


<form [formGroup]="packageInfoForm" class="p-fluid p-formgrid grid">
  <div class="col-5 md:col-5 col-offset-1">
    <span style="text-decoration: underline;">{{'GLS.MEASUREPARCELTEXT' | translate}}</span>
  </div>

  <div class="col-5 md:col-5 col-offset-1">
    <span style="text-decoration: underline;">{{'WEIGHTLIMITS' | translate}}</span>
  </div>


  <div class="col-6 md:col-6" style="padding-top:0px;">
    <span style="line-height: 25px; letter-spacing:normal;">{{'GLS.LENGTHDEMAND' | translate}}</span><br />
    <span>{{'GLS.LENGTHCALCDEMAND' | translate}}</span>
  </div>

  <div class="col-5 md:col-5 col-offset-1" style="padding-top:0px;">
    <span style="line-height: 25px;">{{'GLS.BUSINESSPARCELMAXWEIGHT' | translate}}</span><br />
    <span style="line-height: 25px;">{{'GLS.PRIVATEPARCELMAXWEIGHT' | translate}}</span><br />
    <span>{{'GLS.ABROADPARCELMAXWEIGHT' | translate}}</span>
  </div>


  <div class="col-9 md:col-9 col-offset-3">
    <span style="line-height: 25px; color:red; letter-spacing:0.1rem;">{{'EXTERNALCARRIER.LIMITHELPERTEXT' |
      translate}}</span><br />
    <span style="color:red; letter-spacing:0.1rem;">{{'EXTERNALCARRIER.LIMITREDTEXT' | translate}}</span>
  </div>


  <div class="col-12 md:col-12 grid">
    <div class="col-3 md:col-3 field-checkbox">
      <p-radioButton value="BUSINESS" formControlName="bookingType" inputId="business"></p-radioButton>
      <label for="business">{{'BUSINESS' | translate}}</label>
    </div>

    <div class="col-3 md:col-3 field-checkbox">
      <p-radioButton value="PARCELSHOP" formControlName="bookingType" inputId="parcelShop"></p-radioButton>
      <label for="parcelShop">{{'PARCELSHOPNAME' | translate}}</label>
    </div>

    <div class="col-3 md:col-3 field-checkbox" *ngIf="checkPermission(glsPrivatePermission)">
      <p-radioButton value="PRIVATE" formControlName="bookingType" inputId="private"></p-radioButton>
      <label for="private">{{'PRIVATEDELIVERY' | translate}}</label>
    </div>

    <div class="col-3 md:col-3 field-checkbox" *ngIf="checkPermission(glsAbroadPermission)">
      <p-radioButton value="ABROAD" formControlName="bookingType" inputId="abroad"></p-radioButton>
      <label for="abroad">{{'ABROAD' | translate}}</label>
    </div>

  </div>

  <div class="col-12 md:col-12 grid" style="padding-left:0px; padding-right:0px;" *ngIf="shipment.bookingType">
    <div class="col-4 md:col-4">
      <span class="p-float-label">
        <p-inputNumber [useGrouping]="false" formControlName="weight" id="weight"></p-inputNumber>
        <label for="weight">{{'SHIPMENT.WEIGHT' | translate}}</label>
      </span>

      <span style="padding:0px; margin:0px; font-size:13px; color:red; position:fixed;"
        *ngIf="packageInfoForm.controls['weight'].errors?.min">{{'EXTERNALCARRIER.ERRORMESSAGE.WEGIHTMUSTBEABOVEZERO' |
        translate}}</span>

      <span style="padding:0px; margin:0px; font-size:13px; color:red; position:fixed;"
        *ngIf="packageInfoForm.controls['weight'].errors?.max">{{'EXTERNALCARRIER.ERRORMESSAGE.WEIGHTISBEYONDLIMIT' |
        translate}}
        ({{weightLimit}})</span>

      <span style="padding:0px; margin:0px; margin-left:2px; font-size:13px; color:red; position:fixed;"
        *ngIf="packageInfoForm.controls['weight'].touched && packageInfoForm.controls['weight'].errors?.required">{{'EXTERNALCARRIER.ERRORMESSAGE.WEIGHTREQUIRED'
        | translate}}</span>
    </div>

    <div class="col-8 md:col-8">
      <span class="p-float-label">
        <input formControlName="waybillNo" id="orderNo" pInputText />
        <label for="orderNo">{{'ORDERNO' | translate}}.</label>
      </span>
    </div>
  </div>


  <app-external-carrier-address [shipment]="shipment" [addressForm]="addressForm"
    [services]="externalCarrierServices"></app-external-carrier-address>

</form>

<div class="bottomBtns alignRightBottom">
  <button *ngIf="shipment.bookingType" pButton pRipple label="{{'CREATE' | translate}}"
    (click)="bookShipment()"></button>
</div>

<div class="spinner-overlay" *ngIf="loading">
  <p-progressSpinner styleClass="w-8rem h-8rem" strokeWidth="6" animationDuration="1s"></p-progressSpinner>
  <br />
  <p>{{'CREATINGBOOKING' | translate}}</p>
</div>
import { Injectable } from '@angular/core';
var Holidays = require('date-holidays');
var hd = new Holidays('DK');

@Injectable({
    providedIn: 'root'
})
export class DatesService {

    constructor() { }

    isBusinessDay(date: Date): boolean {
        const dayOfWeek = date.getDay(); // Sunday: 0, Monday: 1, ..., Saturday: 6

        // Check if it's a weekend (Saturday or Sunday)
        if (dayOfWeek === 0 || dayOfWeek === 6) {
            return false;
        }

        return true;
    }

    isBusinessDayOrHoliday(date: Date, countryCode: any): boolean {
        const dayOfWeek = date.getDay(); // Sunday: 0, Monday: 1, ..., Saturday: 6

        // Check if it's a weekend (Saturday or Sunday)
        if (dayOfWeek === 0 || dayOfWeek === 6) {
            return false;
        }

        if (countryCode) {
            hd = new Holidays(countryCode, null, null, { types: ['public', 'bank'] });
        }

        var holidays = hd.isHoliday(date);

        if (!holidays) {
            return true;
        }

        if (holidays.filter((h) => h.type != 'observance').length > 0) return false;

        return true;
    }

    // Helper method to check if dates are equal ( used to check if date is holiday)
    isSameDay(date1: Date, date2: Date): boolean {
        return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
    }

    parseDateArrayToDate(dateArray: string[]): Date {
        return new Date(dateArray[1] + '/' + dateArray[0] + '/' + dateArray[2]);
    }

}


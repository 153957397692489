import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-mail-menu-dialog',
  templateUrl: './mail-menu-dialog.component.html',
  styleUrls: ['./mail-menu-dialog.component.scss']
})
export class MailMenuDialogComponent implements OnInit {
  sendMailForm = new FormGroup({
    recipient: new FormControl("", [Validators.required, Validators.email]),
    subject: new FormControl("", [Validators.required]),
    text: new FormControl("", [Validators.required]),
  });

  pdfUrl: any = this.sanitizer.bypassSecurityTrustResourceUrl("https://online-book.dk/api/ob_mail_pdf.asp?id=" + this.config.data.id);

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private sanitizer: DomSanitizer,
    private clientService: ClientService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    if (!this.config.data) {
      this.ref.close();
    }


    this.sendMailForm.patchValue({
      subject: this.translateService.instant('ABOUTPARCEL') + this.config.data.waybillNo,
      text: this.translateService.instant('ATTACHECTFREIGHT') + this.config.data.waybillNo + this.translateService.instant('SENDBYAGREEMENT') + "\n\n\n" + this.translateService.instant('KINDREGARDS') + "\n" +
        (this.clientService.clientTenant != null ? this.clientService.clientTenant : "") + "\n\n" + this.translateService.instant('MAILAUTOGENERATED')
    });

  }


  sendMail() {
    if (this.sendMailForm.valid) {
      this.ref.close(this.sendMailForm.value);
    }
  }

  close() {
    this.ref.close();
  }

}

<p-toast></p-toast>

<!-- Search Sidebar -->
<p-sidebar [(visible)]="searching" position="left" [style]="{width: '35em'}">
    <ng-template pTemplate="content">
        <div class="col-12 md:col-12">
            <div class="card">
                <button pButton label="Ryd" icon="pi pi-replay" iconPos="right" (click)="resetForm()"
                    style="position: absolute;top:26px;left:43px;"></button>

                <h5 style="color:var(--primary-color); font-weight:500; margin-bottom:14px;" class="text-center">
                    {{'SEARCHMENU' | translate}}
                </h5>
                <form [formGroup]="searchForm" class="p-fluid p-formgrid grid" (ngSubmit)="performGraphSearch()">
                    <div class="col-6 md:col-6">
                        <span class="p-float-label">
                            <input pInputText id="name" type="text" name="test0" (focus)="onFocus($event)"
                                formControlName="packageNo" (blur)="onFocus()" autocomplete="anyrandomstring0" />
                            <label for="name">{{'PACKAGENO' | translate}}</label>
                        </span>
                    </div>
                    <div class="col-6 md:col-6">
                        <span class="p-float-label">
                            <input pInputText id="name" type="text" name="test2" (focus)="onFocus($event)"
                                formControlName="senderName" (blur)="onFocus()" autocomplete="anyrandomstring2" />
                            <label for="name">{{'SENDERNAME' | translate}}</label>
                        </span>
                    </div>

                    <div class="col-6 md:col-6">
                        <span class="p-float-label">
                            <input pInputText #waybillno id="waybillno" type="text" name="test3"
                                (focus)="onFocus($event)" formControlName="waybillNo" (blur)="onFocus()"
                                autocomplete="anyrandomstring3" />
                            <label for="name">{{'FREIGHTNO' | translate}}</label>
                        </span>
                    </div>
                    <div class="col-6 md:col-6">
                        <span class="p-float-label">
                            <input pInputText id="name" type="text" name="test4" (focus)="onFocus($event)"
                                formControlName="senderZipcode" (blur)="onFocus()" autocomplete="anyrandomstring4" />
                            <label for="name">{{'SENDERZIPCODE' | translate}}</label>
                        </span>
                    </div>

                    <div class="col-6 md:col-6">
                        <span class="p-float-label">
                            <input pInputText id="name" type="text" name="test5" (focus)="onFocus($event)"
                                formControlName="TrackAndTraceNo" (blur)="onFocus()" autocomplete="anyrandomstring5" />
                            <label for="name">{{'TRACKINGNO' | translate}}</label>
                        </span>
                    </div>
                    <div class="col-6 md:col-6">
                        <span class="p-float-label">
                            <input pInputText id="name" type="text" name="test6" (focus)="onFocus($event)"
                                formControlName="receiverName" (blur)="onFocus()" autocomplete="anyrandomstring6" />
                            <label for="name">{{'RECEIVERNAME' | translate}}</label>
                        </span>
                    </div>
                    <div class="col-6 md:col-6">
                        <span class="p-float-label">
                            <input pInputText id="name" type="text" name="test13" (focus)="onFocus($event)"
                                formControlName="service" (blur)="onFocus()" autocomplete="anyrandomstring13" />
                            <label for="name">{{'SERVICE' | translate}}</label>
                        </span>
                    </div>

                    <div class="col-6 md:col-6">
                        <span class="p-float-label">
                            <input pInputText id="name" type="text" name="test8" (focus)="onFocus($event)"
                                formControlName="receiverZipcode" (blur)="onFocus()" autocomplete="anyrandomstring8" />
                            <label for="name">{{'RECEIVERZIPCODE' | translate}}</label>
                        </span>
                    </div>

                    <div class="col-6 md:col-6">
                        <span class="p-float-label">
                            <input pInputText id="name" type="text" name="test9" (focus)="onFocus($event)"
                                formControlName="ref1" (blur)="onFocus()" autocomplete="anyrandomstring9" />
                            <label for="name">{{'REF1' | translate}}</label>
                        </span>
                    </div>

                    <div class="col-6 md:col-6">
                        <span class="p-float-label">
                            <input pInputText id="name" type="text" name="test10" (focus)="onFocus($event)"
                                formControlName="ref2" (blur)="onFocus()" autocomplete="anyrandomstring10" />
                            <label for="name">{{'REF2' | translate}}</label>
                        </span>
                    </div>
                    <div class="col-6 md:col-6">
                        <span class="p-float-label">
                            <input pInputText id="name" type="text" name="test11" (focus)="onFocus($event)"
                                formControlName="ref3" (blur)="onFocus()" autocomplete="anyrandomstring11" />
                            <label for="name">{{'REF3' | translate}}</label>
                        </span>
                    </div>
                    <div class="col-6 md:col-6">
                        <span class="p-float-label">
                            <input pInputText id="name" type="text" name="test12" (focus)="onFocus($event)"
                                formControlName="note" (blur)="onFocus()" autocomplete="anyrandomstring12" />
                            <label for="name">{{'NOTE' | translate}}</label>
                        </span>
                    </div>

                    <div class="col-12 md:col-12">
                        <span class="p-float-label">
                            <p-calendar [showIcon]="true" formControlName="bookingDate" selectionMode="range"
                                (onSelect)="onBookingDateSelect($event)" #bookingCalendar [showButtonBar]="true"
                                appendTo="body"></p-calendar>
                            <label for="name">{{'BOOKINGDATEFROMTO' | translate}}</label>
                        </span>
                    </div>

                    <div class="col-12 md:col-12">
                        <span class="p-float-label">
                            <p-calendar [showIcon]="true" formControlName="deliveryDate" selectionMode="range"
                                #deliveryCalendar (onSelect)="onDeliveryDateSelect($event)"
                                [showButtonBar]="true"></p-calendar>
                            <label for="name">{{'DELIVERYDATEFROMTO' | translate}}</label>
                        </span>
                    </div>

                    <div class="col-12 md:col-12">
                        <button pButton label="{{'GENERIC.SEARCH' | translate}}" icon="pi pi-search" iconPos="right"
                            type="submit"></button>
                    </div>

                </form>
            </div>
        </div>
    </ng-template>
</p-sidebar>

<div class="p-fluid p-formgrid grid">
    <div class="col-6 md:col-6">
        <div class="card" style="position: relative;">
            <div class="col-3 md:col-3" style="margin-top:-10px;">
                <p-button label="{{'SEARCHMENU' | translate}}" icon="pi pi-search"
                    (click)="searching = true"></p-button>
            </div>

            <span *ngIf="shipments" style="position: absolute; top:20px; right:50%;">{{'AMOUNT' | translate}}:
                {{shipments.length}}</span>

            <div id="buttons" *ngIf="shipments">
                <button pButton class="p-button-text p-button-rounded" tooltipPosition="top" icon="pi pi-book"
                    pTooltip="{{'PALLETSLIST' | translate}}" (click)="notImplemented()"
                    style="position: absolute; top:6px; right:60px; height:30px; width:30px;"></button>
                <button pButton class="p-button-text p-button-rounded p-button-success" tooltipPosition="top"
                    icon="pi pi-envelope" pTooltip="{{'PACKAGELIST' | translate}}"
                    (click)="printShipment('packageList')"
                    style="position: absolute; top:6px; right:10px; height:30px; width:30px;"></button>
                <button pButton pRipple class="p-button-rounded p-button-text p-button-success" tooltipPosition="top"
                    icon="pi pi-print" pTooltip="Label" (click)="printShipment('label')"
                    style="position: absolute; top:6px; right:110px; height:30px; width:30px;"></button>
            </div>

            <div class="p-fluid p-formgrid grid">
                <div class="col-12 md:col-12" *ngIf="!shipments && !shipment">
                    <span>{{'NORESULTFROMSEARCHMESSAGE' | translate}}</span>
                </div>
            </div>

            <div *ngIf="shipments" style="padding-top:8px;">
                <p-table #dt1 [value]="shipments" selectionMode="multiple" [metaKeySelection]="true" dataKey="id"
                    [(selection)]="selectedShipments" styleClass="p-datatable-sm p-datatable-gridlines"
                    [paginator]="true" [rows]="20" [rowsPerPageOptions]="[10, 20, 50]" [loading]="searching"
                    [rowHover]="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="waybillNo">
                                <div class="flex justify-content-between align-items-center">
                                    {{'FREIGHTNO' | translate}}
                                    <p-sortIcon field="waybillNo"></p-sortIcon>
                                </div>
                            </th>
                            <th pSortableColumn="reference1">
                                <div class="flex justify-content-between align-items-center text-center">
                                    Ref1 <p-sortIcon field="reference1"></p-sortIcon>
                                </div>
                            </th>
                            <th pSortableColumn="senderName">
                                <div class="flex justify-content-between align-items-center">
                                    {{'SENDER' | translate}} <p-sortIcon field="senderName"></p-sortIcon>
                                </div>
                            </th>
                            <th pSortableColumn="recipientName">
                                <div class="flex justify-content-between align-items-center">
                                    {{'RECEIVER' | translate}} <p-sortIcon field="recipientName"></p-sortIcon>
                                </div>
                            </th>
                            <th pSortableColumn="status">
                                <div class="flex align-items-center text-center" style="justify-content: center;">
                                    {{'STATUS' | translate}} <p-sortIcon field="status"></p-sortIcon>
                                </div>
                            </th>
                            <th>
                                <div class="text-center">
                                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                </div>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-simpleShipment>
                        <tr (click)="onRowSelect({data: simpleShipment})" [ngStyle]="{'cursor': 'pointer'}"
                            [ngClass]="{'p-highlight': simpleShipment.id == chosenShipmentId}">
                            <td style="min-width: 6rem;" class="text-center">
                                {{simpleShipment.waybillNo}}
                            </td>
                            <td style="min-width: 6rem; max-width:8rem;word-break: break-all;">
                                {{simpleShipment.reference1}}
                            </td>
                            <td style="min-width: 8rem; max-width:8rem;word-break: break-all;">
                                {{simpleShipment.senderName}}
                            </td>
                            <td style="min-width: 8rem; max-width:8rem;word-break: break-all;">
                                {{simpleShipment.recipientName}}
                            </td>
                            <td style="min-width: 2rem;" class="text-center">
                                <div [ngSwitch]="simpleShipment.status">
                                    <i *ngSwitchCase="'READY'" style="width:100%; justify-content: center;"
                                        [pTooltip]="('READY' | translate)  + ' ' + tooltipText(simpleShipment)"
                                        tooltipPosition="right">
                                        <svg xmlns="http://www.w3.org/2000/svg" [attr.stroke]="'green'" height="1.4em"
                                            [style.stroke]="'green'" [attr.fill]="'green'" [style.fill]="'green'"
                                            viewBox="0 0 640 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                            <path
                                                d="M128 0C92.7 0 64 28.7 64 64V288H19.2C8.6 288 0 296.6 0 307.2C0 349.6 34.4 384 76.8 384H320V288H128V64H448V96h64V64c0-35.3-28.7-64-64-64H128zM512 128H400c-26.5 0-48 21.5-48 48V464c0 26.5 21.5 48 48 48H592c26.5 0 48-21.5 48-48V256H544c-17.7 0-32-14.3-32-32V128zm32 0v96h96l-96-96z" />
                                        </svg>
                                    </i>
                                    <i class="pi pi-download" *ngSwitchCase="'PICKED_UP'"
                                        style="color:var(--green-500);width:100%;"
                                        [pTooltip]="('PICKEDUP' | translate)  + ' ' + tooltipText(simpleShipment)"
                                        tooltipPosition="right">
                                    </i>
                                    <i style="color:var(--green-500); width:100%; justify-content: center;"
                                        *ngSwitchCase="'AT_TERMINAL'"
                                        [pTooltip]="('ATTERMINAL' | translate) + ' ' + tooltipText(simpleShipment)"
                                        tooltipPosition="right">
                                        <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                        <svg xmlns="http://www.w3.org/2000/svg" height="1.4em" viewBox="0 0 384 512"
                                            [attr.stroke]="'darkorange'" [style.stroke]="'darkorange'"
                                            [attr.fill]="'darkorange'" [style.fill]="'darkorange'">
                                            <path
                                                d="M48 0C21.5 0 0 21.5 0 48V464c0 26.5 21.5 48 48 48h96V432c0-26.5 21.5-48 48-48s48 21.5 48 48v80h96c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H48zM64 240c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V240zm112-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V240c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V240zM80 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V112zM272 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16z" />
                                        </svg>
                                    </i>
                                    <i *ngSwitchCase="'IN_TRANSIT'"
                                        style="color:var(--green-500); width:100%; justify-content: center;"
                                        [pTooltip]="('INTRANSIT' | translate)  + ' ' + tooltipText(simpleShipment)"
                                        tooltipPosition="right">
                                        <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                        <svg xmlns="http://www.w3.org/2000/svg" height="1.4em" viewBox="0 0 384 512"
                                            [attr.stroke]="'darkorange'" [style.stroke]="'darkorange'"
                                            [attr.fill]="'darkorange'" [style.fill]="'darkorange'">
                                            <path
                                                d="M48 0C21.5 0 0 21.5 0 48V464c0 26.5 21.5 48 48 48h96V432c0-26.5 21.5-48 48-48s48 21.5 48 48v80h96c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H48zM64 240c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V240zm112-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V240c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V240zM80 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V112zM272 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16z" />
                                        </svg>
                                    </i>
                                    <i *ngSwitchCase="'IN_PROGRESS'"
                                        style="color:var(--green-500);width:100%; justify-content: center;"
                                        [pTooltip]="('SHIPMENTSCANNED' | translate)  + ' ' + tooltipText(simpleShipment)"
                                        tooltipPosition="right">
                                        <svg xmlns="http://www.w3.org/2000/svg" [attr.stroke]="'green'" height="1.4em"
                                            [style.stroke]="'green'" [attr.fill]="'green'" [style.fill]="'green'"
                                            viewBox="0 0 640 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                            <path
                                                d="M48 0C21.5 0 0 21.5 0 48V368c0 26.5 21.5 48 48 48H64c0 53 43 96 96 96s96-43 96-96H384c0 53 43 96 96 96s96-43 96-96h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V288 256 237.3c0-17-6.7-33.3-18.7-45.3L512 114.7c-12-12-28.3-18.7-45.3-18.7H416V48c0-26.5-21.5-48-48-48H48zM416 160h50.7L544 237.3V256H416V160zM112 416a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm368-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
                                        </svg>
                                    </i>
                                    <i class="pi pi-check" *ngSwitchCase="'DELIVERED'"
                                        style="color:var(--green-500);width:100%;"
                                        [pTooltip]="('DELIVERED' | translate)  + ' ' + tooltipText(simpleShipment)"
                                        tooltipPosition="right">
                                    </i>
                                    <i class="pi pi-exclamation-triangle" *ngSwitchCase="'CANCELLED'"
                                        style="color:var(--pink-600);width:100%;"
                                        [pTooltip]="('CANCELLED' | translate) + ' ' + tooltipText(simpleShipment)"
                                        tooltipPosition="right">
                                    </i>
                                    <i class="pi pi-check-circle" *ngSwitchCase="'DONE'"
                                        style="color:var(--green-500);width:100%;"
                                        [pTooltip]="('FINISHED' | translate) + ' ' + tooltipText(simpleShipment)"
                                        tooltipPosition="right">
                                    </i>
                                    <i class="pi pi-trash" *ngSwitchCase="'DELETED'"
                                        style="color:var(--pink-600);width:100%;"
                                        [pTooltip]="('DELETED' | translate) + ' ' + tooltipText(simpleShipment)"
                                        tooltipPosition="right">
                                    </i>
                                </div>
                            </td>
                            <td>
                                <div class="text-center">
                                    <p-tableCheckbox [value]="simpleShipment"></p-tableCheckbox>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="8">
                                {{'SHIPMENTS.EMPTYSHIPMENTS' | translate}}
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="loadingbody">
                        <tr>
                            <td colspan="8">{{'SHIPMENTS.SEARCHING' | translate}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>

    <div class="col-6 md:col-6" style="max-height:600px;" *ngIf="!pdfUrl">
        <div class="card cardScroll" style="position: relative;">
            <div *ngIf="shipment">
                <span>{{'FREIGHT' | translate}} {{shipment.waybillNo}}</span>
                <span style="padding-left:20px;" *ngIf="shipment.approvedAt">{{'APPROVED' | translate}}
                    {{shipment.approvedAt | date : 'dd/MM/yyyy'}}</span>
            </div>
            <shipment-preview *ngIf="chosenShipmentId" [shipmentId]="chosenShipmentId"></shipment-preview>
            <div *ngIf="loading" style="text-align:center;">
                <p-progressSpinner styleClass="w-8rem h-8rem" strokeWidth="6"
                    animationDuration="1s"></p-progressSpinner>
                <br />
                <p>{{'FETCHLABELS' | translate}}</p>
            </div>
        </div>
    </div>
    <div class="col-6 md:col-6" *ngIf="pdfUrl">
        <p-card>
            <object [data]="pdfUrl" type="application/pdf" style="width:100%;height:80vh;">
                <a [href]="pdfUrl">Print.pdf</a>
            </object>
        </p-card>
    </div>

</div>
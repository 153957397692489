import { Component, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppConfig } from 'src/app/domain/appconfig';
import { Shipment } from 'src/app/domain/Shipment';
import { ConfigService } from 'src/app/services/app.config.service';

@Component({
  templateUrl: './booking-preview.component.html',
  selector: 'app-booking-preview',
  styleUrls: ['./booking-preview.component.scss'],
})
export class BookingPreviewComponent implements OnDestroy {
  @Input() shipment = <Shipment>{};
  @Input() senderCustomer: any | undefined;
  @Input() recipientCustomer: any | undefined;

  config: AppConfig;
  subscription: Subscription;
  bookingType: any[];

  constructor(public configService: ConfigService) {
    this.config = this.configService.config;
    this.subscription = this.configService.configUpdate$.subscribe((config) => {
      this.config = config;
    });
  }

  getToday() {
    return new Date();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  deleteColli(colli: any) {
    for (let i = 0; i < this.shipment.collis.length; i++) {
      if (this.shipment.collis[i] === colli) {
        this.shipment.collis.splice(i, 1);
      }
    }
  }
}

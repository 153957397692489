import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MailService {

    constructor(private httpClient: HttpClient) { }

    sendShipmentMail(shipmentId, recipient, subject, text) {
        return this.httpClient.post(`${environment.apiUrl}/mails/sendShipmentMail`,
            {
                ShipmentId: shipmentId,
                Recipient: recipient,
                Subject: subject,
                Text: text
            });
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Address, AutoCompleteAddressResponse } from '../domain/Address';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  constructor(private httpClient: HttpClient) {}

  autoCompleteAddress(searchInput: string) {
    return this.httpClient.post<AutoCompleteAddressResponse>(`${environment.apiUrl}/e/address/autocomplete`, { searchInput });
  }

  updateShipmentAddress(address: any, shipmentId: any) {
    if (address.addressId) {
      address.id = address.addressId;
    }
    var updateAddressCommand = {
      address: address,
      shipmentId: shipmentId,
    };
    return this.httpClient.post<IUpdateAddressResponse>(`${environment.apiUrl}/shipments/address/update`, updateAddressCommand);
  }

  getAddress(addressId: string) {
    return this.httpClient.get<Address>(`${environment.apiUrl}/e/address?id=${addressId}`);
  }
}

export interface IUpdateAddressResponse {
  id: string;
}

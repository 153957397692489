import { Injectable } from '@angular/core';
import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

@Injectable({
  providedIn: 'root',
})
export class SignalrService {
  retryTimes = [0, 1000, 5000, 30000];

  constructor() { }

  public CreateConnection(endpoint: string): HubConnection {
    return new HubConnectionBuilder()
      .withUrl(`/signalr/${endpoint}`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: (context) => {
          const index = context.previousRetryCount < this.retryTimes.length ? context.previousRetryCount : this.retryTimes.length - 1;
          return this.retryTimes[index];
        }
      })
      .build();
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ServicesService {

    constructor(private httpClient: HttpClient) { }

    getExternalServicesByCarrier(carrier: string, bookingType: string) {
        var request = {
            carrier: carrier,
            bookingType: bookingType
        };
        return this.httpClient.post(`${environment.apiUrl}/services/getExternalServicesByCarrier`, request);
    }
}

<p-toast></p-toast>

<div class="p-fluid p-formgrid grid">
  <div class="col-4 md:col-4">
    <p-card styleClass="card">
      <ng-template pTemplate="header">
        <p-toolbar>
          <div class="p-toolbar-group-left p-card-title">
            {{'CUSTOMERLIST' | translate}}
          </div>
          <div class="p-toolbar-group-right">
            <p-button icon="pi pi-plus-circle" styleClass="p-button-primary" class="mr-2" (click)="openDialog('import')"
              label="{{'IMPORTCUSTOMER' | translate}}"></p-button>
            <p-button icon="pi pi-plus-circle" styleClass="p-button-primary" (click)="openDialog('customer')"
              label="{{'NEWCUSTOMER' | translate}}"></p-button>
          </div>
        </p-toolbar>
      </ng-template>
      <p-table #dt1 [value]="customers" dataKey="id" [rows]="10" [rowHover]="true"
        [globalFilterFields]="['name','customerNo']" styleClass="p-datatable-gridlines" scrollHeight="500px"
        selectionMode="single" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
        [(selection)]="customer" responsiveLayout="scroll" [loading]="loading">
        <ng-template pTemplate="caption">
          <div class="flex justify-content-between flex-column sm:flex-row">
            <span class="p-input-icon-left mb-2">
              <i class="pi pi-search"></i>
              <input pInputText type="text" #filter (input)="dt1.filterGlobal($event.target.value, 'contains')"
                placeholder="{{'GENERIC.SEARCH' | translate}}" class="w-full" />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="customerNo">
              <div class="flex justify-content-between align-items-center">
                {{'CUSTOMERNO' | translate}} <p-sortIcon field="customerNo"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="name">
              <div class="flex justify-content-between align-items-center">
                {{'NAME' | translate}} <p-sortIcon field="name"></p-sortIcon>
              </div>
            </th>
            <th class="flex justify-content-center align-items-center text-center">
              {{'GENERIC.DELETE' | translate}}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-customer>
          <tr [pSelectableRow]="customer">
            <td style="min-width: 12rem;">
              {{customer.customerNo}}
            </td>
            <td style="min-width: 12rem;">
              {{customer.name}}
            </td>
            <td class="text-center">
              <i class="pi pi-trash" style="color:#fc6161;" (click)="deleteCustomer($event,customer)"></i>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="2">{{'NOCUSTOMERSFOUNDMESSAGE' | translate}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="loadingbody">
          <tr>
            <td colspan="2">{{'FETCHINGCUSTOMERSMESSAGE' | translate}}</td>
          </tr>
        </ng-template>
      </p-table>
    </p-card>
  </div>


  <div class="col-8 md:col-8" style="position: relative;">
    <p-card styleClass="card" header="{{'CHOSENCUSTOMER' | translate}}">

      <button *ngIf="customer" style="position: absolute; top:20px; right:20px;" pButton pRipple icon="pi pi-save"
        tooltipPosition="left" (click)="updateCustomer()" pTooltip="{{'SAVECUSTOMER' | translate}}"
        class="p-button-rounded p-button-success">
      </button>

      <div class="p-fluid p-formgrid grid" *ngIf="customer">
        <div class="field col-12 md:col-12 gap-5">
          <span class="p-float-label">
            <input pInputText id="customerNo" [(ngModel)]="tmpCustomerNo" type="text" />
            <label for="customerNo">{{'CUSTOMERNO' | translate}}</label>
          </span>
        </div>

        <div class="field col-12 md:col-12">
          <span class="p-float-label">
            <input pInputText id="name" [(ngModel)]="tmpCustomerName" type="text" />
            <label for="name">{{'NAME' | translate}}</label>
          </span>
        </div>

        <div class="field col-12 md:col-12">
          <span class="p-float-label">
            <input pInputText id="name" [(ngModel)]="tmpCustomerNote" type="text" />
            <label for="name">{{'NOTE' | translate}}</label>
          </span>
        </div>

        <p-panel header="{{ 'CUSTOMER.DETAILS.ADDRESSES' | translate }}" [toggleable]="true"
          class="field col-12 md:col-12">
          <p-toolbar styleClass="mb-4 gap-2">
            <ng-template pTemplate="left">
              <button pButton pRipple label="{{ 'GENERIC.ADD' | translate }}" icon="pi pi-plus"
                class="p-button-success mr-2" (click)="openDialog('address')"></button>
              <button pButton pRipple label="{{ 'GENERIC.DELETE' | translate }}" icon="pi pi-trash"
                class="p-button-danger" (click)="deleteSelectedAddresses()"
                [disabled]="!selectedAddresses || !selectedAddresses.length"></button>
            </ng-template>
          </p-toolbar>
          <p-table #addressTable [value]="customer.addresses" dataKey="id" [rowHover]="true" selectionMode="multiple"
            [(selection)]="selectedAddresses" [loading]="addressesLoading">
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 4rem">
                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th>
                  <div class="flex justify-content-between align-items-center">
                    {{ 'CUSTOMER.ADDRESS.STREET' | translate }}
                  </div>
                </th>
                <th>
                  <div class="flex justify-content-between align-items-center">
                    {{ 'CUSTOMER.ADDRESS.ZIPCODE' | translate }}
                  </div>
                </th>
                <th>
                  <div class="flex justify-content-between align-items-center">
                    {{ 'CUSTOMER.ADDRESS.CITY' | translate }}
                  </div>
                </th>
                <th>
                  <div class="flex justify-content-between align-items-center">
                    {{ 'CUSTOMER.ADDRESS.COUNTRY' | translate }}
                  </div>
                </th>
                <th>{{'GENERIC.DELETE' | translate}}</th>
                <th>{{'GENERIC.EDIT' | translate}}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-address>
              <tr>
                <td>
                  <p-tableCheckbox [value]="address"></p-tableCheckbox>
                </td>
                <td>
                  {{ address.street }}
                </td>
                <td>
                  {{ address.zipCode }}
                </td>
                <td>
                  {{ address.city }}
                </td>
                <td>
                  {{ 'COUNTRIES.'+address.countryCode | translate }}
                </td>
                <td>
                  <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger"
                    (click)="deleteAddress(address)"></button>
                </td>
                <td>
                  <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-info"
                    (click)="openDialog('address', address)"></button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-panel>


        <p-panel header="{{ 'CUSTOMER.DETAILS.CONTACTPERSONS' | translate }}" [toggleable]="true"
          class="field col-12 md:col-12">
          <p-toolbar styleClass="mb-4 gap-2">
            <ng-template pTemplate="left">
              <button pButton pRipple label="{{ 'GENERIC.ADD' | translate }}" icon="pi pi-plus"
                class="p-button-success mr-2" (click)="openDialog('contactPerson')"></button>
              <button pButton pRipple label="{{ 'GENERIC.DELETE' | translate }}" icon="pi pi-trash"
                class="p-button-danger" (click)="deleteSelectedContacts()"
                [disabled]="!selectedContactPersons || !selectedContactPersons.length"></button>
            </ng-template>
          </p-toolbar>
          <p-table #contactsTable [value]="customer.contactPersons" dataKey="id" [rowHover]="true"
            selectionMode="multiple" [(selection)]="selectedContactPersons">
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 4rem">
                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th>
                  <div class="flex justify-content-between align-items-center">
                    {{ 'CUSTOMER.CONTACT.NAME' | translate }}
                  </div>
                </th>
                <th>
                  <div class="flex justify-content-between align-items-center">
                    {{ 'CUSTOMER.CONTACT.EMAIL' | translate }}
                  </div>
                </th>
                <th>
                  <div class="flex justify-content-between align-items-center">
                    {{ 'CUSTOMER.CONTACT.PHONE' | translate }}
                  </div>
                </th>
                <th>
                  <div class="flex justify-content-between align-items-center">
                    {{ 'CUSTOMER.CONTACT.SENDLABEL' | translate }}
                  </div>
                </th>
                <th>{{'GENERIC.DELETE' | translate}}</th>
                <th>{{'GENERIC.EDIT' | translate}}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-contact>
              <tr>
                <td>
                  <p-tableCheckbox [value]="contact"></p-tableCheckbox>
                </td>
                <td>
                  {{ contact.name }}
                </td>
                <td>
                  {{ contact.email }}
                </td>
                <td>
                  {{ contact.phone }}
                </td>
                <td>
                  {{contact.sendLabel ? ('GENERIC.YES' | translate) : ('GENERIC.NO' | translate)}}
                </td>
                <td>
                  <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger"
                    (click)="deleteContact(contact)"></button>
                </td>
                <td>
                  <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-info"
                    (click)="openDialog('contactPerson', contact)"></button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-panel>
      </div>
    </p-card>
  </div>
</div>
<p-confirmDialog></p-confirmDialog>
<div class="spinner-overlay" *ngIf="fullPageLoading">
  <p-progressSpinner styleClass="w-8rem h-8rem" strokeWidth="6" animationDuration="1s"></p-progressSpinner>
  <br />
  <p>{{'FETCHINGCUSTOMERS' | translate }}</p>
</div>

<p-confirmDialog key="deleteCustomer"></p-confirmDialog>
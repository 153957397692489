import { Injectable } from "@angular/core";
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
} from "@angular/common/http";

import { Observable } from "rxjs";

/** Pass untouched request through to the next request handler. */
@Injectable()
export class CsrfInterceptor implements HttpInterceptor {
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (req.url.startsWith("/api") || req.url.startsWith("/bff")) {
            const modifiedReq = req.clone({
                headers: req.headers.set("X-CSRF", "1"),
            });
            return next.handle(modifiedReq);
        } else {
            return next.handle(req);
        }
    }
}

import { formatDate } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Apollo, ApolloBase, gql } from 'apollo-angular';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShipmentGraphService {
  constructor(private apolloProvider: Apollo, @Inject(LOCALE_ID) private locale: string) {
    this.apollo = this.apolloProvider.use('shipmentService');
  }

  private apollo: ApolloBase;

  graphSearch(
    barcode: string | null | undefined,
    waybillNo: string | null | undefined,
    trackAndTrace: string | null | undefined,
    reference1: string | null | undefined,
    reference2: string | null | undefined,
    reference3: string | null | undefined,
    services: string | null | undefined,
    senderName: string | null | undefined,
    recipientName: string | null | undefined,
    senderZip: string | null | undefined,
    recipientZip: string | null | undefined,
    note: string | null | undefined,
    bookingFromDate: Date | null | undefined,
    bookingToDate: Date | null | undefined,
    deliveryFromDate: Date | null | undefined,
    deliveryToDate: Date | null | undefined
  ) {
    const searchCriteria = {
      barcode,
      waybillNo,
      trackAndTrace,
      reference1,
      reference2,
      reference3,
      services,
      senderName,
      recipientName,
      senderZip,
      recipientZip,
      note,
    };

    const dateFilters = [];
    if (bookingFromDate) {
      dateFilters.push(`{bookingDate: {gte: "${formatDate(bookingFromDate, 'yyyy-MM-dd 00:00:00', this.locale)}"}}`);
    }
    if (bookingToDate) {
      dateFilters.push(`{bookingDate: {lte: "${formatDate(bookingToDate, 'yyyy-MM-dd 23:59:59', this.locale)}"}}`);
    }
    if (deliveryFromDate) {
      dateFilters.push(`{deliveryDate: {gte: "${formatDate(deliveryFromDate, 'yyyy-MM-dd 00:00:00', this.locale)}"}}`);
    }
    if (deliveryToDate) {
      dateFilters.push(`{deliveryDate: {lte: "${formatDate(deliveryToDate, 'yyyy-MM-dd 23:59:59', this.locale)}"}}`);
    }

    const searchFields = Object.entries(searchCriteria)
      .filter(([key, value]) => value !== null && value !== undefined && value !== '')
      .map(([key, value]) => {
        if (key === 'barcode') {
          return `
          {
            collis: {
              some: {
                and: [
                  { barcodes: { some: { eq: "${value}" } } }
                ]
              }
            }
          }
          `;
        }
        if (key === 'services') {
          return `
          { 
            ${key}: {
              some: {
                eq: "${value}"
              }
            }
          }
          `;
        }
        return `{${key}: {contains: "${value}"}}`;
      });

    const filters = [...searchFields, ...dateFilters];

    if (filters.length === 0) {
      throw new Error('No search criteria provided');
    }

    const searchQuery = `{
          archive(
            where: {
              and: [
                ${filters.join(',')}
              ]
            },
            order: [{bookingDate: DESC}]
          ){
            id
            waybillNo
            reference1
            bookingSource
            bookingDate
            recipientName
            senderName
            status
            eventDate
          }
        }`;

    return this.apollo
      .query({
        query: gql`
          ${searchQuery}
        `,
      })
      .pipe(map((result: any) => result.data.archive));
  }
}

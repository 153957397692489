<div class="p-fluid p-formgrid grid">
  <div class="col-6 md:col-6">
    <div class="card" style="position: relative;">
      <p-fileUpload #fileUpload name="edi[]" [multiple]="true" [customUpload]="true"
        (uploadHandler)="uploadFiles($event, fileUpload)" chooseLabel="{{'CHOOSEEDIFILES' | translate}}" advanced="true"
        (onSelect)="onEdiFileSelect($event)" (onClear)="clearFiles($event)" (onRemove)="fileRemoved($event)"
        uploadLabel="{{'UPLOADFILES' | translate}}" cancelLabel="{{'CLEARFILES' | translate}}">
      </p-fileUpload>
      <p style="position: absolute;top:30px; right:30px;" *ngIf="edi">{{'AMOUNTOFFILES' | translate}}:
        {{edi.length}}</p>
    </div>
    <p-button (click)="getSampleFile()"><i class="pi pi-download" style="margin-right: 1rem"></i> {{'EDI.SAMPLEFILE' |
      translate }} </p-button>
  </div>

  <div class="col-6 md:col-6">
    <div class="card">
      <h4 style="text-align:center; font-weight:500;">
        {{'UPLOADEDFILES' | translate}}</h4>
      <p-divider></p-divider>
      <div>
        <div *ngFor="let file of uploadedFiles">
          <p>{{file.name}}: {{ 'EDI.STATUS.' + file.status | uppercase | translate}}</p>
          <div>
            <p>{{'EDI.SHIPMENTS' | translate}}:</p>
            <p *ngFor="let shipment of file.shipments; index as i">
              {{i+1}}: {{ 'EDI.SHIPMENTSTATUS.' + shipment.status | uppercase | translate}}
            </p>
          </div>
          <p-divider></p-divider>
        </div>
      </div>
    </div>
  </div>
</div>
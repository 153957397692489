import { Component, OnInit, OnDestroy, Input, OnChanges, isDevMode } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/services/app.config.service';
import { AppConfig } from 'src/app/domain/appconfig';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AddressService } from 'src/app/services/address.service';
import { ShipmentService } from 'src/app/services/shipment.service';
import { ColliService } from 'src/app/services/colli.service';
import { ClientService } from 'src/app/services/client.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SignalrService } from 'src/app/services/signalr.service';
import { HubConnection } from '@microsoft/signalr';
import { TranslateService } from '@ngx-translate/core';
import { DateValidator } from '../validators/date.validators';
import { ADRService } from 'src/app/services/adr.service';
import { ScanService } from 'src/app/services/scan.service';
import { Scan } from 'src/app/domain/Scan';
import * as Leaflet from 'leaflet';
import { getLayers } from '../../dashboard/dashboard.component';
import { formatDate } from '@angular/common';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MailMenuDialogComponent } from '../dialogs/mail-menu-dialog/mail-menu-dialog.component';
import { MailService } from 'src/app/services/mail.service';
import { Router } from '@angular/router';
import { ServicesService } from 'src/app/services/services.service';
import { LabelService } from 'src/app/services/label.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PrintLabelShipmentDialogComponent } from '../dialogs/print-label-shipment-dialog/print-label-shipment-dialog.component';
import { AuthService } from 'src/app/services/auth.service';
import { OBPermissions } from '@alicetms/auth.permissions';

@Component({
  templateUrl: './shipment-preview.component.html',
  selector: 'shipment-preview',
  styleUrls: ['./shipment-preview.component.scss'],
})
export class ShipmentPreviewComponent implements OnInit, OnDestroy, OnChanges {
  @Input() editable: boolean = false;
  @Input() shipmentId?: any;

  config: AppConfig;
  shipment: any;
  hubConnection: HubConnection;
  subscription: Subscription;
  services: any;
  shipmentUpdatedNotification: boolean = false;
  senderAddressEdit: boolean = false;
  recipientAddressEdit: boolean = false;
  refEdit: boolean = false;
  exchangePalletsEdit: boolean = false;
  servicesEdit: boolean = false;
  notesEdit: boolean = false;
  datesEdit: boolean = false;
  colliEdit: boolean = false;
  editMode: boolean = false;
  editObject: any;
  colliToEdit: any;
  dangerousGoodsEdit: any;
  foundAdrs: any;
  editColliWeight: boolean = false;

  loadingScans: any;
  currentRoute: any;
  serviceSingleSelectEdit: any;

  loading: boolean = false;
  chosenImage: any;
  scanMapOptions: Leaflet.MapOptions = {
    layers: getLayers(),
    zoom: 12,
    center: Leaflet.latLng(55.676098, 12.568337),
  };
  map: Leaflet.Map;
  mapVisible: boolean = false;
  lastMarker: any;

  ref: DynamicDialogRef;
  tenantLogo: any;
  hasPrivateDeliveryService: boolean = false;

  hasPrintLabelPermission: OBPermissions.PrintLabels;

  serviceForm = new FormGroup({
    services: new FormControl(),
  });
  today: any = formatDate(new Date(), 'yyyy-MM-dd', 'da-DK');

  icon = {
    icon: Leaflet.icon({
      iconSize: [25, 41],
      iconAnchor: [13, 0],
      iconUrl: '/assets/layout/images/marker-icon.png',
      shadowUrl: '/assets/layout/images/marker-shadow.png',
    }),
  };

  editDatesForm = new FormGroup(
    {
      pickupDate: new FormControl('', [Validators.required, Validators.pattern('^\\d{2}/\\d{2}/\\d{4}$'), DateValidator.GreaterThanToday]),
      pickupTimeStart: new FormControl(),
      pickupTimeEnd: new FormControl(),
      deliveryDate: new FormControl('', [Validators.required, Validators.pattern('^\\d{2}/\\d{2}/\\d{4}$'), DateValidator.GreaterThanToday]),
      deliveryTimeStart: new FormControl(),
      deliveryTimeEnd: new FormControl(),
    },
    {
      validators: [
        DateValidator.DeliveryTimeStartParseValidator,
        DateValidator.DeliveryTimeEndParseValidator,
        DateValidator.PickupTimeStartParseValidator,
        DateValidator.PickupTimeEndParseValidator,
        DateValidator.DeliveryTimeValidator,
        DateValidator.PickupTimeValidator,
      ],
    }
  );

  cols: any[] = [
    { field: 'quantity', header: 'COLLIHEADERS.AMOUNT' },
    { field: 'type', header: 'COLLIHEADERS.TYPE' },
    { field: 'barcodes', header: 'COLLIHEADERS.BARCODES' },
    { field: 'description', header: 'COLLIHEADERS.DESCRIPTION' },
    { field: 'weight', header: 'COLLIHEADERS.WEIGHT' },
    { field: 'volume', header: 'COLLIHEADERS.VOLUME' },
    { field: 'length', header: 'COLLIHEADERS.LENGTH' },
    { field: 'width', header: 'COLLIHEADERS.WIDTH' },
    { field: 'height', header: 'COLLIHEADERS.HEIGHT' },
    { field: 'loadMeter', header: 'COLLIHEADERS.LOADMETER' },
  ];

  constructor(
    public configService: ConfigService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private addressService: AddressService,
    private shipmentService: ShipmentService,
    private colliService: ColliService,
    public clientService: ClientService,
    private signalr: SignalrService,
    private translateService: TranslateService,
    private adrService: ADRService,
    private scanService: ScanService,
    private dialogService: DialogService,
    private mailService: MailService,
    private servicesService: ServicesService,
    private router: Router,
    private labelService: LabelService,
    private sanitizer: DomSanitizer,
    public authService: AuthService
  ) {
    this.hubConnection = signalr.CreateConnection('shipment');
    this.config = this.configService.config;
    this.subscription = this.configService.configUpdate$.subscribe((config) => {
      this.config = config;
    });
    this.tenantLogo = this.clientService.clientTenantLogo;
  }

  ngOnInit(): void {
    this.hubConnection.start().then(() => {
      if (this.shipmentId) {
        this.hubConnection.invoke('JoinGroup', `shipment:${this.shipmentId}`);

        this.hubConnection.on('shipment-updated', () => {
          this.shipmentUpdatedNotification = true;
        });
      }
    });
    this.currentRoute = this.router.url;
  }

  isDev() {
    return isDevMode();
  }

  public waybillShortString(dangerousGood: any) {
    return dangerousGood.waybillString?.substring(0, 80);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnChanges() {
    // New shipment is chosen
    if (this.shipmentId) {
      this.refreshShipment();
    } else {
      this.shipment = null;
    }
  }

  printLabel() {
    if (this.shipment.id) {
      this.ref = this.dialogService.open(PrintLabelShipmentDialogComponent, {
        width: '70vw',
        data: { id: this.shipment.id, type: 'label' },
        header: 'Label ' + this.shipment.waybillNo,
      });

      this.ref.onClose.subscribe((result: any) => {
        if (result) {
          // Dialog is closed
        }
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Error Occured, Try again',
      });
    }
  }

  shipmentWithinDeadline(shipment: any) {
    if (this.clientService.clientReadyDeadline) {
      const [hours, minutes, seconds] = this.clientService.clientReadyDeadline.split(':').map(Number);
      var pickupDate = new Date(shipment.pickupDate).setHours(hours, minutes, seconds);
      var pickupDatewithReadyDeadline = new Date(pickupDate);
      if (pickupDatewithReadyDeadline < new Date()) {
        return false;
      }
    }
    return true;
  }

  openMailMenu(shipment: any) {
    this.ref = this.dialogService.open(MailMenuDialogComponent, {
      width: '70vw',
      data: shipment,
      header: 'Mail menu',
    });

    this.ref.onClose.subscribe((result: any) => {
      if (result) {
        this.mailService.sendShipmentMail(shipment.id, result.recipient, result.subject, result.text).subscribe({
          next: (res: any) => {
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('MESSAGES.MAILSENDTO') + res.recipient,
              detail: this.translateService.instant('ABOUTPARCEL') + shipment.waybillNo,
            });
          },
          error: (err) => {
            this.messageService.add({
              severity: 'error',
              summary: this.translateService.instant('ERROR'),
              detail: this.translateService.instant('MESSAGES.UNABLETOSENDMAIL'),
            });
          },
        });
      }
    });
  }

  refreshShipment() {
    this.loading = true;
    this.shipment = null;
    this.scanMapOptions = null;
    this.editMode = false;
    this.mapVisible = false;
    this.chosenImage = null;
    this.map = null;
    this.shipmentUpdatedNotification = false;
    this.closeEdit();
    this.shipmentService.getFullShipment(this.shipmentId).subscribe({
      next: (shipment) => {
        this.shipment = shipment;
        if (!this.shipment.fullExchangePallets) {
          this.shipment.fullExchangePallets = {};
        }
        if (!this.shipment.halfExchangePallets) {
          this.shipment.halfExchangePallets = {};
        }
        if (!this.shipment.quarterExchangePallets) {
          this.shipment.quarterExchangePallets = {};
        }
        this.sanitizeTimeOnlyFields(this.shipment);
        this.loading = false;
        this.getScans();
      },
      error: (error) => {
        this.loading = false;
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('ERROR'),
          detail: this.translateService.instant('MESSAGES.UNABLETOGETSHIPMENTS'),
        });
      },
    });
  }

  private sanitizeTimeOnlyFields(shipment: any) {
    const timeFields = ['pickupTimeStart', 'pickupTimeEnd', 'deliveryTimeStart', 'deliveryTimeEnd'];
    timeFields.forEach((field) => {
      if (shipment[field] && shipment[field].includes('.')) {
        shipment[field] = shipment[field].replace('.', ':');
      }
    });
  }

  openImage(file: any) {
    if (file) {
      this.mapVisible = false;
      this.chosenImage = file;
    }
  }

  showMap(scan: Scan) {
    this.chosenImage = null;
    this.mapVisible = false;
    if (scan.latitude && scan.longitude) {
      this.mapVisible = true;
      if (!this.map) {
        this.map = Leaflet.map('map').setView([scan.latitude, scan.longitude], 12);
      } else {
        this.map.setView([scan.latitude, scan.longitude], 12);
      }

      Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }).addTo(this.map);

      if (this.lastMarker) {
        this.map.removeLayer(this.lastMarker);
      }

      this.lastMarker = Leaflet.marker([scan.latitude, scan.longitude], this.icon).addTo(this.map);

      setTimeout(() => {
        this.map.invalidateSize(true);
      }, 0);
    } else {
      this.messageService.add({
        severity: 'info',
        summary: this.translateService.instant('MESSAGES.NOLOCATION'),
        detail: this.translateService.instant('MESSAGES.NOLOCATIONFORSCAN'),
      });
    }
  }
  getScans() {
    if (this.shipment?.trackAndTrace) {
      this.loadingScans = true;
      const trackAndTraceToSearch = this.shipment.trackAndTrace;
      this.scanService.getScansByTrackAndTrace(trackAndTraceToSearch).subscribe({
        next: (scans: Scan[]) => {
          if (this.shipment) {
            if (scans && scans.length > 0) {
              if (this.shipment.trackAndTrace == trackAndTraceToSearch) {
                this.shipment.scans = [];
                this.shipment.scans = scans;
              }
            }
          }
          this.loadingScans = false;
        },
        error: (error) => {
          this.loadingScans = false;
        },
      });
    }
  }

  totalLoadmeter() {
    return this.shipment.collis?.map((x) => x.loadMeter).reduce((a, b) => a + b, 0);
  }

  totalWeight() {
    return this.shipment.collis
      ?.map((x) => x.weight)
      .reduce((a, b) => a + b, 0)
      .toFixed(2);
  }

  totalAmount() {
    return this.shipment.collis?.map((x) => x.quantity).reduce((a, b) => a + b, 0);
  }

  editColli(colli: any) {
    if (!this.shipment.carrier || this.shipment.carrier == 'Internal') {
      this.editMode = true;
      this.colliEdit = true;
      if (colli) {
        colli.shipmentId = this.shipment.id;
        this.colliToEdit = Object.assign({}, colli);
      } else {
        this.colliToEdit = {};
      }
    } else {
      this.editColliWeight = true;
      colli.shipmentId = this.shipment.id;
      this.colliToEdit = Object.assign({}, colli);
    }
  }

  saveColli() {
    this.editMode = false;
    this.colliEdit = false;
    this.editColliWeight = false;
    this.loading = true;
    if (this.colliToEdit.lineId) {
      this.colliToEdit.barcodes = [];
      this.colliService.updateColliLine(this.colliToEdit).subscribe((colli) => {
        if (colli) {
          this.shipmentService.getFullShipment(this.shipment.id).subscribe((shipment) => {
            this.shipment = shipment;
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('UPDATED'),
              detail: this.translateService.instant('MESSAGES.COLLIUPDATED'),
            });
            this.loading = false;
          });
        }
      });
    } else {
      this.colliToEdit.shipmentId = this.shipment.id;
      this.colliService.createColli(this.colliToEdit).subscribe((colli) => {
        this.shipmentService.getFullShipment(this.shipment.id).subscribe((shipment) => {
          this.shipment = shipment;
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('CREATED'),
            detail: this.translateService.instant('MESSAGES.COLLICREATED'),
          });
          this.loading = false;
        });
      });
    }
  }

  checkColliAgreement() {
    const { colliToEdit } = this;
    const { type } = colliToEdit;
    const chosenType = this.clientService.clientAgreements.find((x) => x.colliName === type);

    if (!type) {
      return true;
    }

    if (!chosenType) {
      return false;
    }

    const { length, width, height, volume, loadMeter, weight, description } = colliToEdit;

    if (chosenType.length && !length) {
      return true;
    }

    if (chosenType.width && !width) {
      return true;
    }

    if (chosenType.height && !height) {
      return true;
    }

    if (chosenType.volume && !volume) {
      return true;
    }

    if (chosenType.ldm && !loadMeter) {
      return true;
    }

    if (chosenType.weight && !weight) {
      return true;
    }

    if (chosenType.text && !description) {
      return true;
    }
    return false;
  }

  deleteColli(colli: any) {
    this.confirmationService.confirm({
      message: this.translateService.instant('COLLIDELETEMESSAGE'),
      header: this.translateService.instant('DELETECOLLI'),
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: this.translateService.instant('GENERIC.YES'),
      rejectLabel: this.translateService.instant('GENERIC.CLOSE'),
      key: 'deleteColliConfirm',
      accept: () => {
        this.loading = true;
        if (colli.lineId) {
          if (colli.quantity > 1) {
            this.colliService.deleteColliLine(colli).subscribe({
              next: (res) => {
                this.shipment.collis = this.shipment.collis.filter((x) => x.lineId !== colli.lineId);
                this.messageService.add({
                  severity: 'success',
                  summary: this.translateService.instant('DELETED'),
                  detail: this.translateService.instant('MESSAGES.COLLIDELETED'),
                });
                this.loading = false;
              },
              error: (err) => {},
            });
          } else {
            this.colliService.deleteColli(colli).subscribe((res) => {
              this.shipmentService.getFullShipment(this.shipment.id).subscribe((shipment) => {
                this.shipment = shipment;
                this.messageService.add({
                  severity: 'success',
                  summary: this.translateService.instant('DELETED'),
                  detail: this.translateService.instant('MESSAGES.COLLIDELETED'),
                });
              });
              this.loading = false;
            });
          }
        }
      },
      reject: () => {},
    });
  }

  checkCollis() {
    if (this.shipment.collis && this.shipment.collis.length > 0) {
      if (this.shipment.collis.some((x) => x.type == 'GLS') || this.shipment.collis.some((x) => x.type == 'PC')) {
        return false;
      }
    }
    if (this.shipment.carrier && this.shipment.carrier == 'GLS') {
      return false;
    }
    if (this.shipment.carrier && this.shipment.carrier == 'PostNord') {
      return false;
    }
    if (this.shipment.carrier && this.shipment.carrier != 'Internal') {
      return false;
    }

    return true;
  }

  closeEdit() {
    this.editMode = false;
    this.senderAddressEdit = false;
    this.recipientAddressEdit = false;
    this.refEdit = false;
    this.exchangePalletsEdit = false;
    this.servicesEdit = false;
    this.notesEdit = false;
    this.datesEdit = false;
    this.colliEdit = false;
    this.dangerousGoodsEdit = false;
    this.editColliWeight = false;
    this.serviceSingleSelectEdit = false;
    this.services = null;
  }

  editSenderAddress() {
    this.editMode = true;
    this.senderAddressEdit = true;
    this.editObject = Object.assign({}, this.shipment.senderAddress);
  }

  saveSenderAddress() {
    this.closeEdit();
    this.loading = true;
    this.addressService.updateShipmentAddress(this.editObject, this.shipment.id).subscribe((address) => {
      this.shipment.senderAddress = this.editObject;
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('UPDATED'),
        detail: this.translateService.instant('MESSAGES.SENDERADDRESSUPDATED'),
      });
      this.loading = false;
    });
  }

  editRecipientAddress() {
    this.editMode = true;
    this.recipientAddressEdit = true;
    this.editObject = Object.assign({}, this.shipment.recipientAddress);
  }

  saveRecipientAddress() {
    this.closeEdit();
    this.loading = true;
    this.addressService.updateShipmentAddress(this.editObject, this.shipment.id).subscribe((address) => {
      this.shipment.recipientAddress = this.editObject;
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('UPDATED'),
        detail: this.translateService.instant('MESSAGES.RECEIVERADDRESSUPDATED'),
      });
      this.loading = false;
    });
  }

  editDates() {
    this.editMode = true;
    this.datesEdit = true;
    this.editObject = Object.assign({}, this.shipment);
    if (this.editObject.pickupDate) {
      this.editObject.pickupDate = this.formatDate(this.editObject.pickupDate);
    }
    this.editObject.deliveryDate = this.formatDate(this.editObject.deliveryDate);

    if (this.editObject.carrier != 'Internal') {
      this.editDatesForm.get('pickupDate').clearValidators();
      this.editDatesForm.get('pickupDate').updateValueAndValidity();
    } else {
      if (this.clientService.clientReadyDeadline) {
        const [hours, minutes, seconds] = this.clientService.clientReadyDeadline.split(':').map(Number);
        var pickupDate = new Date(this.editObject.pickupDate).setHours(hours, minutes, seconds);
        var pickupDatewithReadyDeadline = new Date(pickupDate);
        if (pickupDatewithReadyDeadline < new Date()) {
          this.editDatesForm.get('pickupDate').setValidators([Validators.required, Validators.pattern('^\\d{2}/\\d{2}/\\d{4}$'), DateValidator.GreaterThanToday]);
          this.editDatesForm.addValidators([DateValidator.PickupDateGreaterThanToday]);
        } else {
          // User is able to set pickupdate to today
          this.editDatesForm.get('pickupDate').setValidators([Validators.required, Validators.pattern('^\\d{2}/\\d{2}/\\d{4}$'), DateValidator.GreaterThanOrEqualToday]);
          this.editDatesForm.addValidators([DateValidator.PickupDateGreaterThanOrEqualToday]);
        }
      } else {
        this.editDatesForm.get('pickupDate').setValidators([Validators.required, Validators.pattern('^\\d{2}/\\d{2}/\\d{4}$'), DateValidator.GreaterThanToday]);
        this.editDatesForm.addValidators([DateValidator.PickupDateGreaterThanToday]);
      }
    }
    this.editDatesForm.patchValue(this.editObject);
    this.editDatesForm.valueChanges.subscribe((val) => {
      this.editObject.pickupDate = val.pickupDate;
      this.editObject.deliveryDate = val.deliveryDate;
      this.editObject.pickupTimeStart = val.pickupTimeStart;
      this.editObject.pickupTimeEnd = val.pickupTimeEnd;
      this.editObject.deliveryTimeStart = val.deliveryTimeStart;
      this.editObject.deliveryTimeEnd = val.deliveryTimeEnd;
    });
  }

  saveDates() {
    if (!this.editDatesForm.invalid) {
      this.closeEdit();
      if (this.editObject.pickupDate) {
        this.editObject.pickupDate = this.parseDate(this.editObject.pickupDate).toDateString();
      }
      this.editObject.deliveryDate = this.parseDate(this.editObject.deliveryDate).toDateString();
      this.updateShipmentDates(this.editObject);
    } else {
    }
  }

  updateShipmentDates(shipment: any) {
    this.loading = true;
    this.shipmentService.updateShipmentDates(shipment).subscribe({
      next: (shipment) => {
        this.shipmentService.getFullShipment(this.shipment.id).subscribe((shipment) => {
          this.shipment = shipment;
          this.sanitizeTimeOnlyFields(this.shipment);
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('UPDATED'),
            detail: this.translateService.instant('MESSAGES.DATESUPDATED'),
          });
          this.loading = false;
        });
      },
      error: (err) => {
        this.loading = false;
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('ERROR'),
          detail: this.translateService.instant('MESSAGES.UNABLETOUPDATEDATES'),
        });
      },
    });
  }

  setDeliveryDateToToday() {
    this.confirmationService.confirm({
      message: this.translateService.instant('DELIVERYDATETODAYMESSAGE') + `${this.formatDate(this.today)}?`,
      header: this.translateService.instant('DELIVERYDATETODAY'),
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: this.translateService.instant('GENERIC.YES'),
      rejectLabel: this.translateService.instant('GENERIC.CANCEL'),
      key: 'setDeliveryDateToTodayConfirm',
      accept: () => {
        this.loading = true;
        this.shipment.deliveryDate = this.today;

        this.shipmentService.updateShipmentDates(this.shipment).subscribe({
          next: (shipment) => {
            this.shipmentService.getFullShipment(this.shipment.id).subscribe((shipment) => {
              this.shipment = shipment;
              this.sanitizeTimeOnlyFields(this.shipment);
              this.messageService.add({
                severity: 'success',
                summary: this.translateService.instant('UPDATED'),
                detail: this.translateService.instant('MESSAGES.DELIVERYDATESETTOTODAY'),
              });
              this.loading = false;
            });
          },
          error: (err) => {
            this.loading = false;
            this.messageService.add({
              severity: 'error',
              summary: this.translateService.instant('ERROR'),
              detail: this.translateService.instant('MESSAGES.UNABLETOUPDATEDATES'),
            });
          },
        });
      },
      reject: () => {},
    });
  }

  updateShipmentDetails(shipment: any) {
    this.loading = true;
    this.shipmentService.updateShipmentDetails(shipment).subscribe((shipment) => {
      this.shipmentService.getFullShipment(this.shipment.id).subscribe((shipment) => {
        this.shipment = shipment;
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('UPDATED'),
          detail: this.translateService.instant('MESSAGES.SHIPMENTUPDATED'),
        });
        this.loading = false;
      });
    });
  }

  updateShipmentServices(shipment: any) {
    this.loading = true;
    this.shipmentService.updateShipmentServices(shipment).subscribe({
      next: (shipment) => {
        this.shipmentService.getFullShipment(this.shipment.id).subscribe((shipment) => {
          this.shipment = shipment;
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('UPDATED'),
            detail: this.translateService.instant('MESSAGES.SERVICESUPDATED'),
          });
        });
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('ERROR'),
          detail: this.translateService.instant('MESSAGES.UNABLETOUPDATESERVICES'),
        });
      },
    });
  }

  editExchangePallets() {
    this.editMode = true;
    this.exchangePalletsEdit = true;
    this.editObject = JSON.parse(JSON.stringify(this.shipment)); // Fix - find a faster way to deep copy shipment... (Lodash?)
  }

  saveExchangePallets() {
    this.closeEdit();
    this.loading = true;
    this.updateShipmentExchangePallets(this.editObject);
  }

  updateShipmentExchangePallets(shipment: any) {
    this.shipmentService.updateShipmentExchangePallets(shipment).subscribe({
      next: (shipment) => {
        this.shipmentService.getFullShipment(this.shipment.id).subscribe((shipment) => {
          this.shipment = shipment;
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('UPDATED'),
            detail: this.translateService.instant('MESSAGES.EXCHANGEPALLETSUPDATED'),
          });
        });
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('ERROR'),
          detail: this.translateService.instant('MESSAGES.UNABLETOUPDATEEXCHANGEPALLETS'),
        });
      },
    });
  }

  updateShipmentDangerousGoods(shipment: any) {
    this.shipmentService.updateShipmentDangerousGoods(shipment).subscribe({
      next: (shipment) => {
        this.shipmentService.getFullShipment(this.shipment.id).subscribe((shipment) => {
          this.shipment = shipment;
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('UPDATED'),
            detail: this.translateService.instant('MESSAGES.DANGEROUSGOODSUPDATED'),
          });
        });
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('ERROR'),
          detail: this.translateService.instant('MESSAGES.UNABLETOUPDATEDANGEROUSGOODS'),
        });
      },
    });
  }

  editRefs() {
    this.editMode = true;
    this.refEdit = true;
    this.editObject = Object.assign({}, this.shipment);
  }

  saveRefs() {
    this.closeEdit();
    this.updateShipmentDetails(this.editObject);
  }

  editServices() {
    this.editObject = Object.assign([], this.shipment);
    if (this.editObject.services && this.editObject.services.length == 0) {
      this.editObject.services = null;
    }
    if (!this.shipment.carrier || this.shipment.carrier == 'Internal') {
      this.services = this.clientService.clientServices ?? [];
    }
    if (!this.services) {
      this.servicesService.getExternalServicesByCarrier(this.shipment.carrier, this.shipment.bookingType).subscribe((result: any) => {
        this.services = result.services;
        this.editMode = true;
        if (this.shipment.carrier == 'GLS') {
          this.serviceSingleSelectEdit = true;

          if (this.editObject.services && this.editObject.services.length > 0) {
            const privateDelivery = this.editObject.services.find((x: any) => x == 'PrivateDelivery');
            if (privateDelivery) {
              this.hasPrivateDeliveryService = true;
              this.editObject.services = this.editObject.services.filter((x: any) => x != 'PrivateDelivery');
              if (this.editObject.services.length == 0) {
                this.editObject.services = null;
              }
            }
          }
        } else {
          this.servicesEdit = true;
        }
      });
    } else {
      this.editMode = true;
      this.servicesEdit = true;
    }
  }

  saveServices() {
    this.closeEdit();

    if (this.editObject.services === null) {
      this.editObject.services = [];
    }
    if (!Array.isArray(this.editObject.services)) {
      this.editObject.services = [this.editObject.services];
    }
    if (this.hasPrivateDeliveryService) {
      this.editObject.services.push('PrivateDelivery');
    }
    this.updateShipmentServices(this.editObject);
  }

  editNotes() {
    this.editMode = true;
    this.notesEdit = true;
    this.editObject = Object.assign({}, this.shipment);
  }

  saveNotes() {
    this.closeEdit();
    if (this.editObject.note != this.shipment.note) {
      this.updateShipmentDetails(this.editObject);
    }
  }

  editDangerousGoods() {
    this.editMode = true;
    this.dangerousGoodsEdit = true;
    this.editObject = Object.assign({}, this.shipment);
    if (this.editObject.dangerousGoods && this.editObject.dangerousGoods.length > 0) {
      this.editObject.includeDangerousGoods = true;
    }
  }

  saveDangerousGoods() {
    this.closeEdit();
    this.updateShipmentDangerousGoods(this.editObject);
  }

  adrSearch(event: any) {
    this.adrService.searchADR(event.query).subscribe((data: any) => {
      if (data && data.items) {
        this.foundAdrs = data.items;
      }
    });
  }

  formatDate(dateStr: string): string {
    const date = new Date(dateStr);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
  }

  parseDate(dateStr: string): Date {
    const [day, month, year] = dateStr.split('/').map(Number);
    return new Date(year, month - 1, day);
  }
}

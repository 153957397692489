import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Shipment } from 'src/app/domain/Shipment';
import { ClientService } from 'src/app/services/client.service';
import { StepperService } from 'src/app/pages/Shared/stepper/stepper.service';
import { Account } from 'src/app/domain/Account';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  @Input() shipment = <Shipment>{};

  accounts: Account[] = [];

  accountForm = new FormGroup({
    accountChoice: new FormControl(this.shipment.accountId)
  })

  constructor(public stepperService: StepperService, private clientService: ClientService) {
    this.accounts = this.clientService.clientAccounts;
    this.shipment = this.stepperService.newShipment;

  }

  ngOnInit(): void {
    this.accountForm.valueChanges.subscribe((value) => {
      if (value.accountChoice) {
        this.shipment.accountId = value.accountChoice.id;
        this.shipment.reference2 = value.accountChoice.name;
      }
    });
    if (this.shipment.accountId) {
      var account = this.accounts.find(x => x.id == this.shipment.accountId);
      this.accountForm.controls.accountChoice.setValue(account, { emitEvent: false });
    }
  }

}

<div class="col-12 md:col-12">
  <div class="p-fluid p-formgrid grid">
    <div class="formgrid grid">
      <div class="field col-12">
        Indtast kolonne-nummer for hvert felt - længst til venstre i filen er 0. Efterlad feltet blankt hvis det ikke
        eksiterer <br />
        Land skal være landekoden - fx DK, SE, DE<br />
        Filen må ikke indeholde overskrifter
      </div>
      <div class="field col-3">
        <label for="delimiter">Seperator</label>
        <input type="text" id="delimiter" pInputText [(ngModel)]="delimiter" />
      </div>

      <div class="field col-3">
        <label for="customerNo">{{'CUSTOMER.CUSTOMERNO' | translate}}</label>
        <p-inputNumber inputId="customerNo" [(ngModel)]="columnDefinition.customerNo"></p-inputNumber>
      </div>

      <div class="field col-3">
        <label for="name">{{'CUSTOMER.NAME' | translate}}</label>
        <p-inputNumber inputId="name" [(ngModel)]="columnDefinition.name"></p-inputNumber>
      </div>

      <div class="field col-3">
        <label for="registrationNumber">{{'CUSTOMER.REGISTRATIONNUMBER' | translate}}</label>
        <p-inputNumber inputId="registrationNumber" [(ngModel)]="columnDefinition.registrationNumber"></p-inputNumber>
      </div>

      <div class="field col-3">
        <label for="street">{{'STREET' | translate}}</label>
        <p-inputNumber inputId="street" [(ngModel)]="columnDefinition.street"></p-inputNumber>
      </div>

      <div class="field col-3">
        <label for="zipCode">{{'ZIPCODE' | translate}}</label>
        <p-inputNumber inputId="zipCode" [(ngModel)]="columnDefinition.zipCode"></p-inputNumber>
      </div>

      <div class="field col-3">
        <label for="city">{{'CITY' | translate}}</label>
        <p-inputNumber inputId="city" [(ngModel)]="columnDefinition.city"></p-inputNumber>
      </div>

      <div class="field col-3">
        <label for="countryCode">{{'COUNTRYCODE' | translate}}</label>
        <p-inputNumber inputId="countryCode" [(ngModel)]="columnDefinition.countryCode"></p-inputNumber>
      </div>

      <div class="field col-3">
        <label for="contactName">{{'CUSTOMER.CONTACT.NAME' | translate}}</label>
        <p-inputNumber inputId="contactName" [(ngModel)]="columnDefinition.contactName"></p-inputNumber>
      </div>

      <div class="field col-3">
        <label for="contactPhone">{{'CUSTOMER.CONTACT.PHONE' | translate}}</label>
        <p-inputNumber inputId="contactPhone" [(ngModel)]="columnDefinition.contactPhone"></p-inputNumber>
      </div>

      <div class="field col-3">
        <label for="contactEmail">{{'CUSTOMER.CONTACT.EMAIL' | translate}}</label>
        <p-inputNumber inputId="contactEmail" [(ngModel)]="columnDefinition.contactEmail"></p-inputNumber>
      </div>

      <div class="field col-3">
        <label for="note">{{'NOTE' | translate}}</label>
        <p-inputNumber inputId="note" [(ngModel)]="columnDefinition.note"></p-inputNumber>
      </div>

      <div class="field col-3">
        <label for="file">Vælg fil</label>
        <p-fileUpload inputId="file" mode="basic" chooseLabel="Vælg fil" accept=".csv" maxFileSize="1000000"
          (onSelect)="fileChangeListener($event)"></p-fileUpload>
      </div>

      <div class="field col-12">
        <button pButton type="button" label="Opdater tabel" (click)="mapFields()"></button>
      </div>

      <div class="field col-12">
        <p-table #dt1 [value]="customers" dataKey="customerNo" [rowHover]="true">
          <ng-template pTemplate="header">
            <tr>
              <th>{{'CUSTOMER.CUSTOMERNO' | translate}}</th>
              <th>{{'CUSTOMER.NAME' | translate}}</th>
              <th>{{'CUSTOMER.REGISTRATIONNUMBER' | translate}}</th>
              <th>{{'STREET' | translate}}</th>
              <th>{{'ZIPCODE' | translate}}</th>
              <th>{{'CITY' | translate}}</th>
              <th>{{'COUNTRYCODE' | translate}}</th>
              <th>{{'CUSTOMER.CONTACT.NAME' | translate}}</th>
              <th>{{'CUSTOMER.CONTACT.PHONE' | translate}}</th>
              <th>{{'CUSTOMER.CONTACT.EMAIL' | translate}}</th>
              <th>{{'NOTE' | translate}}</th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-customer let-rowIndex="rowIndex">
            <tr>
              <td>{{customer.customerNo}}</td>
              <td>{{customer.name}}</td>
              <td>{{customer.registrationNumber}}</td>
              <td>{{customer.street}}</td>
              <td>{{customer.zipCode}}</td>
              <td>{{customer.city}}</td>
              <td>{{customer.countryCode}}</td>
              <td>{{customer.contactName}}</td>
              <td>{{customer.contactPhone}}</td>
              <td>{{customer.contactEmail}}</td>
              <td>{{customer.note}}</td>
              <ng-container [ngSwitch]="getStatus(rowIndex)">
                <td *ngSwitchDefault><i class="pi pi-plus-circle"></i></td>
                <td *ngSwitchCase="'success'"><i class="pi pi-check-circle"></i></td>
                <td *ngSwitchCase="'error'"><i class="pi pi-times-circle"></i></td>
              </ng-container>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <div class="field col-12">
        <button pButton type="button" label="Import" (click)="onSubmit()"></button>
      </div>
    </div>
  </div>
</div>
<div [formGroup]="payerForm">
  <div class="col-12 md:col-12" style="margin-bottom:-12px; padding-bottom:0px;">
    <h4 class="primColor">{{'PAYER' | translate}}</h4>
  </div>
  <div class="field grid">
    <div class="col-12 m-2">
      <p-radioButton inputId="franco" name="payerType" value="franco" formControlName="payerType"
        (ngModelChange)="onPayerTypeChange()"></p-radioButton>
      <label for="exworks" class="ml-2">
        {{'FRANCO' | translate}}
      </label>
    </div>
    <div class="col-12 m-2">
      <p-radioButton inputId="exworks" name="payerType" value="exworks" formControlName="payerType"
        (ngModelChange)="onPayerTypeChange()"></p-radioButton>
      <label for="exworks" class="ml-2">
        {{'EXWORKS' | translate}}
      </label>
    </div>
  </div>

  <div class="p-fluid p-formgrid grid gap-1" *ngIf="payerForm.get('paymentChoice').enabled">
    <div class="col-6 md:col-6" *ngIf="payers">
      <p-dropdown [options]="payers" formControlName="paymentChoice" inputId="payment" optionLabel="name"
        placeholder="Vælg betaler" emptyMessage="{{'NORESULTS' | translate}}"
        (onChange)="onPaymentChange($event)"></p-dropdown>
    </div>
  </div>

  <p-divider></p-divider>

  <span style="font-size:small;" *ngIf="payerForm.invalid">{{'PAYERREQUIRED' | translate}}</span>

  <div class="bottomBar">
    <button pButton pRipple label="{{'GENERIC.PREVIOUS' | translate}}" *ngIf="stepperService.activeIndex > 0"
      (click)="stepperService.stepPrev()"></button>
    <button [disabled]="payerForm.invalid" pButton pRipple label="{{'GENERIC.NEXT' | translate}}"
      (focus)="stepperService.stepNext()" (click)="stepperService.stepNext()"></button>
  </div>
</div>
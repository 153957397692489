import { OBPermissions } from '@alicetms/auth.permissions';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ADRService } from 'src/app/services/adr.service';
import { StepperService } from '../stepper.service';
import { ConfigService } from 'src/app/services/app.config.service';
import { AppConfig } from 'src/app/domain/appconfig';
import { Subscription } from 'rxjs';
import { DangerousGoods } from 'src/app/domain/DangerousGoods';

@Component({
  selector: 'app-dangerous-goods',
  templateUrl: './dangerous-goods.component.html',
  styleUrls: ['./dangerous-goods.component.scss']
})
export class DangerousGoodsComponent implements OnInit, OnDestroy {
  adrPermission = OBPermissions.BookDangerousGoods;
  config: AppConfig;
  subscription: Subscription;
  shipment: any;
  foundAdrs: DangerousGoods[] = [];

  includeDangerousGoods: any;

  constructor(private configService: ConfigService, private adrService: ADRService, public stepperService: StepperService) {
    this.config = this.configService.config;
    this.subscription = this.configService.configUpdate$.subscribe(
      (config) => { this.config = config; });

    this.shipment = this.stepperService.newShipment;
  }

  ngOnInit(): void {
    if (this.shipment.dangerousGoods && this.shipment.dangerousGoods.length > 0) {
      this.includeDangerousGoods = true;
    }
  }

  public waybillShortString(dangerousGood: any) {
    return dangerousGood.waybillString?.substring(0, 80);
  }

  adrSearch(event: any) {
    if (event.query == "UN" || event.query == "un") {
      this.foundAdrs = [];
      return;
    }
    if (event.query.includes('UN')) {
      event.query = event.query.replace('UN', '').trim();
    }
    if (event.query.includes('un')) {
      event.query = event.query.replace('un', '').trim();
    }

    this.adrService.searchADR(event.query).subscribe((data: any) => {
      if (data && data.items) {
        this.foundAdrs = data.items;
      }
    });
  }

  clearDangerousGoods() {
    this.shipment.dangerousGoods = [];
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


}

import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss'],
  animations: [
    trigger('validationErrorAnimation', [
      transition(':enter', [
        style({ height: 0, transform: 'scaleY(0)' }),
        animate(
          '.4s ease-out',
          style({ height: '1.5rem', transform: 'scaleY(1)' })
        ),
      ]),
      transition(':leave', [
        style({ height: '1.5rem', transform: 'scaleY(1)' }),
        animate('.25s ease-in', style({ height: 0, transform: 'scaleY(0)' })),
      ]),
    ]),
  ],
})
export class FormErrorComponent implements OnInit {

  @Input() controls: any;

  constructor() { }

  ngOnInit(): void {
  }

}

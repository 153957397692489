import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { DividerModule } from "primeng/divider";
import { InputMaskModule } from "primeng/inputmask";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { MultiSelectModule } from "primeng/multiselect";
import { TableModule } from "primeng/table";
import { TooltipModule } from "primeng/tooltip";
import { AddressModule } from "../stepper/address/address.module";
import { ColliModule } from "../stepper/colli/colli.module";
import { ExchangePalletsModule } from "../stepper/exchange-pallets/exchange-pallets.module";
import { ShipmentPreviewComponent } from "./shipment-preview.component";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { ProgressBarModule } from "primeng/progressbar";
import { CheckboxModule } from "primeng/checkbox";
import { MessagesModule } from "primeng/messages";
import { AutoCompleteModule } from "primeng/autocomplete";
import { CarouselModule } from "primeng/carousel";
import { GalleriaModule } from "primeng/galleria";
import { InplaceModule } from "primeng/inplace";
import { SkeletonModule } from "primeng/skeleton";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { RadioButtonModule } from "primeng/radiobutton";
import { AvatarModule } from "primeng/avatar";
import { BadgeModule } from "primeng/badge";
import { SplitButtonModule } from "primeng/splitbutton";
import { InputNumberModule } from "primeng/inputnumber";

@NgModule({
    imports: [
        CommonModule,
        DividerModule,
        TableModule,
        TooltipModule,
        AddressModule,
        ButtonModule,
        ExchangePalletsModule,
        InputTextModule,
        FormsModule,
        InputTextareaModule,
        InputMaskModule,
        MultiSelectModule,
        ColliModule,
        ConfirmDialogModule,
        CalendarModule,
        ProgressSpinnerModule,
        ProgressBarModule,
        CheckboxModule,
        ReactiveFormsModule,
        AutoCompleteModule,
        TranslateModule.forChild({
            extend: true,
        }),
        MessagesModule,
        CarouselModule,
        GalleriaModule,
        InplaceModule,
        SkeletonModule,
        LeafletModule,
        RadioButtonModule,
        AvatarModule,
        BadgeModule,
        SplitButtonModule,
        RadioButtonModule,
        InputNumberModule
    ],
    declarations: [ShipmentPreviewComponent],
    exports: [ShipmentPreviewComponent],
})
export class ShipmentPreviewModule { }

<div class="col-12 md:col-12">
    <div class="p-fluid p-formgrid grid">
        <form [formGroup]="newCustomerForm" class="col-12 md:col-12">
            <p-divider align="left">
                <div class="inline-flex align-items-center">
                    <i class="pi pi-building mr-2"></i>
                    <b>{{ 'CUSTOMER.FORM.BASICINFO' | translate }}</b>
                </div>
            </p-divider>
            <div class="field grid">
                <div class="col-9 md:col-9">
                    <span class="p-float-label">
                        <input type="text" id="name" pInputText formControlName="name">
                        <label for="name">{{ 'CUSTOMER.NAME' | translate }}</label>
                    </span>
                </div>
                <div class="col-3 md:col-3">
                    <p-message severity="error" *ngIf="newCustomerForm.controls['name'].errors?.required"
                        text="{{ 'CUSTOMER.FORM.REQUIREDFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                    <p-message severity="success" *ngIf="newCustomerForm.controls['name'].valid"
                        text="{{ 'CUSTOMER.FORM.OKFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                </div>
            </div>
            <div class="field grid">
                <div class="col-9 md:col-9">
                    <span class="p-float-label">
                        <input type="text" id="customerNo" pInputText formControlName="customerNo">
                        <label for="customerNo">{{ 'CUSTOMER.CUSTOMERNO' | translate }}</label>
                    </span>
                </div>
                <div class="col-3 md:col-3">
                    <p-message severity="error" *ngIf="newCustomerForm.controls['customerNo'].errors?.required"
                        text="{{ 'CUSTOMER.FORM.REQUIREDFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                    <p-message severity="success" *ngIf="newCustomerForm.controls['customerNo'].valid"
                        text="{{ 'CUSTOMER.FORM.OKFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                </div>
            </div>
            <div class="field grid">
                <div class="col-9 md:col-9">
                    <span class="p-float-label">
                        <input type="text" id="registrationNumber" pInputText formControlName="registrationNumber">
                        <label for="registrationNumber">{{ 'CUSTOMER.REGISTRATIONNUMBER' | translate }}</label>
                    </span>
                </div>
                <div class="col-3 md:col-3">
                    <p-message severity="error" *ngIf="newCustomerForm.controls['registrationNumber'].errors?.required"
                        text="{{ 'CUSTOMER.FORM.REQUIREDFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                    <p-message severity="success" *ngIf="newCustomerForm.controls['registrationNumber'].valid"
                        text="{{ 'CUSTOMER.FORM.OKFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                </div>
            </div>
            <p-divider align="left">
                <div class="inline-flex align-items-center">
                    <i class="pi pi-map mr-2"></i>
                    <b>{{ 'CUSTOMER.FORM.ADDRESS' | translate }}</b>
                </div>
            </p-divider>
            <div class="field grid">
                <div class="col-9 md:col-9">
                    <span class="p-float-label">
                        <p-dropdown [options]="countries" [(ngModel)]="selectedCountry" id="country"
                            formControlName="countryCode" optionValue="code" optionLabel="name"
                            placeholder="{{ 'CUSTOMER.FORM.SELECTCOUNTRY' | translate }} *">
                            <ng-template pTemplate="selectedItem">
                                <div class="country-item country-item-value" *ngIf="selectedCountry">
                                    <div><img src="assets/demo/flags/flag_placeholder.png"
                                            [class]="'flag flag-' + selectedCountry.toLowerCase()" />
                                        {{ 'COUNTRIES.'+selectedCountry | translate }}
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template let-country pTemplate="item">
                                <div class="country-item">
                                    <img src="assets/demo/flags/flag_placeholder.png"
                                        [class]="'flag flag-' + country.code.toLowerCase()" />
                                    {{ 'COUNTRIES.'+country.code | translate }}
                                </div>
                            </ng-template>
                        </p-dropdown>
                        <label for="country">{{ 'CUSTOMER.FORM.SELECTCOUNTRY' | translate }}</label>
                    </span>
                </div>
                <div class="col-3 md:col-3">
                    <p-message severity="error" *ngIf="newCustomerForm.controls['countryCode'].errors?.required"
                        text="{{ 'CUSTOMER.FORM.REQUIREDFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                    <p-message severity="success" *ngIf="newCustomerForm.controls['countryCode'].valid"
                        text="{{ 'CUSTOMER.FORM.OKFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                </div>
            </div>
            <div class="field grid">
                <div class="col-9 md:col-9">
                    <span class="p-float-label">
                        <p-autoComplete [suggestions]="foundAddresses" [minLength]="3" formControlName="street"
                            showEmptyMessage="true" emptyMessage="{{'NORESULTS' | translate}}" [delay]="1000"
                            name="test" id="addressStreet" #addressStreet autocomplete="anyrandomstring"
                            (completeMethod)="searchAddress($event)" showClear="true" (onClear)="onClearAddress()"
                            (onSelect)="setAddress($event)" [field]="formattedStreetName">

                            <ng-template let-address pTemplate="address">
                                <div class="address-item">
                                    <div>
                                        <img *ngIf="address.countryCode" src="assets/demo/flags/flag_placeholder.png"
                                            [class]="'flag flag-' + address.countryCode.toLowerCase()" />
                                        {{address.street}},
                                        <span class="grayedText"> {{address.zipCode}} {{address.city}}</span>
                                    </div>
                                </div>
                            </ng-template>
                        </p-autoComplete>
                        <label for="address">{{'ADDRESS' | translate}}</label>
                    </span>
                </div>
                <div class="col-3 md:col-3">
                    <p-message severity="error" *ngIf="newCustomerForm.controls['street'].errors?.required"
                        text="{{ 'CUSTOMER.FORM.REQUIREDFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                    <p-message severity="success" *ngIf="newCustomerForm.controls['street'].valid"
                        text="{{ 'CUSTOMER.FORM.OKFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                </div>
            </div>
            <div class="field grid">
                <div class="col-9 md:col-9">
                    <span class="p-float-label">
                        <input type="text" id="zipCode" pInputText formControlName="zipCode">
                        <label for="zipCode">{{ 'CUSTOMER.ADDRESS.ZIPCODE' | translate }}</label>
                    </span>
                </div>
                <div class="col-3 md:col-3">
                    <p-message severity="error" *ngIf="newCustomerForm.controls['zipCode'].errors?.required"
                        text="{{ 'CUSTOMER.FORM.REQUIREDFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                    <p-message severity="success" *ngIf="newCustomerForm.controls['zipCode'].valid"
                        text="{{ 'CUSTOMER.FORM.OKFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                </div>
            </div>
            <div class="field grid">
                <div class="col-9 md:col-9">
                    <span class="p-float-label">
                        <input type="text" id="city" pInputText formControlName="city">
                        <label for="city">{{ 'CUSTOMER.ADDRESS.CITY' | translate }}</label>
                    </span>
                </div>
                <div class="col-3 md:col-3">
                    <p-message severity="error" *ngIf="newCustomerForm.controls['city'].errors?.required"
                        text="{{ 'CUSTOMER.FORM.REQUIREDFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                    <p-message severity="success" *ngIf="newCustomerForm.controls['city'].valid"
                        text="{{ 'CUSTOMER.FORM.OKFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                </div>
            </div>
            <p-divider align="left">
                <div class="inline-flex align-items-center">
                    <i class="pi pi-user mr-2"></i>
                    <b>{{ 'CUSTOMER.FORM.CONTACTPERSON' | translate }}</b>
                </div>
            </p-divider>
            <div class="field grid">
                <div class="col-9 md:col-9">
                    <span class="p-float-label">
                        <input type="text" id="contactName" pInputText formControlName="contactName">
                        <label for="contactName">{{ 'CUSTOMER.CONTACT.NAME' | translate }}</label>
                    </span>
                </div>
                <div class="col-3 md:col-3">
                    <p-message severity="error" *ngIf="newCustomerForm.controls['contactName'].errors?.required"
                        text="{{ 'CUSTOMER.FORM.REQUIREDFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                    <p-message severity="success" *ngIf="newCustomerForm.controls['contactName'].valid"
                        text="{{ 'CUSTOMER.FORM.OKFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                </div>
            </div>
            <div class="field grid">
                <div class="col-9 md:col-9">
                    <span class="p-float-label">
                        <input type="email" id="contactEmail" pInputText formControlName="contactEmail">
                        <label for="contactEmail">{{ 'CUSTOMER.CONTACT.EMAIL' | translate }}</label>
                    </span>
                </div>
                <div class="col-3 md:col-3">
                    <p-message severity="error" *ngIf="newCustomerForm.controls['contactEmail'].errors?.required"
                        text="{{ 'CUSTOMER.FORM.REQUIREDFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                    <p-message severity="success" *ngIf="newCustomerForm.controls['contactEmail'].valid"
                        text="{{ 'CUSTOMER.FORM.OKFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                </div>
            </div>
            <div class="field grid">
                <div class="col-9 md:col-9">
                    <div class="p-inputgroup">
                        <p-dropdown inputId="phone" [options]="countryCodes" [(ngModel)]="selectedPhoneCountry"
                            appendTo="body" (onChange)="onPhoneCountryChange($event)"
                            [style]="{'min-width':'100%', 'border-right': '1px solid white', 'border-radius': '1px'}"
                            optionLabel="label" [ngModelOptions]="{standalone: true}">
                            <!-- Chosen country -->
                            <ng-template pTemplate="selectedItem">
                                <div class="flex align-items-center gap-2" *ngIf="selectedPhoneCountry">
                                    <img src="/assets/demo/flags/flag_placeholder.png"
                                        [class]="'flag flag-' + selectedPhoneCountry.value?.toLowerCase()"
                                        style="width: 18px" />
                                    <div>{{ selectedPhoneCountry.value }} {{selectedPhoneCountry.callCode}}</div>
                                </div>
                            </ng-template>

                            <!-- Suggestions -->
                            <ng-template let-country pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                    <img src="/assets/demo/flags/flag_placeholder.png"
                                        [class]="'flag flag-' + country.value.toLowerCase()" style="width: 18px" />
                                    <div>{{ country.label }} {{country.callCode}}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                        <input style="min-width:520px;" formControlName="contactPhone" pInputText id="contactPhone"
                            #phone type="text" placeholder="{{'CUSTOMER.CONTACT.PHONE' | translate}}" />
                    </div>
                </div>
                <div class="col-3 md:col-3">
                    <p-message severity="error" *ngIf="newCustomerForm.controls['contactPhone'].errors?.required"
                        text="{{ 'CUSTOMER.FORM.REQUIREDFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                    <p-message severity="error" *ngIf="newCustomerForm.controls['contactPhone'].errors?.invalid"
                        text="Ugyldig nummer" class="scalein animation-duration-1000">
                    </p-message>
                    <p-message severity="success" *ngIf="newCustomerForm.controls['contactPhone'].valid"
                        text="{{ 'CUSTOMER.FORM.OKFIELD' | translate }}" class="scalein animation-duration-1000">
                    </p-message>
                </div>
            </div>
            <div class="field grid">
                <div class="col-8 md:col-8">
                    <p-checkbox inputId="sendLabel" formControlName="sendLabel" binary="true"
                        label="Send label"></p-checkbox>
                </div>
                <div class="col-4 md:col-4">
                    <p-message severity="info"
                        *ngIf="!newCustomerForm.controls['contactEmail'].valid || !newCustomerForm.controls['contactEmail'].value"
                        class="scalein animation-duration-1000" text="Udfyld Email for at sende label"></p-message>
                </div>
            </div>
        </form>
    </div>
    <div class="col-12 md:col-12" style="text-align: end;">
        <span style="padding-right:4px; vertical-align:super;"
            *ngIf="newCustomerForm.touched && !newCustomerForm.valid">{{
            'CUSTOMER.FORM.INVALID' | translate
            }}</span>
        <button style="margin-right:8px;" pButton pRipple label="{{ 'GENERIC.SAVE' | translate }}" icon="pi pi-check"
            (click)="createCustomer()" [disabled]="!newCustomerForm.valid" class="p-button-success"></button>
        <button pButton pRipple label="{{ 'GENERIC.CANCEL' | translate }}" icon="pi pi-times" (click)="closeDialog()"
            class="p-button-warning"></button>
    </div>
</div>
<div>
    <form [formGroup]="accountForm">
        <div class="p-fluid p-formgrid grid gap-1">
            <div class="col-12 md:col-12" style="margin-bottom:-12px; padding-bottom:0px;">
                <h4 class="primColor">{{'ACCOUNT' | translate}}</h4>
            </div>
            <div class="col-6 md:col-6">
                <span class="p-float-label">
                    <p-dropdown [options]="accounts" formControlName="accountChoice" inputId="account"
                        optionLabel="name" placeholder="{{'ACCOUNTCHOICE' | translate}}"
                        emptyMessage="{{'NORESULTS' | translate}}"></p-dropdown>
                    <label for="account">{{'ACCOUNTCHOICE' | translate}}</label>
                </span>
            </div>
        </div>
    </form>

    <p-divider></p-divider>

    <span *ngIf="!shipment.accountId">{{'ACCOUNTREQUIRED' | translate}}</span>

    <div class="bottomBar">
        <button pButton pRipple label="{{'GENERIC.PREVIOUS' | translate}}" *ngIf="stepperService.activeIndex > 0"
            (click)="stepperService.stepPrev()"></button>
        <button [disabled]="!shipment.accountId" pButton pRipple label="{{'GENERIC.NEXT' | translate}}"
            (focus)="stepperService.stepNext()" (click)="stepperService.stepNext()"></button>
    </div>
</div>
<div [formGroup]="serviceForm">
  <h4 class="primColor">{{'SERVICES' | translate}}</h4>
  <div class="p-fluid p-formgrid grid row-gap-1">

    <div *ngFor="let service of services" class="col-4 md:col-4">
      <p-checkbox [(ngModel)]="shipment.services" formControlName="services" [value]="service.code"
        [label]="service.code | serviceText | async" (onChange)="onServiceChange($event)"></p-checkbox>
    </div>
  </div>
  <p-divider></p-divider>
  <div formArrayName="serviceData" class="p-fluid p-formgrid grid">
    <div *ngFor="let fg of getServiceDataFormGroups()" class="col-4 md:col-4">
      <ng-container [formGroup]="fg">
        <label for="value">{{ fg.get('service').value | serviceText | async }}</label>
        <input pInputText id="value" formControlName="value">
      </ng-container>
    </div>
  </div>

  <p-divider></p-divider>

  <div class="bottomBar">
    <button pButton pRipple label="{{'GENERIC.PREVIOUS' | translate}}" *ngIf="stepperService.activeIndex > 0"
      (click)="stepperService.stepPrev()"></button>
    <button pButton pRipple label="{{'GENERIC.NEXT' | translate}}" (focus)="stepperService.stepNext()"
      (click)="stepperService.stepNext()" [disabled]="!serviceForm.valid"></button>
  </div>
</div>
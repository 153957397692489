import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ParcelShopService {

    constructor(private httpClient: HttpClient) {
    }

    getGLSParcelShopsByAddress(address: any) {
        return this.httpClient.post(`${environment.apiUrl}/parcelshops/searchByAddress`, address);
    }

    getPostnordParcelShopsByAddress(address: any) {
        return this.httpClient.post(`${environment.apiUrl}/parcelshops/searchPostnordParcelShops`, address);
    }

}


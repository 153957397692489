import { Injectable } from '@angular/core';
import { Shipment } from '../../../domain/Shipment';
import { ClientService } from 'src/app/services/client.service';

@Injectable({
  providedIn: 'root',
})
export class StepperService {
  newShipment: Shipment = <Shipment>{};
  activeIndex: number = 0;
  steps: any[] = [];

  senderContactPersons: any[];
  recipientContactPersons: any[];
  pickupContactPersons: any[];
  senderCustomer: any;
  recipientCustomer: any;
  bookingChoice: any;
  resetBookingDate: any;

  constructor(private clientService: ClientService) {}

  stepNext() {
    this.steps[this.activeIndex + 1].disabled = false;
    this.steps[this.activeIndex + 1].command();
  }

  goToStart(resetBookingChoice: boolean = false) {
    this.steps[0]?.command();
    for (let i = 1; i < this.steps.length; i++) {
      this.steps[i].disabled = true;
    }
    if (resetBookingChoice) {
      this.bookingChoice = null;
      this.steps = [];
      this.newShipment = <Shipment>{
        senderAddress: {},
        recipientAddress: {},
        collis: [],
        dangerousGoods: [],
      };
    }
    if (this.clientService.clientAddress) {
      this.newShipment.senderAddress = Object.assign({}, this.clientService.clientAddress);
      this.newShipment.senderAddress.name = this.clientService.defaultClientName;
      this.newShipment.senderAddress.contactEmail = this.clientService.defaultClientEmail;
      this.newShipment.senderAddress.contactPhone = this.clientService.defaultClientPhone;
      this.newShipment.senderAddress.contactPerson = this.clientService.defaultClientContactPerson;
    }
  }

  stepPrev() {
    if (this.activeIndex - 1 >= 0) {
      this.steps[this.activeIndex - 1].command();
    }
  }
}

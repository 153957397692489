<div class="card">
  <h5 class="header">{{'BOOKING' | translate }} </h5>

  <div class="grid col" style="padding-bottom:0px;">
    <div class="md:col-3">
      <span>{{'DATE' | translate}}</span>
      <p>{{getToday() | date : 'dd/MM/yyyy'}}</p>
    </div>
    <div class="md:col-3">
      <span>{{'CUSTOMERNO' | translate}}</span>
      <p></p>
    </div>
    <div class="md:col-3">
      <span>{{'ORDERNO' | translate}}</span>
      <p>{{shipment?.waybillNo}}</p>
    </div>
    <div class="md:col-3">
      <span>{{'FREIGHTNO' | translate}}</span>
    </div>
  </div>

  <p-divider *ngIf="shipment.exchangePallets"></p-divider>

  <div *ngIf="shipment.exchangePallets" class="grid col" style="padding-bottom:0px;">
    <div class="md:col-3">
      <span style="color:var(--primary-color);">{{'COLLIEXCHANGE' | translate}}</span>
    </div>
    <div class="md:col-3">
      <span>{{'FULLPALLETLABEL' | translate}}</span>
    </div>
    <div class="md:col-3">
      <span>{{'HALFPALLETLABEL' | translate}}</span>
    </div>
    <div class="md:col-3">
      <span>{{'QUARTERPALLETLABEL' | translate}}</span>
    </div>
  </div>

  <div *ngIf="shipment.exchangePallets" class="grid col" style="padding-top:0px;">
    <div class="md:col-3">
      <span *ngIf="shipment.exchangePallets">{{'GENERIC.YES' | translate}}</span>
      <span *ngIf="!shipment.exchangePallets">{{'GENERIC.NO' | translate}}</span>
    </div>
    <div class="md:col-3">
      <span>{{shipment.fullExchangePallets?.quantity}}</span>
    </div>
    <div class="md:col-3">
      <span>{{shipment.halfExchangePallets?.quantity}}</span>
    </div>
    <div class="md:col-3">
      <span>{{shipment.quarterExchangePallets?.quantity}}</span>
    </div>

  </div>

  <p-divider></p-divider>
  <div class="grid col">
    <div class="md:col-6">
      <span class="primColor">{{'SENDER' | translate}}:</span><br />
      <span>{{shipment?.senderAddress?.name}}</span><br />
      <span>{{shipment?.senderAddress?.street}}</span><br />
      <span>{{shipment?.senderAddress?.countryCode}}<span
          *ngIf="shipment?.senderAddress?.countryCode && shipment?.senderAddress?.zipCode">-{{shipment?.senderAddress?.zipCode}}</span>
        {{shipment?.senderAddress?.city}}</span><br />
      <br />
      <span>{{shipment?.senderAddress?.contactPerson}}</span> <br />
      <span>{{shipment?.senderAddress?.contactPhone}}</span> <br />
      <span>{{shipment?.senderAddress?.contactEmail}}</span>
      <span>{{senderCustomer?.note}}</span>
    </div>
    <div class="md:col-3">
      <span class="primColor">{{'RECEIVER' | translate}}:</span><br />
      <span *ngIf="!shipment.parcelShop">{{shipment?.recipientAddress?.name}}</span>
      <span *ngIf="shipment.parcelShop">{{shipment.parcelShop?.companyName}}</span> <br />
      <span>{{shipment?.recipientAddress?.street}}</span><br />
      <span>{{shipment?.recipientAddress?.countryCode}}<span
          *ngIf="shipment?.recipientAddress?.countryCode && shipment?.recipientAddress?.zipCode">-</span>{{shipment?.recipientAddress?.zipCode}}
        {{shipment?.recipientAddress?.city}}</span><br />
      <br />
      <span>{{shipment?.recipientAddress?.contactPerson}}</span> <br />
      <span>{{shipment?.recipientAddress?.contactPhone}}</span> <br />
      <span>{{shipment?.recipientAddress?.contactEmail}}</span>
      <span>{{recipientCustomer?.note}}</span>
    </div>
  </div>

  <div class="grid col">
    <div class="md:col-6">
      <span class="primColor">{{'PICKUP' | translate}}</span><br />
      <span>{{shipment?.senderAddress?.date}} {{(shipment?.senderAddress?.timeStart != null
        &&
        shipment?.senderAddress?.date
        != null) ? ":" : ""}}
        {{shipment?.senderAddress?.timeStart}} {{shipment?.senderAddress?.timeEnd != null ? "-" : ""}}
        {{shipment?.senderAddress?.timeEnd}}</span>
    </div>
    <div class="md:col-6">
      <span class="primColor">{{'DELIVERY' | translate}}</span><br />
      <span>{{shipment?.recipientAddress?.date }} {{(shipment?.recipientAddress?.timeStart !=
        null
        &&
        shipment?.recipientAddress?.date != null) ? ":" : ""}}
        {{shipment?.recipientAddress?.timeStart}} {{shipment?.recipientAddress?.timeEnd != null ? "-" :
        ""}}
        {{shipment?.recipientAddress?.timeEnd}}</span>
    </div>
  </div>


  <p-divider *ngIf="shipment?.note"></p-divider>

  <div class="grid col" style="padding-bottom:0px;">
    <div class="md:col-12">
      <span *ngIf="shipment?.note" class="primColor">{{'NOTE' | translate}}<br />
      </span>
      <span style="line-break:anywhere;">{{shipment?.note}}</span>
    </div>
  </div>

  <p-divider *ngIf="shipment?.reference1 || shipment?.reference2 || shipment?.reference3"></p-divider>

  <div class="grid col" style="padding-bottom:0px;">
    <div class="md:col-4">
      <span class="primColor" *ngIf="shipment?.reference1">Reference 1
        <br /></span><span>{{shipment?.reference1}}</span>
    </div>
    <div class="md:col-4">
      <span class="primColor" *ngIf="shipment?.reference2">Reference 2
        <br /></span><span>{{shipment?.reference2}}</span>
    </div>
    <div class="md:col-4">
      <span class="primColor" *ngIf="shipment?.reference3">Reference 3
        <br /></span><span>{{shipment?.reference3}}</span>
    </div>
  </div>


  <p-divider></p-divider>

  <div class="grid col-12">
    <div class="md:col-1">
      <span>{{'AMOUNT' | translate}}</span><br />
    </div>
    <div class="md:col-1">
      <span>{{'TYPE' | translate}}</span><br />
    </div>
    <div class="md:col-1">
      <span>{{'WEIGHT' | translate}}</span><br />
    </div>
    <div class="md:col-1">
      <span>{{'VOLUMESHORT' | translate}}</span>
    </div>
    <div class="md:col-1">
      <span>L</span>
    </div>
    <div class="md:col-1">
      <span>B</span>
    </div>
    <div class="md:col-1">
      <span>H</span>
    </div>
    <div class="md:col-1">
      <span>LDM</span>
    </div>
    <div class="md:col-1">
      <span>ADR</span>
    </div>
    <div class="md:col-2">
      <span>{{'COLLIDESCRIPTION' | translate}}</span>
    </div>
    <div class="md:col-1"></div>
  </div>

  <div class="grid col-12" *ngFor="let colli of shipment?.collis">
    <div class="md:col-1 breakall">
      {{colli.quantity}}
    </div>
    <div class="md:col-1 breakall">
      {{colli.type}}
    </div>
    <div class="md:col-1 breakall">
      {{colli.weight}}
    </div>
    <div class="md:col-1 breakall">
      {{colli.volume}}
    </div>
    <div class="md:col-1 breakall">
      {{colli.length}}
    </div>
    <div class="md:col-1 breakall">
      {{colli.width}}
    </div>
    <div class="md:col-1 breakall">
      {{colli.height}}
    </div>
    <div class="md:col-1 breakall">
      {{colli.loadMeter != null ? colli.loadMeter.toFixed(3) : colli.loadMeter}}
    </div>
    <div class="md:col-1 breakall">
    </div>
    <div class="md:col-2 breakall description" [pTooltip]="colli.description" tooltipPosition="left">
      {{colli.description}}
    </div>
    <div class="md:col-1" style="margin-top:-8px;">
      <button *ngIf="colli?.type != 'GLS' && colli?.type != 'PC'" pButton pRipple icon="pi pi-trash"
        class="p-button-rounded p-button-danger" style="font-size:1rem;" (click)="deleteColli(colli)"></button>
    </div>
  </div>

  <p-divider></p-divider>

  <div *ngIf="shipment?.services">
    <div class="grid col">
      <div class="md:col-6">
        <span class="primColor">{{'SERVICES' | translate}}:</span>
      </div>

      <div *ngFor="let service of shipment?.services" class="col-12 md:col-12">
        <span>{{service.toUpperCase() | serviceText | async}}</span>
      </div>
    </div>

    <p-divider></p-divider>
  </div>

  <div *ngIf="shipment?.externalCarrierServices && shipment?.externalCarrierServices.length > 0">
    <div class="grid col">
      <div class="md:col-6">
        <span class="primColor">{{'SERVICES' | translate}}:</span>
      </div>

      <div *ngFor="let service of shipment?.externalCarrierServices" class="col-12 md:col-12">
        <span>{{service.code.toUpperCase() | translate}}</span>
      </div>
    </div>

    <p-divider></p-divider>
  </div>


  <div *ngIf="shipment?.dangerousGoods && shipment?.dangerousGoods.length > 0">
    <div class="grid col">
      <div class="md:col-6">
        <span class="primColor">{{'DANGEROUSGOODS' | translate}}</span>
      </div>

      <div *ngFor="let adr of shipment?.dangerousGoods" class="col-12 md:col-12">
        <span style="padding-left:0px;" class="col-10 md:col-10">{{adr.waybillString}}</span>
      </div>
    </div>

    <p-divider></p-divider>

  </div>
</div>
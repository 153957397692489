import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AddCustomerAddress, CreateCustomer, CustomerContact } from '../domain/Customer';
import { Address } from '../domain/Address';
import { ClientService } from './client.service';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private httpClient: HttpClient, private client: ClientService) { }

  getCustomers() {
    return this.httpClient.get<any>(`${environment.apiUrl}/e/customers/getByClient?clientId=${this.client.clientId}`);
  }

  create(customer: CreateCustomer) {
    return this.httpClient.post(`${environment.apiUrl}/customers/create`, customer);
  }

  delete(customerId: any) {
    return this.httpClient.post(`${environment.apiUrl}/customers/delete`, { id: customerId });
  }

  updateCustomer(customer: any) {
    var updateCustomerCommand = {
      id: customer.id,
      name: customer.name,
      customerNo: customer.customerNo,
      note: customer.note,
    };

    return this.httpClient.post(`${environment.apiUrl}/customers/update`, updateCustomerCommand);
  }

  searchCustomerFormatted(searchInput: string) {
    return this.httpClient.post(`${environment.apiUrl}/customers/search`, { searchInput }).pipe(
      map((result: any) => {
        let res = [];
        if (result && result.items) {
          for (let i = 0; i < result.items.length; i++) {
            if (result.items[i].addresses.length > 0) {
              for (let j = 0; j < result.items[i].addresses.length; j++) {
                res.push({
                  id: result.items[i].id,
                  name: result.items[i].name,
                  address: result.items[i].addresses[j],
                  contactPersons: result.items[i].contactPersons,
                  note: result.items[i].note,
                });
                for (let k = 0; k < result.items[i].contactPersons.length; k++) {
                  res.push({
                    id: result.items[i].id,
                    name: result.items[i].name,
                    contactPerson: result.items[i].contactPersons[k],
                    address: result.items[i].addresses[j],
                    contactPersons: result.items[i].contactPersons,
                    note: result.items[i].note,
                  });
                }
              }
            }
          }
        }
        return res;
      })
    );
  }

  addAddress(address: AddCustomerAddress) {
    return this.httpClient.post(`${environment.apiUrl}/customers/address/add`, address);
  }

  updateCustomerAddress(address: Address, customerId: any) {
    var updateAddressCommand = {
      address: address,
      customerId: customerId,
    };
    return this.httpClient.post(`${environment.apiUrl}/customers/address/update`, updateAddressCommand);
  }

  deleteAddress(addressId: string, customerId: string) {
    return this.httpClient.post(`${environment.apiUrl}/customers/address/delete`, { addressId, customerId });
  }

  addContactPerson(contact: CustomerContact, customerId: string) {
    return this.httpClient.post(`${environment.apiUrl}/customers/contactPersons/add`, { ...contact, customerId });
  }

  updateContactPerson(contactPerson: CustomerContact, customerId: string) {
    return this.httpClient.post(`${environment.apiUrl}/customers/contactPerson/update`, { contactPerson: contactPerson, customerId });
  }

  deleteContactPerson(contactId: string, customerId: string) {
    return this.httpClient.post(`${environment.apiUrl}/customers/contactPersons/delete`, { contactId, customerId });
  }
}

import { Component, OnInit } from '@angular/core';
import { StepperService } from '../stepper/stepper.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ShipmentService } from 'src/app/services/shipment.service';
import { MessageService } from 'primeng/api';
import { formatDate } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import { OBPermissions } from '@alicetms/auth.permissions';
import { Router } from '@angular/router';
import { ServicesService } from 'src/app/services/services.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-gls-booking',
  templateUrl: './gls-booking.component.html',
  styleUrls: ['./gls-booking.component.scss'],
})
export class GLSBooking implements OnInit {
  shipment = <any>{
    senderAddress: {},
    recipientAddress: {},
    collis: [],
  };
  loadingParcelShops: boolean = false;

  packageInfoForm = new FormGroup({
    waybillNo: new FormControl(null),
    weight: new FormControl(null, Validators.required),
    bookingType: new FormControl('', Validators.required),
  });

  glsPrivatePermission = OBPermissions.BookGLSPrivate;
  glsAbroadPermission = OBPermissions.BookGLSAbroad;
  externalCarrierServices: any;
  contactPersons: any;
  loading: boolean = false;
  weightLimit: any;

  addressForm = new FormGroup({
    name: new FormControl(this.shipment.recipientAddress.name),
    street: new FormControl(this.shipment.recipientAddress.street, Validators.required),
    contactPerson: new FormControl(),
    contactPhone: new FormControl(),
    contactEmail: new FormControl(null, Validators.email),
    zipCode: new FormControl(this.shipment.recipientAddress.zipCode, Validators.required),
    city: new FormControl(this.shipment.recipientAddress.city, Validators.required),
    countryCode: new FormControl(this.shipment.recipientAddress.countryCode, Validators.required),
    note: new FormControl(this.shipment.note, Validators.maxLength(40)),
    parcelShopId: new FormControl(this.shipment.parcelShopId),
    externalCarrierServices: new FormControl()
  });

  constructor(
    public stepperService: StepperService,
    private shipmentService: ShipmentService,
    private messageService: MessageService,
    private authService: AuthService,
    private router: Router,
    private servicesService: ServicesService,
    private translateService: TranslateService
  ) {
    this.setGLSValues();
    this.checkPackageInfoForm();
  }

  goBack() {
    this.stepperService.bookingChoice = null;
    this.router.navigate(['/']);
    this.stepperService.newShipment.collis = [];
  }

  setGLSValues() {
    this.stepperService.newShipment.services = null;
    this.shipment = this.stepperService.newShipment;
    this.shipment.bookingType = null;
    this.shipment.carrier = 'GLS';
    this.shipment.collis = [];
    this.shipment.collis.push({ type: 'GLS', quantity: 1, description: 'GLS' });
    let date = new Date();
    date.setDate(date.getDate() + 1);
    this.shipment.deliveryDate = formatDate(date, 'yyyy-MM-dd', 'en-US');
  }

  checkPermission(permission: any) {
    if (this.authService.hasPermission(permission)) {
      return true;
    }
    return false;
  }

  formErrors() {
    let invalidFields = [];
    if (this.packageInfoForm.invalid) {
      for (let key in this.packageInfoForm.controls) {
        if (this.packageInfoForm.controls[key].invalid) {
          switch (key) {
            case 'weight':
              invalidFields.push(this.translateService.instant('WEIGHT'));
              break;
          }
        }
      }
    }
    if (this.addressForm.invalid) {
      for (let key in this.addressForm.controls) {
        if (this.addressForm.controls[key].invalid) {
          switch (key) {
            case 'contactPerson':
              invalidFields.push(this.translateService.instant('RECEIVERNAME'));
              break;
            case 'contactPhone':
              invalidFields.push(this.translateService.instant('RECEIVERPHONE'));
              break;
            case 'contactEmail':
              invalidFields.push(this.translateService.instant('RECEIVEREMAIL'));
              break;
            case 'street':
              if (this.shipment.bookingType != 'PARCELSHOP') {
                invalidFields.push(this.translateService.instant('ADDRESS'));
              } else {
                invalidFields.push(this.translateService.instant('PARCELSHOPNAME') + this.translateService.instant('ADDRESS'));
              }
              break;
            case 'parcelShopId':
              invalidFields.push(this.translateService.instant('PARCELSHOPNAME'));
              break;
            case 'countryCode':
              invalidFields.push(this.translateService.instant('COUNTRYCODE'));
              break;
            case 'note':
              invalidFields.push(this.translateService.instant('NOTEABOVEMAXLENGTH'));
              break;
          }
        }
      }
    }

    return `${invalidFields.join(', ')}`;
  }

  checkPackageInfoForm() {
    this.packageInfoForm.valueChanges.subscribe((val) => {
      if (val.weight) {
        this.shipment.collis[0].weight = val.weight;
      }

      this.shipment.reference1 = val.waybillNo;

      if (val.bookingType != this.shipment.bookingType) {
        this.addressForm.reset();
        this.shipment = this.stepperService.newShipment;
        this.shipment.recipientAddress = {};
        this.shipment.externalCarrierServices = null;
        if (val.bookingType == 'PARCELSHOP') {
          this.addressForm.controls.parcelShopId.addValidators(Validators.required);
          this.addressForm.controls.parcelShopId.updateValueAndValidity();
          this.addressForm.controls.city.disable({ emitEvent: false });
          this.addressForm.controls.zipCode.disable({ emitEvent: false });
          this.addressForm.controls.countryCode.disable({ emitEvent: false });
          this.addressForm.controls.contactPerson.addValidators(Validators.required);
          this.addressForm.controls.contactPhone.addValidators(Validators.required);
          this.addressForm.controls.contactPhone.setErrors({ required: true });
          this.addressForm.controls.contactEmail.addValidators(Validators.required);
          this.addressForm.controls.contactEmail.setErrors({ required: true });

        } else {
          this.addressForm.controls.parcelShopId.removeValidators(Validators.required);
          this.addressForm.controls.parcelShopId.updateValueAndValidity();
          this.addressForm.controls.city.enable({ emitEvent: false });
          this.addressForm.controls.zipCode.enable({ emitEvent: false });
          this.addressForm.controls.countryCode.enable({ emitEvent: false });
          this.addressForm.controls.contactPerson.removeValidators(Validators.required);
          this.addressForm.controls.contactPerson.updateValueAndValidity();
          this.addressForm.controls.contactPhone.removeValidators(Validators.required);
          this.addressForm.controls.contactPhone.updateValueAndValidity();
          this.addressForm.controls.contactEmail.removeValidators(Validators.required);
          this.addressForm.controls.contactEmail.updateValueAndValidity();
          this.shipment.parcelShop = null;
        }
        this.shipment.bookingType = val.bookingType;
        this.setServicesAndWeightValidatorByBookingType();
      }
    });
  }

  ngOnInit(): void {
  }

  checkFormValidity() {
    if (!this.packageInfoForm.valid || !this.addressForm.valid) {
      this.messageService.add({
        severity: 'warn',
        summary: this.translateService.instant('MESSAGES.MISSINGFIELDS'),
        detail: this.formErrors()
      });

      if (this.packageInfoForm.controls.bookingType.value == 'PARCELSHOP') {
        if (!this.addressForm.controls.contactPerson.valid) {
          this.addressForm.controls.contactPerson.markAsDirty();
        }
        if (!this.addressForm.controls.contactPhone.valid) {
          this.addressForm.controls.contactPhone.markAsDirty();
          this.addressForm.controls.contactPhone.setErrors({ required: true });
        }
        if (!this.addressForm.controls.contactEmail.valid) {
          this.addressForm.controls.contactEmail.markAsDirty();
          this.addressForm.controls.contactEmail.setErrors({ required: true });
        }

        if (!this.packageInfoForm.controls.weight.valid) {
          this.packageInfoForm.controls.weight.markAsDirty();
        }
        if (!this.addressForm.controls.parcelShopId.valid) {
          this.addressForm.controls.parcelShopId.markAsDirty();
        }
        if (!this.addressForm.controls.street.valid) {
          this.addressForm.controls.street.markAsDirty();
        }
        if (!this.addressForm.controls.countryCode.valid) {
          this.addressForm.controls.countryCode.markAsDirty();
        }

      } else {
        if (!this.addressForm.controls.street.valid) {
          this.addressForm.controls.street.markAsDirty();
        }
      }
      if (!this.packageInfoForm.controls.weight.valid) {
        this.packageInfoForm.controls.weight.markAsDirty();
      }
      if (!this.addressForm.controls.countryCode.valid) {
        this.addressForm.controls.countryCode.markAsDirty();
      }
      if (!this.addressForm.controls.note.valid) {
        this.addressForm.controls.note.markAsDirty();
      }
      return false;
    }

    return true;
  }

  bookShipment() {
    if (!this.checkFormValidity()) {
      return;
    }

    this.loading = true;
    if (this.shipment.service) {
      this.shipment.services = [];
      this.shipment.services.push(this.shipment.service.code);
      this.shipment.service = null;
    }

    this.shipmentService.createShipment(this.shipment).subscribe({
      next: (result: any) => {
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('APPROVED'),
          detail: this.translateService.instant('MESSAGES.BOOKINGCREATED'),
        });
        this.packageInfoForm.reset();
        this.setGLSValues();
        this.loading = false;
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: this.translateService.instant('ERROR'), detail: this.translateService.instant('BOOKINGERROR') });
        this.loading = false;
      },
      complete: () => { },
    });
  }

  setServicesAndWeightValidatorByBookingType() {
    this.servicesService.getExternalServicesByCarrier(this.shipment.carrier, this.shipment.bookingType).subscribe((res: any) => {
      this.externalCarrierServices = res.services;
    });
    switch (this.shipment.bookingType) {
      case 'PARCELSHOP':
        this.updateWeightValidator(20);
        break;
      case 'BUSINESS':
        this.updateWeightValidator(30);
        break;
      case 'PRIVATE':
        this.updateWeightValidator(30);
        break;
      case 'ABROAD':
        this.updateWeightValidator(50);
        break;
    }
  }

  updateWeightValidator(maxValue: any) {
    this.packageInfoForm.controls.weight.clearValidators();
    this.weightLimit = maxValue;
    this.packageInfoForm.controls.weight.setValidators([Validators.required, Validators.max(maxValue), Validators.min(1)]);
    this.packageInfoForm.controls.weight.updateValueAndValidity();
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ADRService {

    constructor(private httpClient: HttpClient) { }

    searchADR(searchInput: string) {
        return this.httpClient.post(`${environment.apiUrl}/adr/search`, { searchInput });
    }

}


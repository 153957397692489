<div>
    <h4 class="primColor">{{'DANGEROUSGOODS' | translate}}</h4>
    <div class="p-fluid p-formgrid grid" style="align-items:flex-start;">
        <div class="field-checkbox col-1 md:col-2">
            <p-checkbox name="colliAdrField" inputId="colliAdr" [(ngModel)]="includeDangerousGoods"
                [binary]="true"></p-checkbox>
            <label for="colliAdr">{{'ADR' | translate}}</label>
        </div>

        <div class="col-12 md:col-9">
            <span class="p-float-label">
                <p-autoComplete [disabled]="!includeDangerousGoods" [suggestions]="foundAdrs" [minLength]="2"
                    inputId="adr" (completeMethod)="adrSearch($event)" [showEmptyMessage]="true"
                    [(ngModel)]="shipment.dangerousGoods" emptyMessage="{{'NOADRFOUND' | translate}}" [multiple]="true"
                    [field]="waybillShortString">
                    <ng-template let-adr pTemplate="adr">
                        <div class="adr-item">
                            <span>{{adr.waybillString.substring(0, 8)}}</span><span
                                class="lightGrayText">{{adr.waybillString.substring(8, 80)}}</span>
                        </div>
                    </ng-template>

                </p-autoComplete>
                <label for="adr">{{'SEARCHADR' | translate}}</label>
            </span>
        </div>

        <div class="col-12 md:col-1" *ngIf="shipment.dangerousGoods && shipment.dangerousGoods.length > 0">
            <button pButton label="{{'CLEAR' | translate}}" (click)="clearDangerousGoods()"></button>
        </div>
    </div>

    <p-divider></p-divider>

    <div class="bottomBar">
        <button pButton pRipple label="{{'GENERIC.PREVIOUS' | translate}}" *ngIf="stepperService.activeIndex > 0"
            (click)="stepperService.stepPrev()"></button>
        <button pButton pRipple label="{{'GENERIC.NEXT' | translate}}" (focus)="stepperService.stepNext()"
            (click)="stepperService.stepNext()"></button>
    </div>
</div>
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { MenuItem } from "primeng/api";
import { Title } from "@angular/platform-browser";

@Injectable()
export class AppBreadcrumbService {
    constructor(private title: Title) {}

    private itemsSource = new Subject<MenuItem[]>();

    itemsHandler = this.itemsSource.asObservable();

    setItems(items: MenuItem[]) {
        this.itemsSource.next(items);

        let title = "OnlineBook";

        items.forEach((item) => {
            title = `${title} / ${item.label}`;
        });

        this.title.setTitle(title);
    }
}

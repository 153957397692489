import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Shipment } from 'src/app/domain/Shipment';
import { ShipmentService } from 'src/app/services/shipment.service';
import { StepperService } from 'src/app/pages/Shared/stepper/stepper.service';
import { ClientService } from 'src/app/services/client.service';
import { AddCustomerAddress, CreateCustomer } from 'src/app/domain/Customer';
import { CustomerService } from 'src/app/services/customer.service';
import { Router } from '@angular/router';
import { OBPermissions } from '@alicetms/auth.permissions';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-booking-info',
  templateUrl: './booking-info.component.html',
  styleUrls: ['./booking-info.component.scss'],
})
export class BookingInfoComponent implements OnInit {
  @Input() shipment = <Shipment>{};

  loading: boolean = false;

  BookEXW = OBPermissions.BookEXW;

  bookingInfoForm = new FormGroup({
    reference1: new FormControl(),
    reference2: new FormControl(),
    reference3: new FormControl(),
    note: new FormControl(this.shipment.note, Validators.maxLength(200)),
  });

  constructor(
    public stepperService: StepperService,
    private shipmentService: ShipmentService,
    private messageService: MessageService,
    private clientService: ClientService,
    private customerService: CustomerService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private translateService: TranslateService
  ) {
    this.shipment = this.stepperService.newShipment;
  }

  ngOnInit(): void {
    this.bookingInfoForm.valueChanges.subscribe((value) => {
      this.shipment.note = value.note;
      this.shipment.reference1 = value.reference1;

      if (value.reference2) {
        this.shipment.reference2 = value.reference2;
      }
      if (value.reference3) {
        this.shipment.reference3 = value.reference3;
      }
    });

    if (this.shipment.accountId) {
      if (!this.shipment.reference2) {
        var account = this.clientService.clientAccounts.find((x) => x.id == this.shipment.accountId);
        this.shipment.reference2 = account.name;
      }
      this.bookingInfoForm.controls.reference2.disable();
    }
    if (this.shipment.note) {
      this.bookingInfoForm.patchValue({
        note: this.shipment.note,
      });
    }
    if (this.shipment.reference1) {
      this.bookingInfoForm.patchValue({
        reference1: this.shipment.reference1,
      });
    }
    if (this.shipment.reference2) {
      this.bookingInfoForm.patchValue({
        reference2: this.shipment.reference2,
      });
    }
    if (this.shipment.reference3) {
      this.bookingInfoForm.patchValue({
        reference3: this.shipment.reference3,
      });
    }
  }

  checkShipmentValidity() {
    if (!this.shipment.senderAddress.street || this.shipment.senderAddress.street === '' || this.shipment.senderAddress.street === null || !this.shipment.senderAddress.name) {
      this.messageService.add({ severity: 'error', summary: this.translateService.instant('ERROR'), detail: this.translateService.instant('MESSAGES.SENDERADRESSMUSTBEFILLED') });
      return false;
    }
    if (!this.shipment.recipientAddress.street || this.shipment.recipientAddress.street === '' || this.shipment.recipientAddress.street === null || !this.shipment.recipientAddress.name) {
      this.messageService.add({ severity: 'error', summary: this.translateService.instant('ERROR'), detail: this.translateService.instant('MESSAGES.RECEIVERADRESSMUSTBEFILLED') });
      return false;
    }
    if (this.shipment.collis.length === 0) {
      this.messageService.add({ severity: 'error', summary: this.translateService.instant('ERROR'), detail: this.translateService.instant('MESSAGES.ATLEASTONECOLLI') });
      return false;
    }
    if (!this.shipment.recipientAddress.date || this.shipment.recipientAddress.date === '') {
      this.messageService.add({ severity: 'error', summary: this.translateService.instant('ERROR'), detail: this.translateService.instant('MESSAGES.RECEIVERDATEMUSTBEFILLED') });
      return false;
    }
    return true;
  }

  createBooking() {
    var isValid = this.checkShipmentValidity();
    if (!isValid) return;

    if (this.clientService.clientAccounts && this.clientService.clientAccounts.length > 0) {
      if (!this.shipment.accountId) {
        this.messageService.add({ severity: 'error', summary: this.translateService.instant('ERROR'), detail: this.translateService.instant('MESSAGES.ACCOUNTMUSTBEFILLED') });
        return;
      }
    }

    let notes = [this.shipment.note];

    if (this.stepperService.senderCustomer?.note) {
      notes.push(this.stepperService.senderCustomer.note);
    }
    if (this.stepperService.recipientCustomer?.note) {
      notes.push(this.stepperService.recipientCustomer.note);
    }
    this.shipment.note = notes.join('. ');

    this.loading = true;
    this.checkSaveAddresses();
    if (!this.shipment.carrier) {
      this.shipment.carrier = 'Internal'; // SET Carrier to Internal if not set
    }

    if (this.shipment.senderAddress.sendLabel && this.shipment.senderAddress.sendLabel === true && this.shipment.senderAddress.contactEmail) {
      this.shipment.sendLabel = true;
    }

    this.shipmentService.createShipment(this.shipment).subscribe({
      next: (result: any) => {
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('APPROVED'),
          detail: this.translateService.instant('MESSAGES.BOOKINGCREATED'),
        });
        this.shipment = <Shipment>{
          senderAddress: {},
          recipientAddress: {},
          collis: [],
          dangerousGoods: [],
        };
        this.stepperService.newShipment = null;
        if (this.clientService.clientAccounts && this.clientService.clientAccounts.length > 0) {
          if (this.clientService.clientAccounts.length === 1) {
            // Set Account if only one
            this.shipment.accountId = this.clientService.clientAccounts[0].id;
            this.shipment.reference2 = this.clientService.clientAccounts[0].name;
          }
        }

        if (this.clientService.clientAddress) {
          this.shipment.senderAddress = this.clientService.clientAddress;
        }

        if (this.clientService.hasClientPermission(this.BookEXW)) {
          if (this.clientService.clientPayers && this.clientService.clientPayers.length > 0) {
            if (this.clientService.clientPayers.length === 1) {
              // Set Payer if only one
              if (this.clientService.clientPayers[0].id) {
                this.shipment.clientPayerId = this.clientService.clientPayers[0].id;
              }
            }
          }
        }
        this.stepperService.newShipment = this.shipment;
        this.stepperService.senderCustomer = null;
        this.stepperService.recipientCustomer = null;

        this.loading = false;

        this.confirmationService.confirm({
          message: this.translateService.instant('BOOKINGCREATED'),
          header: ' ',
          icon: 'pi pi-check-circle',
          // dismissableMask: true, // If clicking outside of dialog should close it
          accept: () => {
            this.stepperService.resetBookingDate = true;
            this.stepperService.goToStart();
          },
          reject: () => {
            this.router.navigate(['/shipments']);
          },
          acceptLabel: this.translateService.instant('CREATEMORE'),
          rejectLabel: this.translateService.instant('CLOSE'),
          rejectIcon: 'pi pi-arrow-right',
        });
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: this.translateService.instant('ERROR'), detail: this.translateService.instant('BOOKINGERROR') });
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  checkSaveAddresses() {
    if (!this.shipment) return;
    const { senderAddress, recipientAddress } = this.shipment;

    if (senderAddress?.saveAddress) {
      if (this.stepperService.senderCustomer && this.stepperService.senderCustomer.id) {
        var addressToSave = <AddCustomerAddress>{
          city: senderAddress.city,
          countryCode: senderAddress.countryCode,
          customerId: this.stepperService.senderCustomer.id,
          street: senderAddress.street,
          zipCode: senderAddress.zipCode,
        };
        this.customerService.addAddress(addressToSave).subscribe({
          next: (result: any) => {
            this.messageService.add({ severity: 'success', summary: this.translateService.instant('APPROVED'), detail: this.translateService.instant('MESSAGES.SENDERADRESSSAVED') });
            this.stepperService.senderCustomer = null;
            this.stepperService.recipientCustomer = null;
          },
          error: (error) => {
            this.stepperService.senderCustomer = null;
            this.stepperService.recipientCustomer = null;
          },
        });
      } else {
        var newCustomer: CreateCustomer = {
          name: senderAddress.name,
          city: senderAddress.city,
          countryCode: senderAddress.countryCode,
          street: senderAddress.street,
          latitude: senderAddress.latitude,
          longitude: senderAddress.longitude,
          zipCode: senderAddress.zipCode,
          customerNo: '',
          registrationNumber: senderAddress.name,
          contactPhone: senderAddress.contactPhone,
          contactEmail: senderAddress.contactEmail,
          contactName: senderAddress.contactPerson,
        };

        this.customerService.create(newCustomer).subscribe({
          next: (result: any) => {
            this.messageService.add({ severity: 'success', summary: this.translateService.instant('APPROVED'), detail: this.translateService.instant('MESSAGES.SENDERADRESSSAVED') });
            this.stepperService.senderCustomer = null;
            this.stepperService.recipientCustomer = null;
          },
          error: (error) => {
            this.stepperService.senderCustomer = null;
            this.stepperService.recipientCustomer = null;
          },
        });
      }
    }

    if (recipientAddress?.saveAddress) {
      if (this.stepperService.recipientCustomer && this.stepperService.recipientCustomer.id) {
        var addressToSave = <AddCustomerAddress>{
          city: recipientAddress.city,
          countryCode: recipientAddress.countryCode,
          customerId: this.stepperService.recipientCustomer.id,
          street: recipientAddress.street,
          zipCode: recipientAddress.zipCode,
        };

        this.customerService.addAddress(addressToSave).subscribe({
          next: (result: any) => {
            this.messageService.add({ severity: 'success', summary: this.translateService.instant('APPROVED'), detail: this.translateService.instant('MESSAGES.RECEIVERADRESSSAVED') });
            this.stepperService.senderCustomer = null;
            this.stepperService.recipientCustomer = null;
          },
          error: (error) => {
            this.stepperService.senderCustomer = null;
            this.stepperService.recipientCustomer = null;
          },
        });
      } else {
        var newCustomer: CreateCustomer = {
          name: recipientAddress.name,
          city: recipientAddress.city,
          countryCode: recipientAddress.countryCode,
          street: recipientAddress.street,
          latitude: recipientAddress.latitude,
          longitude: recipientAddress.longitude,
          zipCode: recipientAddress.zipCode,
          customerNo: '',
          registrationNumber: recipientAddress.name,
          contactPhone: recipientAddress.contactPhone,
          contactEmail: recipientAddress.contactEmail,
          contactName: recipientAddress.contactPerson,
        };

        this.customerService.create(newCustomer).subscribe({
          next: (result: any) => {
            this.messageService.add({ severity: 'success', summary: this.translateService.instant('APPROVED'), detail: this.translateService.instant('MESSAGES.RECEIVERADRESSSAVED') });
            this.stepperService.senderCustomer = null;
            this.stepperService.recipientCustomer = null;
          },
          error: (error) => {
            this.stepperService.senderCustomer = null;
            this.stepperService.recipientCustomer = null;
          },
        });
      }
    }
  }
}

import { formatDate } from "@angular/common";
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export class DateValidator {
    // GENERAL VALIDATORS
    static TimeStartTimeEndValidator(control: AbstractControl): { [key: string]: boolean } | null {
        const timeStart = control.get('timeStart')?.value;
        const timeEnd = control.get('timeEnd')?.value;

        if (timeStart && timeEnd) {

            if (timeStart > timeEnd) {
                return { 'invalidTimeEnd': true };
            }
        }

        return null;
    }

    static GreaterThanToday(control: FormControl): ValidationErrors | null {
        let formattedToday = formatDate(Date.now(), "dd/MM/yyyy", "da-DK");
        var todayDateArray = formattedToday.split("/");

        var convertedToday = parseDateArrayToDate(todayDateArray);

        if (control.value == null) {
            return null;
        }
        let controlDateArray = control.value.split("/");
        var convertedControlDate = parseDateArrayToDate(controlDateArray);

        if (convertedToday >= convertedControlDate) {
            return { "LessThanToday": true }; // return error
        }
        return null;
    }

    static GreaterThanOrEqualToday(control: FormControl): ValidationErrors | null {
        let formattedToday = formatDate(Date.now(), "dd/MM/yyyy", "da-DK");
        var todayDateArray = formattedToday.split("/");

        var convertedToday = parseDateArrayToDate(todayDateArray);

        if (control.value == null) {
            return null;
        }
        let controlDateArray = control.value.split("/");
        var convertedControlDate = parseDateArrayToDate(controlDateArray);

        if (convertedToday > convertedControlDate) {
            return { "LessThanOrEqualToToday": true }; // return error
        }
        return null;
    }

    static GreaterThanOrEqualGivenDate(date: any): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            var pickupDate = date.split("/");

            var convertedPickupDate = parseDateArrayToDate(pickupDate);

            if (control.value == null) {
                return null;
            }
            let controlDateArray = control.value.split("/");
            var convertedControlDate = parseDateArrayToDate(controlDateArray);

            if (convertedPickupDate > convertedControlDate) {
                return { "LessThanOrEqualToGivenDate": true }; // return error
            }
            return null;
        }
    }

    static GreaterThanGivenDate(date: any): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            var pickupDate = date.split("/");
            var convertedPickupDate = parseDateArrayToDate(pickupDate);

            if (control.value == null) {
                return null;
            }
            let controlDateArray = control.value.split("/");
            var convertedControlDate = parseDateArrayToDate(controlDateArray);

            if (convertedPickupDate >= convertedControlDate) {
                return { "LessThanGivenDate": true }; // return error
            }
            return null;
        }
    }
    // PICKUP DATE VALIDATORS


    static PickupDateGreaterThanToday(control: AbstractControl): ValidationErrors | null {
        const pickupDate = control.get('pickupDate')?.value;
        if (pickupDate) {
            let formattedToday = formatDate(Date.now(), "dd/MM/yyyy", "da-DK");
            var todayDateArray = formattedToday.split("/");

            var convertedToday = parseDateArrayToDate(todayDateArray);

            if (pickupDate == null) {
                return null;
            }
            let controlDateArray = pickupDate.split("/");
            var convertedControlDate = parseDateArrayToDate(controlDateArray);

            if (convertedToday >= convertedControlDate) {
                return { "PickupDate_LessThanToday": true }; // return error
            }
        }
        return null;

    }

    static PickupDateGreaterThanOrEqualToday(control: AbstractControl): ValidationErrors | null {
        const pickupDate = control.get('pickupDate')?.value;
        if (pickupDate) {
            let formattedToday = formatDate(Date.now(), "dd/MM/yyyy", "da-DK");
            var todayDateArray = formattedToday.split("/");

            var convertedToday = parseDateArrayToDate(todayDateArray);

            if (pickupDate == null) {
                return null;
            }
            let controlDateArray = pickupDate.split("/");
            var convertedControlDate = parseDateArrayToDate(controlDateArray);

            if (convertedToday > convertedControlDate) {
                return { "PickupDate_LessThanOrEqualToToday": true }; // return error
            }
        }
        return null;

    }

    static PickupTimeValidator(control: AbstractControl): { [key: string]: boolean } | null {
        const pickupTimeStart = control.get('pickupTimeStart')?.value;
        const pickupTimeEnd = control.get('pickupTimeEnd')?.value;

        if (pickupTimeStart && pickupTimeEnd) {

            if (pickupTimeStart > pickupTimeEnd) {
                return { 'invalidPickupTime': true };
            }
        }

        return null;
    }

    static PickupTimeStartParseValidator(control: AbstractControl): { [key: string]: boolean } | null {
        const pickupTimeStart = control.get('pickupTimeStart')?.value;

        if (pickupTimeStart) {
            if (pickupTimeStart > "23:59") {
                return { 'PickupTimeStart_Invalid_Parsing': true };
            }
        }

        return null;
    }


    static PickupTimeEndParseValidator(control: AbstractControl): { [key: string]: boolean } | null {
        const pickupTimeEnd = control.get('pickupTimeEnd')?.value;

        if (pickupTimeEnd) {
            if (pickupTimeEnd > "23:59") {
                return { 'PickupTimeEnd_Invalid_Parsing': true };
            }
        }

        return null;
    }

    // DELIVERY DATE VALIDATOR
    static DeliveryDateGreaterThanToday(control: AbstractControl): ValidationErrors | null {
        const deliveryDate = control.get('deliveryDate')?.value;
        if (deliveryDate) {
            let formattedToday = formatDate(Date.now(), "dd/MM/yyyy", "da-DK");
            var todayDateArray = formattedToday.split("/");

            var convertedToday = parseDateArrayToDate(todayDateArray);

            if (deliveryDate == null) {
                return null;
            }
            let controlDateArray = deliveryDate.split("/");
            var convertedControlDate = parseDateArrayToDate(controlDateArray);

            if (convertedToday >= convertedControlDate) {
                return { "DeliveryDate_LessThanToday": true }; // return error
            }
        }
        return null;

    }


    static DeliveryTimeValidator(control: AbstractControl): { [key: string]: boolean } | null {
        const deliveryTimeStart = control.get('deliveryTimeStart')?.value;
        const deliveryTimeEnd = control.get('deliveryTimeEnd')?.value;

        if (deliveryTimeStart && deliveryTimeEnd) {

            if (deliveryTimeStart > deliveryTimeEnd) {
                return { 'invalidDeliveryTime': true };
            }
        }

        return null;
    }

    static DeliveryTimeStartParseValidator(control: AbstractControl): { [key: string]: boolean } | null {
        const deliveryTimeStart = control.get('deliveryTimeStart')?.value;

        if (deliveryTimeStart) {
            if (deliveryTimeStart > "23:59") {
                return { 'DeliveryTimeStart_Invalid_Parsing': true };
            }
        }

        return null;
    }

    static DeliveryTimeEndParseValidator(control: AbstractControl): { [key: string]: boolean } | null {
        const deliveryTimeEnd = control.get('deliveryTimeEnd')?.value;

        if (deliveryTimeEnd) {
            if (deliveryTimeEnd > "23:59") {
                return { 'DeliveryTimeEnd_Invalid_Parsing': true };
            }
        }

        return null;
    }

}
// Helper Function
function parseDateArrayToDate(dateArray: string[]): Date {
    return new Date(dateArray[1] + "/" + dateArray[0] + "/" + dateArray[2]);
}
<div style="padding-top:0px;" class="col-12 md:col-12 grid">
    <div *ngIf="shipment.bookingType">
        <h4 class="text-center" style="color:var(--primary-color); padding-bottom:4px;">{{'RECEIVER' | translate}}
        </h4>
        <form [formGroup]="addressForm">
            <div class="p-fluid p-formgrid grid row-gap-2">
                <div class="col-12 md:col-12">
                    <span class="p-float-label">
                        <p-autoComplete [suggestions]="foundCustomers" [minLength]="3" formControlName="name" #name
                            [delay]="500" inputId="name" name="nameField" autocomplete="anyrandomstring"
                            showEmptyMessage="true" emptyMessage="{{'NORESULTS' | translate}}"
                            (completeMethod)="searchCustomer($event)" showClear="true" [field]="formattedCustomerName"
                            (onSelect)="setCustomer($event)">
                            <ng-template let-customer pTemplate="customer">
                                <div class="customer-item">
                                    <div>
                                        {{customer.name}}
                                        <span *ngIf="customer.address" class="grayedText">
                                            {{customer.address.street}}, {{customer.address.zipCode}},
                                            {{customer.address.city}}</span>
                                        <span *ngIf="customer.contactPerson" style="font-size:12px; color:gray;"> |
                                            {{'CUSTOMER.FORM.CONTACTPERSON' | translate}}
                                            {{customer.contactPerson.name}}
                                            <span *ngIf="customer.contactPerson.email">,
                                                {{customer.contactPerson.email}}</span>
                                            <span *ngIf="customer.contactPerson.phone">,
                                                {{customer.contactPerson.phone}}</span></span>
                                    </div>
                                </div>
                            </ng-template>
                        </p-autoComplete>
                        <label for="name">{{'NAME' | translate}} {{'SEARCHFIELD' | translate}}</label>

                    </span>
                </div>

                <div class="col-12 md:col-4">
                    <span class="p-float-label">
                        <p-autoComplete [suggestions]="foundAddresses" [minLength]="3" formControlName="street"
                            name="addressField" showEmptyMessage="true" emptyMessage="{{'NORESULTS' | translate}}"
                            [delay]="500" inputId="addressStreet" #addressStreet autocomplete="anyrandomstring"
                            (completeMethod)="searchAddress($event)" showClear="true" (onClear)="onClearAddress()"
                            (onSelect)="setAddress($event)" [field]="formattedStreetName">

                            <ng-template let-address pTemplate="address">
                                <div class="address-item">
                                    <div>
                                        <img src="assets/demo/flags/flag_placeholder.png"
                                            [class]="'flag flag-' + address.countryCode.toLowerCase()" />
                                        {{address.street}},
                                        <span class="grayedText"> {{address.zipCode}} {{address.city}}</span>
                                        <span *ngIf="isDev()">, {{'CUSTOMER.FORM.VALIDATEDBY' | translate}} :
                                            {{address.validatedBy}}</span>
                                    </div>
                                </div>
                            </ng-template>
                        </p-autoComplete>
                        <label for="addressStreet"><span *ngIf="shipment.bookingType == 'PARCELSHOP'">{{'PARCELSHOPNAME'
                                | translate}}
                            </span> {{'ADDRESS' | translate}} {{'SEARCHFIELD' | translate}}</label>
                    </span>
                </div>

                <div class="col-12 md:col-2">
                    <span class="p-float-label">
                        <input formControlName="zipCode" pInputText id="zipCode" #zipCode name="test"
                            autocomplete="anyrandomstring" [tabindex]="validatedAddress == true ? '-1' : '0'"
                            type="text" />
                        <label for="zipCode">{{'ZIPCODE' | translate}}</label>
                    </span>
                </div>

                <div class="col-12 md:col-3">
                    <span class="p-float-label">
                        <input formControlName="city" [tabindex]="validatedAddress == true ? '-1' : '0'" pInputText
                            name="test" autocomplete="anyrandomstring" id="city" #city type="text" />
                        <label for="city">{{'CITY' | translate}}</label>
                    </span>
                </div>

                <div class="col-12 md:col-3">
                    <span class="p-float-label">
                        <p-dropdown [options]="countries" formControlName="countryCode" optionValue="code"
                            placeholder="Land" optionLabel="name">
                            <ng-template pTemplate="selectedItem">
                                <div class="country-item country-item-value"
                                    *ngIf="this.shipment.recipientAddress.countryCode">
                                    <div><img src="assets/demo/flags/flag_placeholder.png"
                                            [class]="'flag flag-' + this.shipment.recipientAddress.countryCode.toLowerCase()" />
                                        {{ 'COUNTRIES.'+this.shipment.recipientAddress.countryCode | translate }}
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template let-country pTemplate="item">
                                <div class="country-item">
                                    <img src="assets/demo/flags/flag_placeholder.png"
                                        [class]="'flag flag-' + country.code.toLowerCase()" />
                                    {{ 'COUNTRIES.'+country.code | translate }}
                                </div>
                            </ng-template>
                        </p-dropdown>
                        <label for="countryCode">{{'COUNTRY' | translate}}</label>
                    </span>
                </div>

                <div class="col-12 md:col-12" *ngIf="shipment.bookingType =='PARCELSHOP'">
                    <span class="p-float-label">
                        <p-dropdown inputId="parcelShops" [options]="foundParcelShops"
                            placeholder="{{'PARCELSHOPNAME' | translate}}" formControlName="parcelShopId"
                            optionValue="number" [loading]="loadingParcelShops"
                            emptyMessage="{{'PARCELSHOPSEMPTYMESSAGE' | translate}}"
                            [dropdownIcon]="loadingParcelShops ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"
                            [optionLabel]="formattedParcelShopName" name="test" autocomplete="anyrandomstring">
                            <ng-template pTemplate="parcelShop">
                                <div class="flex align-items-center gap-2">
                                    <div>{{ parcelShop.streetname }}</div>
                                </div>
                            </ng-template>
                            <ng-template let-parcelShop pTemplate="parcelShop">
                                <div class="flex align-items-center gap-2">
                                    <div *ngIf="parcelShop.number" style="color:gray;">{{parcelShop.number}}: </div>
                                    <div>{{parcelShop.companyName}}, {{ parcelShop.streetname }},
                                        {{parcelShop.cityName}}, {{parcelShop.zipCode}}
                                        {{parcelShop.countryCodeISO3166A2}}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                        <label for="parcelShops">{{'PARCELSHOPNAME' | translate}}</label>
                    </span>
                </div>

                <div class="col-12 md:col-4">
                    <span class="p-float-label">
                        <p-autoComplete [dropdown]="contactPersons != null && contactPersons != []"
                            emptyMessage="{{'NOCONTACTPERSONONCUSTOMER' | translate}}" showEmptyMessage="true"
                            [suggestions]="filteredContactPersons" (completeMethod)="filterContactPersons($event)"
                            field="name" formControlName="contactPerson"
                            (onSelect)="onContactPersonSelect($event)"></p-autoComplete>
                        <label for="contactPerson" *ngIf="shipment.bookingType != 'PARCELSHOP'">{{'CONTACTPERSON' |
                            translate}}</label>
                        <label for="contactPerson" *ngIf="shipment.bookingType == 'PARCELSHOP'">{{'FULLNAME' |
                            translate}}</label>
                    </span>
                </div>


                <div class="col-12 md:col-4">
                    <span class="p-float-label">
                        <input formControlName="contactPhone" pInputText id="contactPhone" #contactPhone type="text" />
                        <label for="contactPhone"><span *ngIf="shipment.bookingType == 'PARCELSHOP'">{{'RECEIVER' |
                                translate}}
                            </span>{{'MOBILE' | translate}}</label>
                    </span>
                </div>

                <div class="col-12 md:col-4">
                    <span class="p-float-label">
                        <input formControlName="contactEmail" pInputText id="contactEmail" #contactEmail type="text" />
                        <label for="contactEmail"><span *ngIf="shipment.bookingType == 'PARCELSHOP'">{{'RECEIVER' |
                                translate}}
                            </span>{{'EMAIL' | translate}}</label>
                    </span>
                </div>

                <div class="col-12 md:col-12">
                    <span class="p-float-label">
                        <input formControlName="note" pInputText id="note" type="text" />
                        <label for="note">{{'NOTE' | translate}}</label>
                    </span>
                    <div *ngIf="shipment.bookingType && shipment.bookingType == 'GLS'">
                        <small
                            [ngStyle]="addressForm.controls['note'].value?.length > 40 && {'color': '#FC6161'} || addressForm.controls['note'].value?.length <= 40 && {'color': 'green'}"
                            style="color:green; margin-left:6px;" class="p-invalid">{{addressForm.controls['note'].value
                            != null ?
                            addressForm.controls['note'].value?.length : 0}} /
                            40
                        </small>
                    </div>
                </div>

                <div class="col-12 md:col-12 grid servicesList"
                    style="padding-top:0px; padding-bottom:0px; margin-top:2px;"
                    *ngIf="shipment.bookingType && services && services.length > 0 && !multiSelectServices">
                    <div class="col-12 md:col-4" style="padding-bottom:0px;">
                        <h4 style="color:var(--primary-color); line-height:0px; margin-top:8px;">{{'SERVICES' |
                            translate}}</h4>
                    </div>
                    <div class="col-4 md:col-4">
                        <p-radioButton [(ngModel)]="shipment.service" [value]="null"
                            formControlName="externalCarrierServices"
                            label="{{'NOEXTRASERVICES' | translate}}"></p-radioButton>
                    </div>
                    <div *ngFor="let service of services" class="col-4 md:col-4">
                        <p-radioButton name="externalCarrierServices" [(ngModel)]="shipment.service" [value]="service"
                            formControlName="externalCarrierServices"
                            [label]="service.code.toUpperCase() | translate"></p-radioButton>
                    </div>
                </div>

                <div class="col-12 md:col-12 grid servicesList"
                    style="padding-top:0px; padding-bottom:0px; margin-top:2px;"
                    *ngIf="shipment.bookingType && services && services.length > 0 && multiSelectServices">
                    <div class="col-12 md:col-4" style="padding-bottom:0px;">
                        <h4 style="color:var(--primary-color); line-height:0px; margin-top:8px;">
                            {{'SERVICES' |translate}}</h4>
                    </div>
                    <div class="col-4 md:col-4">
                        <p-radioButton formControlName="externalCarrierServices" (onClick)="removeAllServices($event)"
                            label="{{'NOEXTRASERVICES' | translate}}"></p-radioButton>
                    </div>
                    <div *ngFor="let service of services" class="col-4 md:col-4">
                        <p-checkbox name="externalCarrierServices" [(ngModel)]="shipment.externalCarrierServices"
                            (onChange)="externalServiceChange($event)" [value]="service"
                            formControlName="externalCarrierServices"
                            [label]="service.code.toUpperCase() | translate"></p-checkbox>
                    </div>
                </div>

            </div>
        </form>
    </div>
</div>
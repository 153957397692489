import { Component, Input, OnChanges, OnInit, SimpleChanges, isDevMode } from '@angular/core';
import { Validators } from '@angular/forms';
import { Address } from 'src/app/domain/Address';
import { ParcelShop } from 'src/app/domain/ParcelShop';
import { AddressService } from 'src/app/services/address.service';
import { ClientService } from 'src/app/services/client.service';
import { CountryService } from 'src/app/services/country.service';
import { CustomerService } from 'src/app/services/customer.service';
import { ParcelShopService } from 'src/app/services/parcel-shop.service';

@Component({
  selector: 'app-external-carrier-address',
  templateUrl: './external-carrier-address.component.html',
  styleUrls: ['./external-carrier-address.component.scss'],
})
export class ExternalCarrierAddressComponent implements OnInit, OnChanges {
  @Input() shipment: any;
  @Input() addressForm: any;
  @Input() services: any;
  @Input() multiSelectServices: boolean = false;
  @Input() contactPersons: any;

  countries: any;
  foundParcelShops: ParcelShop[];
  foundCustomers: any;
  foundAddresses: any;
  loadingParcelShops: boolean = false;
  filteredContactPersons: any;

  constructor(
    private clientService: ClientService,
    private countryService: CountryService,
    private parcelShopService: ParcelShopService,
    private customerService: CustomerService,
    private addressService: AddressService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.contactPersons = null;
    this.foundParcelShops = null;
  }

  ngOnInit(): void {
    this.fetchCountries();
    this.checkClientAddress();
    this.checkAddressForm();
  }

  externalServiceChange(event: any) {
    if (this.shipment.externalCarrierServices) {
      let flex = this.shipment.externalCarrierServices.find((x: any) => x.code == 'flex' || x.code == 'A6');
      if (flex) {
        this.addressForm.get('note').setValidators(Validators.required, Validators.max(40));
        this.addressForm.get('note').updateValueAndValidity();
      } else {
        this.addressForm.get('note').clearValidators();
        this.addressForm.get('note').setValidators([Validators.max(40)]);
        this.addressForm.get('note').updateValueAndValidity();
      }
    }
  }

  onContactPersonSelect(event: any) {
    this.addressForm.patchValue({
      contactPhone: event.phone,
      contactEmail: event.email,
      contactPerson: { name: event.name },
    });
    this.shipment.recipientAddress.contactPerson = event.name;
  }

  fetchCountries() {
    if (!this.countries) {
      this.countries = this.countryService.getEUCountriesJson();
    }
  }

  formattedParcelShopName(item: ParcelShop) {
    if (item) {
      return `${item.number} : ${item.companyName} ${item.streetname} - ${item.cityName} ${item.zipCode} ${item.countryCodeISO3166A2}`;
    }
    return '';
  }
  formattedStreetName(item: Address) {
    // If Address is chosen (Auto Complete)
    if (item && item.street) {
      return `${item.street}`;
    }
    // if Address is manually typed
    if (item) {
      return `${item}`;
    }
    return '';
  }
  searchAddress(event: any) {
    this.addressService.autoCompleteAddress(event.query).subscribe((addressResult: any) => {
      if (addressResult && addressResult.items) {
        this.foundAddresses = addressResult.items;
      }
    });
  }

  filterContactPersons(event: any) {
    if (!this.contactPersons) {
      this.filteredContactPersons = [];
      return;
    }
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.contactPersons.length; i++) {
      let contactPerson = this.contactPersons[i];
      if (contactPerson.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(contactPerson);
      }
    }

    this.filteredContactPersons = filtered;
  }

  searchCustomer(event: any) {
    this.customerService.searchCustomerFormatted(event.query).subscribe(
      (result: any) => {
        if (result) {
          this.foundCustomers = result;
        }
      },
      (error: any) => {
        this.foundCustomers = [];
      }
    );
  }

  setCustomer(cust: any) {
    this.addressForm.patchValue({ name: cust.name });

    if (cust.contactPersons) {
      this.contactPersons = cust.contactPersons;
    }

    if (cust.contactPerson) {
      this.addressForm.patchValue({ contactPerson: { name: cust.contactPerson.name } });
      this.addressForm.patchValue({ contactPhone: cust.contactPerson.phone });
      this.addressForm.patchValue({ contactEmail: cust.contactPerson.email });
      this.shipment.recipientAddress.contactPerson = cust.contactPerson.name;
      this.shipment.recipientAddress.contactPhone = cust.contactPerson.phone;
      this.shipment.recipientAddress.contactEmail = cust.contactPerson.email;
    }
    if (!cust.contactPerson) {
      this.addressForm.patchValue({ contactPerson: null });
      this.addressForm.patchValue({ contactPhone: null });
      this.addressForm.patchValue({ contactEmail: null });
      this.shipment.recipientAddress.contactPerson = null;
      this.shipment.recipientAddress.contactPhone = null;
      this.shipment.recipientAddress.contactEmail = null;
    }

    if (cust.address) {
      this.setAddress(cust.address);
    }
  }

  setAddress(event: any) {
    this.loadingParcelShops = true;
    this.foundParcelShops = [];
    if (!event.countryCode) {
      this.addressForm.controls.countryCode.setValidators(Validators.required);
      this.addressForm.controls.countryCode.markAsDirty();
      this.addressForm.controls.countryCode.updateValueAndValidity();
    }

    this.addressForm.patchValue(
      {
        street: event.street,
        zipCode: event.zipCode,
        city: event.city,
        countryCode: event.countryCode,
      },
      { emitEvent: false }
    );
    this.shipment.recipientAddress.street = event.street;
    this.shipment.recipientAddress.zipCode = event.zipCode;
    this.shipment.recipientAddress.city = event.city;
    this.shipment.recipientAddress.countryCode = event.countryCode;
    this.shipment.recipientAddress.latitude = event.latitude;
    this.shipment.recipientAddress.longitude = event.longitude;
    this.shipment.recipientAddress.validatedBy = event.validatedBy;

    if (this.shipment.bookingType == 'PARCELSHOP') {
      if (this.shipment.carrier == 'GLS') {
        this.parcelShopService.getGLSParcelShopsByAddress(this.shipment.recipientAddress).subscribe({
          next: (result: any) => {
            if (result) {
              if (result.parcelshops) {
                this.foundParcelShops = result.parcelshops;
                this.loadingParcelShops = false;
              }
            } else {
              this.loadingParcelShops = false;
            }
          },
          error: (error: any) => {
            this.loadingParcelShops = false;
          },
        });
      }
      if (this.shipment.carrier == 'Postnord') {
        this.parcelShopService.getPostnordParcelShopsByAddress(this.shipment.recipientAddress).subscribe({
          next: (result: any) => {
            if (result) {
              if (result.parcelShops) {
                this.foundParcelShops = result.parcelShops;
                this.loadingParcelShops = false;
              }
            } else {
              this.loadingParcelShops = false;
            }
          },
          error: (error: any) => {
            this.loadingParcelShops = false;
          },
        });
      }
    }
  }

  formattedCustomerName(item: any) {
    if (item && item.name) {
      return `${item.name}`;
    }
    if (item) {
      return `${item}`;
    }
    return '';
  }

  checkClientAddress() {
    if (this.clientService.clientAddress) {
      this.shipment.senderAddress = this.clientService.clientAddress;
      this.shipment.senderAddress.name = this.clientService.defaultClientName;
      this.shipment.senderAddress.contactEmail = this.clientService.defaultClientEmail;
      this.shipment.senderAddress.contactPhone = this.clientService.defaultClientPhone;
      this.shipment.senderAddress.contactPerson = this.clientService.defaultClientContactPerson;
    }
  }

  onClearAddress() {
    if (this.shipment.bookingType != 'PARCELSHOP') {
      this.addressForm.get('zipCode').enable();
      this.addressForm.get('city').enable();
      this.addressForm.get('countryCode').enable();
    }
    this.addressForm.patchValue({ street: '', zipCode: '', city: '', countryCode: '' });
  }

  checkAddressForm() {
    this.addressForm.valueChanges.subscribe((val) => {
      this.shipment.note = val.note;
      this.shipment.parcelShopId = val.parcelShopId;
      if (val.name) {
        this.shipment.recipientAddress.name = val.name;
      }
      if (val.contactPerson) {
        if (val.contactPerson.name) {
          this.shipment.recipientAddress.contactPerson = val.contactPerson.name;
        } else {
          this.shipment.recipientAddress.contactPerson = val.contactPerson;
        }
      }
      if (val.contactPhone) {
        this.shipment.recipientAddress.contactPhone = val.contactPhone;
      }
      if (val.contactEmail) {
        this.shipment.recipientAddress.contactEmail = val.contactEmail;
      }
      if (val.street) {
        this.shipment.recipientAddress.street = val.street;
      }
      if (val.zipCode) {
        this.shipment.recipientAddress.zipCode = val.zipCode;
      }
      if (val.city) {
        this.shipment.recipientAddress.city = val.city;
      }
      if (val.countryCode) {
        this.shipment.recipientAddress.countryCode = val.countryCode;
      }
    });
  }

  removeAllServices(event: any) {
    this.shipment.externalCarrierServices = null;
  }

  isDev() {
    if (isDevMode()) {
      return true;
    }
    return false;
  }
}

import { Injectable } from '@angular/core';
import * as countriesJson from '../../assets/demo/countries/countries.json';
import { ClientService } from './client.service';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  private countries = countriesJson;
  private codeToName: Map<string, any>;
  private nameToCode: Map<string, any>;

  constructor(private client: ClientService) {
    this.codeToName = new Map();
    this.nameToCode = new Map();

    for (const country of this.countries.data) {
      this.codeToName.set(country.code.toLowerCase(), country);
      this.nameToCode.set(country.name.toLowerCase(), country);
    }
  }

  /// Returns a list of countries from local .json file - Change to fetch CLient Countries from API?
  getCountriesJson() {
    return this.countries.data.filter((x) => x.code == 'DK' || x.code == 'SE' || x.code == 'NO' || x.code == 'PL' || x.code == 'NL');
  }

  getEUCountriesJson() {
    return this.countries.data.filter(
      (x) =>
        x.code == 'DK' ||
        x.code == 'SE' ||
        x.code == 'NO' ||
        x.code == 'PL' ||
        x.code == 'NL' ||
        x.code == 'DE' ||
        x.code == 'BE' ||
        x.code == 'FR' ||
        x.code == 'ES' ||
        x.code == 'IT' ||
        x.code == 'PT' ||
        x.code == 'AT' ||
        x.code == 'LU' ||
        x.code == 'FI' ||
        x.code == 'EE' ||
        x.code == 'LV' ||
        x.code == 'LT' ||
        x.code == 'CZ' ||
        x.code == 'SK' ||
        x.code == 'HU' ||
        x.code == 'SI' ||
        x.code == 'HR' ||
        x.code == 'BG' ||
        x.code == 'RO' ||
        x.code == 'IE' ||
        x.code == 'CY' ||
        x.code == 'MT' ||
        x.code == 'GR'
    );
  }

  getCountryInfo(input: string): string {
    if (input.length < 2) return '';

    const inputLower = input.toLowerCase();

    let result = this.codeToName.get(inputLower);
    if (result) {
      return result.name;
    }

    result = this.nameToCode.get(inputLower);
    if (result) {
      return result.code;
    }

    return '';
  }
  getClientCountries(): any[] {
    return this.client.countries.map((countryCode: string) => ({
      code: countryCode,
      name: this.countries.data.find((x) => x.code == countryCode)?.name ?? countryCode,
    }));
  }
}

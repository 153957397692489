import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import { ConfigService } from 'src/app/services/app.config.service';
import { AppConfig } from 'src/app/domain/appconfig';
import { AppBreadcrumbService } from 'src/app/services/app.breadcrumb.service';
import { CustomerService } from 'src/app/services/customer.service';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { AddCustomerAddress, CreateCustomer, Customer, CustomerAddress, CustomerContact } from 'src/app/domain/Customer';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { atLeastOne } from 'src/app/pages/Shared/directives/atLeastOneValidator';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AddressDialogComponent } from '../Shared/dialogs/address-dialog/address-dialog.component';
import { CustomerDialogComponent } from '../Shared/dialogs/customer-dialog/customer-dialog.component';
import { ContactDialogComponent } from '../Shared/dialogs/contact-dialog/contact-dialog.component';
import { OBPermissions } from '@alicetms/auth.permissions';
import { AuthService } from 'src/app/services/auth.service';
import { Address } from 'src/app/domain/Address';
import { AddressService } from 'src/app/services/address.service';
import { ImportCustomerDialogComponent } from './import-customer-dialog/import-customer-dialog.component';

@Component({
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
  providers: [DialogService],
})
export class CustomersComponent implements OnInit, OnDestroy {
  transitionOption: string = '250ms cubic-bezier(0, 0, 0.2, 1)';

  config: AppConfig;
  subscription: Subscription;

  customers: Customer[] = [];
  customer: Customer;

  tmpCustomerNo: string;
  tmpCustomerName: string;
  tmpCustomerNote: string;

  displayNewCustomerDialog = false;
  displayAddAddressDialog = false;
  displayAddContactDialog = false;
  displayImportDialog = false;

  selectedAddresses: any[];
  selectedContactPersons: any[];

  ref: DynamicDialogRef;

  loading: boolean = false;
  fullPageLoading: boolean = false;
  addressesLoading: boolean = true;
  createCustomerPermission = OBPermissions.CreateCustomer;

  newCustomerForm = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    customerNo: new FormControl(null),
    registrationNumber: new FormControl(null),
    countryCode: new FormControl(null, [Validators.required]),
    street: new FormControl(null, [Validators.required]),
    zipCode: new FormControl(null, [Validators.required]),
    city: new FormControl(null, [Validators.required]),
    longitude: new FormControl(0),
    latitude: new FormControl(0),
    contactName: new FormControl(null),
    contactEmail: new FormControl(null, [
      Validators.email,
      Validators.pattern(
        /^[A-Za-z0-9!#$%&amp;'*+\/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&amp;'*+\/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/
      ),
    ]),
    contactPhone: new FormControl(null),
    sendLabel: new FormControl({ value: null, disabled: true }),
    note: new FormControl(null),
  });

  newAddressForm = new FormGroup({
    id: new FormControl(null),
    countryCode: new FormControl(null, [Validators.required]),
    street: new FormControl(null, [Validators.required]),
    zipCode: new FormControl(null, [Validators.required]),
    city: new FormControl(null, [Validators.required]),
    longitude: new FormControl(0),
    latitude: new FormControl(0),
  });

  newContactForm = new FormGroup(
    {
      id: new FormControl(null),
      default: new FormControl(false),
      name: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [
        Validators.email,
        Validators.pattern(
          /^[A-Za-z0-9!#$%&amp;'*+\/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&amp;'*+\/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/
        ),
      ]),
      phone: new FormControl(null),
      sendLabel: new FormControl({ value: null, disabled: true }),
    },
    { validators: [atLeastOne(Validators.required, ['email', 'phone'])] }
  );

  customerBreadcrumbDefinition = {
    label: this.translateService.instant('ROUTES.CUSTOMERS'),
    command: () => {
      this.customer = null;
      this.breadcrumbService.setItems([
        {
          label: this.translateService.instant('ROUTES.CUSTOMERS'),
          routerLink: ['customers'],
        },
      ]);
    },
  };

  //#region DOM Events
  @HostListener('document:keydown.escape', ['$event']) onKeydownEscapeHandler(event: KeyboardEvent) {
    if (!this.displayNewCustomerDialog && !this.displayAddAddressDialog && !this.displayAddContactDialog) {
      this.clearChoice();
    }
  }
  //#endregion

  constructor(
    private breadcrumbService: AppBreadcrumbService,
    private configService: ConfigService,
    private customerService: CustomerService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
    private address: AddressService,
    public dialogService: DialogService,
    public authService: AuthService
  ) {
    this.breadcrumbService.setItems([
      {
        label: translateService.instant('ROUTES.CUSTOMERS'),
        routerLink: ['customers'],
      },
    ]);
    this.config = this.configService.config;
    this.subscription = this.configService.configUpdate$.subscribe((config) => {
      this.config = config;
    });
  }

  ngOnInit() {
    this.loadCustomers();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.ref) {
      this.ref.close();
    }
  }

  onRowSelect(event: any) {
    this.tmpCustomerName = event.data.name;
    this.tmpCustomerNo = event.data.customerNo;
    this.tmpCustomerNote = event.data.note;
    this.customer.addresses = [];
    this.addressesLoading = true;

    const observables = event.data.addressIds.map((id) => {
      return this.address.getAddress(id);
    });

    if (this.customer) {
      forkJoin(observables).subscribe((responses: any[]) => {
        responses.forEach((res: any) => {
          this.customer.addresses.push(res);
        });
        this.addressesLoading = false;
      });
      this.breadcrumbService.setItems([this.customerBreadcrumbDefinition, { label: event.data.name }]);
    }
  }

  deleteCustomer(event: any, customer: any) {
    event.stopPropagation();
    event.preventDefault();
    if (!customer.id) {
      return;
    }

    this.confirmationService.confirm({
      message: this.translateService.instant('DELETECONFIRMMESSAGE') + customer.name + '?',
      header: this.translateService.instant('CONFIRMDELETE'),
      icon: 'pi pi-exclamation-triangle',
      key: 'deleteCustomer',
      acceptLabel: this.translateService.instant('GENERIC.YES'),
      rejectLabel: this.translateService.instant('GENERIC.NO'),
      dismissableMask: true,
      accept: () => {
        this.customerService.delete(customer.id).subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('CUSTOMERDELETED'),
            });
            this.loadCustomers();
          },
        });
      },
      reject: (type: ConfirmEventType) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            break;
          case ConfirmEventType.CANCEL:
            break;
        }
      },
    });
  }

  updateCustomer() {
    this.fullPageLoading = true;
    let customer = {
      id: this.customer.id,
      name: this.tmpCustomerName,
      customerNo: this.tmpCustomerNo,
      note: this.tmpCustomerNote,
    };
    this.customerService.updateCustomer(customer).subscribe({
      next: (customer: Customer) => {
        this.customer.name = this.tmpCustomerName;
        this.customer.customerNo = this.tmpCustomerNo;
        this.customer.note = this.tmpCustomerNote;
        this.fullPageLoading = false;
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('CUSTOMER.UPDATE.SUCCESS'),
        });
      },
      error: (error) => {
        this.tmpCustomerName = this.customer.name;
        this.tmpCustomerNo = this.customer.customerNo;
        this.fullPageLoading = false;
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('CUSTOMER.UPDATE.ERROR'),
        });
      },
    });
  }

  openDialog(dialogChoice: string, editObject: any = null) {
    switch (dialogChoice) {
      case 'address':
        this.displayAddAddressDialog = true;
        if (editObject) {
          this.newAddressForm.patchValue(editObject);
        }
        this.ref = this.dialogService.open(AddressDialogComponent, {
          width: '50vw',
          data: this.newAddressForm,
          header: this.translateService.instant('CUSTOMER.ADDRESS.ADDADDRESS'),
          transitionOptions: this.transitionOption,
        });

        this.ref.onClose.subscribe((addressForm: any) => {
          this.displayAddAddressDialog = false;
          if (addressForm) {
            if (!editObject) {
              this.newAddressForm = addressForm;
              this.addAddress();
            } else {
              this.updateAddress(this.newAddressForm);
            }
          } else {
            this.newAddressForm.reset();
          }
        });
        break;
      case 'contactPerson':
        this.displayAddContactDialog = true;
        if (editObject) {
          this.newContactForm.patchValue(editObject);
        }
        this.ref = this.dialogService.open(ContactDialogComponent, {
          width: '50vw',
          data: this.newContactForm,
          header: this.translateService.instant('CUSTOMER.CONTACT.ADD'),
          transitionOptions: this.transitionOption,
        });

        this.ref.onClose.subscribe((contactForm: any) => {
          this.displayAddContactDialog = false;
          if (contactForm) {
            if (!editObject) {
              this.newContactForm = contactForm;
              this.addContact();
            } else {
              this.updateContact(this.newContactForm);
            }
          } else {
            this.newContactForm.reset();
          }
        });
        break;
      case 'customer':
        this.displayNewCustomerDialog = true;
        this.ref = this.dialogService.open(CustomerDialogComponent, {
          width: '50vw',
          data: { customer: this.customer, form: this.newCustomerForm },
          header: this.translateService.instant('NEWCUSTOMER'),
          transitionOptions: this.transitionOption,
          closable: false,
        });

        this.ref.onClose.subscribe((customerForm: any) => {
          this.displayNewCustomerDialog = false;
          if (customerForm) {
            this.newCustomerForm = customerForm;
            this.createCustomer();
          } else {
            this.newCustomerForm.reset();
          }
        });
        break;
      case 'import':
        this.displayImportDialog = true;
        this.ref = this.dialogService.open(ImportCustomerDialogComponent, {
          width: '50vw',
        });
        break;
      default:
        break;
    }
  }

  createCustomer() {
    let customer: CreateCustomer = this.newCustomerForm.value as CreateCustomer;

    this.customerService.create(customer).subscribe({
      next: (res) => {
        this.displayNewCustomerDialog = false;
        this.newCustomerForm.reset();
        this.newCustomerForm.controls['longitude'].setValue(0);
        this.newCustomerForm.controls['latitude'].setValue(0);
        this.loadCustomers();
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('CUSTOMER.CREATED.NOTIFICATION'),
          detail: customer.name,
        });
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('CUSTOMER.CREATED.ERROR'),
          detail: error.statusText,
          life: 10000,
        });
      },
    });
  }

  updateAddress(addressForm: any) {
    let address: Address = addressForm.value as Address;

    this.customerService.updateCustomerAddress(address, this.customer.id).subscribe({
      next: (res) => {
        this.displayAddAddressDialog = false;
        this.newAddressForm.reset();
        this.newAddressForm.controls['longitude'].setValue(0);
        this.newAddressForm.controls['latitude'].setValue(0);
        this.loadCustomers();
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('CUSTOMER.ADDRESS.UPDATED'),
          detail: address.street,
        });
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('CUSTOMER.ADDRESS.ERROR'),
          life: 1000,
        });
      },
    });
  }

  addAddress() {
    let address: AddCustomerAddress = this.newAddressForm.value as AddCustomerAddress;
    address.customerId = this.customer.id;

    this.customerService.addAddress(address).subscribe({
      next: (res) => {
        this.displayAddAddressDialog = false;
        this.newAddressForm.reset();
        this.newAddressForm.controls['longitude'].setValue(0);
        this.newAddressForm.controls['latitude'].setValue(0);
        this.loadCustomers();
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('CUSTOMER.ADDRESS.ADDED'),
          detail: address.street,
        });
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('CUSTOMER.ADDRESS.ADDFAILED'),
          detail: error.statusText,
          life: 10000,
        });
      },
    });
  }

  deleteSelectedAddresses() {
    this.confirmationService.confirm({
      message: this.translateService.instant('CUSTOMER.ADDRESSES.CONFIRMDELETEMANY'),
      header: this.translateService.instant('GENERIC.CONFIRM'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.selectedAddresses.forEach((address) => {
          this.customerService.deleteAddress(address.id, this.customer.id).subscribe({
            next: (res) => {
              this.messageService.add({
                severity: 'info',
                summary: this.translateService.instant('CUSTOMER.ADDRESS.DELETED'),
              });
              this.loadCustomers();
              this.selectedAddresses = [];
            },
          });
        });
      },
    });
  }

  deleteAddress(address: CustomerAddress) {
    this.confirmationService.confirm({
      message: this.translateService.instant('CUSTOMER.ADDRESS.CONFIRMDELETE'),
      header: this.translateService.instant('GENERIC.CONFIRM'),
      icon: 'pi pi-exclamation-triangle',

      accept: () => {
        this.customerService.deleteAddress(address.id, this.customer.id).subscribe({
          next: (res) => {
            this.messageService.add({
              severity: 'info',
              summary: this.translateService.instant('CUSTOMER.ADDRESS.DELETED'),
            });
            this.loadCustomers();
          },
          error: (error) => {
            this.messageService.add({
              severity: 'error',
              summary: this.translateService.instant('CUSTOMER.ADDRESS.ADDFAILED'),
              detail: error.statusText,
              life: 10000,
            });
          },
        });
      },
    });
  }

  updateContact(contactPersonForm: any) {
    let contact: CustomerContact = contactPersonForm.value as CustomerContact;
    this.customerService.updateContactPerson(contact, this.customer.id).subscribe({
      next: (res) => {
        this.displayAddContactDialog = false;
        this.newContactForm.reset();
        this.newContactForm.controls['default'].setValue(false);
        this.loadCustomers();
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('CUSTOMER.CONTACT.UPDATED'),
          detail: contact.name,
        });
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'CUSTOMER.CONTACT.UPDATEFAILED',
          life: 1000,
        });
      },
    });
  }

  addContact() {
    let contact: CustomerContact = this.newContactForm.value as CustomerContact;

    this.customerService.addContactPerson(contact, this.customer.id).subscribe({
      next: (res) => {
        this.displayAddContactDialog = false;
        this.newContactForm.reset();
        this.newContactForm.controls['default'].setValue(false);
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('CUSTOMER.CONTACT.ADDED'),
          detail: contact.name,
        });
        this.loadCustomers();
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'CUSTOMER.CONTACT.ADDFAILED',
          detail: error.statusText,
          life: 10000,
        });
      },
    });
  }

  deleteSelectedContacts() {
    this.confirmationService.confirm({
      message: this.translateService.instant('CUSTOMER.CONTACT.CONFIRMDELETEMANY'),
      header: this.translateService.instant('GENERIC.CONFIRM'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.selectedContactPersons.forEach((contact) => {
          this.doDeleteContact(contact.id);
        });
      },
    });
  }

  deleteContact(contact: CustomerContact) {
    this.confirmationService.confirm({
      message: this.translateService.instant('CUSTOMER.CONTACT.CONFIRMDELETE'),
      header: this.translateService.instant('GENERIC.CONFIRM'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.doDeleteContact(contact.id);
      },
    });
  }

  private doDeleteContact(id: string) {
    this.customerService.deleteContactPerson(id, this.customer.id).subscribe({
      next: (res) => {
        this.messageService.add({
          severity: 'info',
          summary: this.translateService.instant('CUSTOMER.CONTACT.DELETED'),
        });
        this.loadCustomers();
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('CUSTOMER.CONTACT.DELETEFAILED'),
          detail: error.statusText,
          life: 10000,
        });
      },
    });
  }

  loadCustomers() {
    this.loading = true;
    this.customerService.getCustomers().subscribe({
      next: (res) => {
        this.loading = false;
        this.customers = res.items.sort((a, b) => a.name.localeCompare(b.name));
        if (this.customer) {
          const cust = this.customers.find((c) => c.id === this.customer.id);
          if (cust) {
            this.customer = cust;
            this.onRowSelect({ data: this.customer });
          }
        }
      },
      error(err) {
        this.loading = false;
        if (this.messageService) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: this.translateService.instant('CUSTOMER.GETCUSTOMERERROR') });
        } else {
          console.error(this.messageService);
          window.location.reload();
        }
      },
    });
  }

  clearChoice() {
    this.breadcrumbService.setItems([this.customerBreadcrumbDefinition]);
    if (this.customer) {
      this.customer = null;
    }
  }

  onRowUnselect(event: any) {
    this.breadcrumbService.setItems([this.customerBreadcrumbDefinition]);
  }
}

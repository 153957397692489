<div>
    <form [formGroup]="bookingInfoForm">
        <h4 class="primColor">{{'INFORMATIONS' | translate}}</h4>
        <div class="p-fluid p-formgrid grid row-gap-1">

            <div class="col-12 md:col-12">
                <span class="p-float-label">
                    <input formControlName="note" pInputText id="note" #note type="text" />
                    <label for="note">{{'NOTE' | translate}}</label>
                </span>
                <small *ngIf="bookingInfoForm.controls.note.errors?.maxlength" class="text-danger"
                    style="color:#FC6161">
                    {{'MAXDESCRIPTION' | translate}}
                </small>
            </div>

            <div class="col-4 md:col-4">
                <span class="p-float-label">
                    <input formControlName="reference1" pInputText id="reference1" #reference1 type="text" />
                    <label for="reference1">{{'REF1' | translate}}</label>
                </span>
            </div>

            <div class="col-4 md:col-4">
                <span class="p-float-label">
                    <input formControlName="reference2" pInputText id="reference2" #reference2 type="text" />
                    <label for="reference1">{{'REF2' | translate}}</label>
                </span>
            </div>

            <div class="col-4 md:col-4">
                <span class="p-float-label">
                    <input formControlName="reference3" pInputText id="reference3" #reference3 type="text" />
                    <label for="reference1">{{'REF3' | translate}}</label>
                </span>
            </div>

        </div>
    </form>

    <p-divider></p-divider>

    <div class="bottomBar">
        <button pButton pRipple label="{{'GENERIC.PREVIOUS' | translate}}" *ngIf="stepperService.activeIndex > 0"
            (click)="stepperService.stepPrev()"></button>
        <button pButton pRipple label="{{'CREATE' | translate}}" (click)="createBooking()"
            [disabled]="bookingInfoForm.controls.note.errors?.maxlength"></button>
    </div>
</div>

<p-confirmDialog id="doneBookingDialog"></p-confirmDialog>

<div class="spinner-overlay" *ngIf="loading">
    <p-progressSpinner styleClass="w-8rem h-8rem" strokeWidth="6" animationDuration="1s"></p-progressSpinner>
    <br />
    <p>{{'CREATINGBOOKING' | translate}}</p>
</div>
import { HttpParams } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LabelService } from 'src/app/services/label.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-print-label-shipment-dialog',
  templateUrl: './print-label-shipment-dialog.component.html',
  styleUrls: ['./print-label-shipment-dialog.component.scss']
})
export class PrintLabelShipmentDialogComponent implements OnInit {
  pdfUrl: any;
  loading: boolean = false;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private sanitizer: DomSanitizer, private messageService: MessageService, private labelService: LabelService, private translateService: TranslateService) { }

  ngOnInit(): void {
    if (!this.config.data) {
      this.ref.close();
    }
    this.printShipment(this.config.data.type);
  }


  async printShipment(type: 'label' | 'shipment' | 'packageList'): Promise<void> {
    this.loading = true;
    if (!this.config.data.id) {
      this.messageService.add({
        severity: 'info',
        summary: this.translateService.instant('MESSAGES.CHOOSESHIPMENTFORPRINT'),
      });
      this.ref.close();
      return;
    }
    if (this.config.data.id) {
      let queryParams = new HttpParams()
        .append('printType', type);
      queryParams = queryParams.append('shipmentIds', this.config.data.id);

      if (type == 'label') {
        this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(await this.labelService.getMultipleLabels([this.config.data.id]));
      }
      else {
        this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.apiUrl}/shipments/print?${queryParams.toString()}`);
      }
      this.loading = false;
    }
  }

  close() {
    this.ref.close();
  }

}

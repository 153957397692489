import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { AutoCompleteModule } from "primeng/autocomplete";
import { ButtonModule } from "primeng/button";
import { CheckboxModule } from "primeng/checkbox";
import { DividerModule } from "primeng/divider";
import { DropdownModule } from "primeng/dropdown";
import { InputMaskModule } from "primeng/inputmask";
import { InputNumberModule } from "primeng/inputnumber";
import { InputTextModule } from "primeng/inputtext";
import { FormErrorComponent } from "./form-error.component";

@NgModule({
    imports: [
        CommonModule,
        AutoCompleteModule,
        FormsModule,
        ReactiveFormsModule,
        DropdownModule,
        DividerModule,
        CheckboxModule,
        ButtonModule,
        InputMaskModule,
        InputTextModule,
        InputNumberModule,
        AutoCompleteModule,
        TranslateModule.forChild({
            extend: true,
        }),
    ],
    declarations: [FormErrorComponent],
    exports: [FormErrorComponent]
})
export class FormErrorModule { }

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
// import * as Leaflet from 'leaflet';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    menuMode = 'horizontal';
    layout = 'orange';
    theme = 'orange';
    ripple: boolean = true;
    colorScheme = 'dark';

    constructor(private primengConfig: PrimeNGConfig, private translate: TranslateService) { }

    ngOnInit() {
        if (localStorage.getItem('language')) {
            this.translate.setDefaultLang(localStorage.getItem('language'));
            this.translate.use(localStorage.getItem('language'));
        }
        else {
            if (navigator.language === 'en-US') {

                this.translate.setDefaultLang('en');
                this.translate.use('en');
            }
            else {
                this.translate.setDefaultLang(navigator.language);
                this.translate.use(navigator.language);
            }
        }

        this.translate.get('primeng').subscribe(res => this.primengConfig.setTranslation(res));

        this.primengConfig.ripple = true;
        this.ripple = true;
    }
}


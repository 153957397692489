import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StepperService } from '../stepper.service';
import { ClientService } from 'src/app/services/client.service';
import { Payer } from 'src/app/domain/Payer';

@Component({
  selector: 'app-payer',
  templateUrl: './payer.component.html',
  styleUrls: ['./payer.component.scss'],
})
export class PayerComponent implements OnInit {
  shipment: any;
  payers: Payer[] = [];

  payerForm = new FormGroup({
    payerType: new FormControl('franco', [Validators.required]),
    paymentChoice: new FormControl(),
  });

  constructor(public stepperService: StepperService, private clientService: ClientService, private cdr: ChangeDetectorRef) {
    this.shipment = this.stepperService.newShipment;
    this.payers = this.clientService.clientPayers;
  }

  ngOnInit(): void {
    if (this.shipment.clientPayerId) {
      var payer = this.payers.find((x) => x.id == this.shipment.clientPayerId);
      this.payerForm.controls.paymentChoice.setValue(payer, { emitEvent: false });
      this.payerForm.controls.payerType.setValue('exworks', { emitEvent: false });
    }
    this.onPayerTypeChange();
  }

  onPayerTypeChange() {
    if (this.payerForm.get('payerType').value === 'exworks') {
      this.payerForm.get('paymentChoice').enable();
      this.payerForm.controls.paymentChoice.setValidators([Validators.required]);
      if (this.payers.length === 1) {
        this.payerForm.controls.paymentChoice.setValue(this.payers[0], { emitEvent: false });
      }
    } else {
      this.payerForm.get('paymentChoice').disable();
      this.payerForm.controls.paymentChoice.removeValidators([Validators.required]);
      this.payerForm.updateValueAndValidity();
      this.shipment.clientPayerId = null;
    }
    this.cdr.detectChanges();
  }

  onPaymentChange(event: any) {
    if (event.value) {
      this.shipment.clientPayerId = event.value.id;
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { PDFDocument } from 'pdf-lib';

@Injectable({
  providedIn: 'root',
})
export class LabelService {
  constructor(private httpClient: HttpClient) { }

  printShipments(queryParams: any): Observable<Blob> {
    return this.httpClient.get(`${environment.apiUrl}/shipments/print?${queryParams.toString()}`, { responseType: 'blob' });
  }

  async getMultipleLabels(shipmentIds: string[]): Promise<string> {
    let labelUrls = await Promise.all(
      shipmentIds.map(async (id) => {
        const response: any = await this.httpClient.get('/api/e/shipments/labels/shipment/' + id).toPromise();
        return response?.labelUri;
      })
    );
    return await this.mergeAllPDFs(labelUrls);
  }

  private async mergeAllPDFs(urls) {
    if (!urls) {
      return null;
    }

    const pdfDoc = await PDFDocument.create();
    const numDocs = urls.length;

    for (var i = 0; i < numDocs; i++) {
      const donorPdfBytes = await fetch(urls[i]).then((res) => res.arrayBuffer());
      const donorPdfDoc = await PDFDocument.load(donorPdfBytes);
      const docLength = donorPdfDoc.getPageCount();
      for (var k = 0; k < docLength; k++) {
        const [donorPage] = await pdfDoc.copyPages(donorPdfDoc, [k]);
        pdfDoc.addPage(donorPage);
      }
    }

    const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });

    return pdfDataUri;
  }
}

import { Component, HostListener, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Address } from 'src/app/domain/Address';
import { AddressService } from 'src/app/services/address.service';
import { CountryService } from 'src/app/services/country.service';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { customArray as CountryList } from 'country-codes-list';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-customer-dialog',
  templateUrl: './customer-dialog.component.html',
  styleUrls: ['./customer-dialog.component.scss'],
  providers: [DialogService],
})
export class CustomerDialogComponent implements OnInit {
  newCustomerForm: FormGroup;
  countries: any[];
  selectedCountry: any;
  foundAddresses: any;

  countryCodes = CountryList({ value: '{countryCode}', label: '{countryNameEn}', callCode: '+{countryCallingCode}' });
  selectedPhoneCountry: any;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      let isDirty = false;
      for (const key in this.newCustomerForm.controls) {
        if (this.newCustomerForm.controls[key].dirty) {
          isDirty = true;
          break;
        }
      }
      if (isDirty) {
        this.confirmationService.confirm({
          message: this.translateService.instant('UNSAVEDCHANGED.MESSAGE'),
          header: this.translateService.instant('UNSAVEDCHANGED.HEADER'),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.ref.close();
          },
        });
      } else {
        this.ref.close();
      }
    }
  }

  constructor(private translateService: TranslateService, private confirmationService: ConfirmationService, private countryService: CountryService, public ref: DynamicDialogRef, public config: DynamicDialogConfig, private addressService: AddressService) { }

  ngOnInit(): void {
    if (!this.config.data) {
      this.ref.close();
    }
    this.newCustomerForm = this.config.data.form;
    this.fetchCountries();

    this.selectedPhoneCountry = this.countryCodes.find((c: any) => c.value == 'DK');

    this.newCustomerForm.valueChanges.subscribe((value: any) => {
      if (value.contactPhone) {
        if (isValidPhoneNumber(value.contactPhone, this.selectedPhoneCountry.value)) {
          this.newCustomerForm.controls.contactPhone.setErrors(null);
        } else {
          this.newCustomerForm.controls.contactPhone.setErrors({ invalid: true });
        }
      }
    });

    this.newCustomerForm.controls.contactEmail.statusChanges.subscribe((value: any) => {
      if (value === 'INVALID') {
        this.newCustomerForm.controls.sendLabel.disable();
        this.newCustomerForm.controls.sendLabel.setValue(false);
      } else {
        if (this.newCustomerForm.controls.contactEmail.value) {
          this.newCustomerForm.controls.sendLabel.enable();
        }
        else {
          this.newCustomerForm.controls.sendLabel.disable();
          this.newCustomerForm.controls.sendLabel.setValue(false);
        }
      }
    });
    if (this.newCustomerForm.controls.contactEmail.value && this.newCustomerForm.controls.contactEmail.valid) {
      this.newCustomerForm.controls.sendLabel.enable();
    }
  }

  onPhoneCountryChange(event: any) {
    if (this.newCustomerForm.controls.contactPhone.value) {
      const isValidPhone = isValidPhoneNumber(this.newCustomerForm.controls.contactPhone.value, this.selectedPhoneCountry.value);
      if (!isValidPhone) {
        this.newCustomerForm.controls.contactPhone.setErrors({ invalid: true });
      } else {
        this.newCustomerForm.controls.contactPhone.setErrors(null);
      }
    }
  }

  onClearAddress() {
    this.newCustomerForm.patchValue({
      street: null,
      zipCode: null,
      city: null,
      countryCode: null,
      longitude: 0,
      latitude: 0,
    });
  }

  setAddress(event: any) {
    this.newCustomerForm.patchValue({
      street: event.street,
      zipCode: event.zipCode,
      city: event.city,
      countryCode: event.countryCode,
      longitude: event.longitude,
      latitude: event.latitude,
    });
  }

  fetchCountries() {
    this.countries = this.countryService.getClientCountries();

    if (this.countries && this.countries.length === 1) {
      this.selectedCountry = this.countries[0].code;
      this.newCustomerForm.patchValue({
        countryCode: this.selectedCountry
      });
    }
  }

  createCustomer() {
    this.ref.close(this.newCustomerForm);
  }

  closeDialog() {
    let isDirty = false;
    for (const key in this.newCustomerForm.controls) {
      if (this.newCustomerForm.controls[key].dirty) {
        isDirty = true;
        break;
      }
    }
    if (isDirty) {
      this.confirmationService.confirm({
        message: this.translateService.instant('UNSAVEDCHANGED.MESSAGE'),
        header: this.translateService.instant('UNSAVEDCHANGED.HEADER'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.ref.close();
        },
      });
    } else {
      this.ref.close();
    }
  }

  formattedStreetName(item: Address) {
    if (item && item.street) {
      return `${item.street}`;
    }
    if (item) {
      return `${item}`;
    }
    return '';
  }

  searchAddress(event: any) {
    this.addressService.autoCompleteAddress(event.query).subscribe((addressResult: any) => {
      if (addressResult && addressResult.items) {
        this.foundAddresses = addressResult.items;
      }
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EdiService {
  constructor(private httpClient: HttpClient) {}

  uploadFiles({ files, handler = 'FileUpload', parser = 'ob_csv' }) {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });
    return this.httpClient.post(`${environment.apiUrl}/edi/upload?handler=${handler}&parser=${parser}`, formData);
  }

  getStatus(id: string) {
    return this.httpClient.get(`${environment.apiUrl}/edi/status/${id}`);
  }

  getSampleFile() {
    return this.httpClient.get(`${environment.apiUrl}/edi/samplefile`).pipe(
      map((res: any) => {
        return res.fileUri;
      })
    );
  }
}
